/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Sidebar from "./Sidebar";
import { format } from "date-fns";
import axios from "axios";
import { appId, deliveryId, apiUrl } from "../Helpers/Config";
import Membership from "./Membership";
import {
  addressFormat,
  stripslashes,
  showPrices,
  showAlert,
} from "../Helpers/SettingHelper";

import Moment from "moment";
import {
  GET_CORDERDETAIL,
  GET_PORDERDETAIL,
  GET_PRINTORDER,
  GET_ORDERHISTORY,
  GET_ACTIVITYCOUNT,
  GET_CUSTOMER_DETAIL,
} from "../../actions";

import cookie from "react-cookies";

import innerbanner from "../../common/images/inner-banner.jpg";
import printer from "../../common/images/printer.png";
import mailthank from "../../common/images/email-thank.png";
var Parser = require("html-react-parser");
class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      corderdetail: [],
      cartItems: [],
      cartData: [],
      cart_brktime_list: [],
      showitems: 10,
      showpitems: 10,
      showresvitems: 10,
      resviewmore: "none",
      order_all: 0,
      overall_orders: 0,
      deliveryTakeaway_orders: 0,
      currentTab: "C",
      showItemList: [],
    };

    if (cookie.load("UserId") === undefined) {
      cookie.save("loginpopupTrigger", "Yes", { path: "/" });
      props.history.push("/");
    }
  }

  componentDidMount() {
    var params = "&status=A&refrence=" + cookie.load("UserId");
    this.props.getCustomerDetail(params);

    /* delivery current - past orders */
    var deliveryparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getCorderDetail(deliveryparams);

    var deliverypastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getPorderDetail(deliverypastparams);

    //Default Action
    $(".filter_tabsec").hide(); //Hide all content
    $("ul.web_order_typecls li:first").addClass("active").show(); //Activate first tab
    $(".filter_tabsec:first").show(); //Show first tab content

    //On Click Event
    $("ul.web_order_typecls li").click(function () {
      $("ul.web_order_typecls li").removeClass("active"); //Remove any "active" class
      $(this).addClass("active"); //Add "active" class to selected tab
      $(".filter_tabsec").hide(); //Hide all tab content
      var activeTab = $(this).find("a").attr("href"); //Find the rel attribute value to identify the active tab + content
      $(activeTab).fadeIn(); //Fade in the active content
      return false;
    });

    this.getActivityCounts();
  }

  /* for order load more  button */
  loadcurrentItems() {
    var pageNext = this.state.showitems + 10;
    this.setState({ showitems: pageNext }, function () {
      this.loadcitems();
    });
  }
  loadpastItems() {
    var pagepNext = this.state.showpitems + 10;
    this.setState({ showpitems: pagepNext }, function () {
      this.loadpitems();
    });
  }

  loadcitems() {
    $(".load_more_data").append('<b class="gloading_img"></b>');
    var deliveryparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      deliveryId +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getCorderDetail(deliveryparams);
  }
  loadpitems() {
    $(".load_more_data1").append('<b class="gloading_img"></b>');
    var deliverypastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      deliveryId +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getPorderDetail(deliverypastparams);
  }

  getActivityCounts() {
    const inputKeys = [
      "order_all",
      "overall_orders",
      "deliveryTakeaway_orders",
      "reward",
    ];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  componentWillReceiveProps(nextProps) {
    /*activity count -start */
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({
            order_all: nextProps.activitycount[0].result_set.order_all,
            overall_orders:
              nextProps.activitycount[0].result_set.overall_orders,
            deliveryTakeaway_orders:
              nextProps.activitycount[0].result_set.deliveryTakeaway_orders,
          });
        }
      }
    } else {
      this.setState({
        order_all: 0,
        overall_orders: 0,
        deliveryTakeaway_orders: 0,
      });
    }

    /*current orders */
    if (nextProps.corderdetail !== this.props.corderdetail) {
      if (Object.keys(nextProps.corderdetail).length > 0) {
        $("#dvLoading").fadeOut(2000);
      }

      if (nextProps.corderdetail && nextProps.corderdetail[0].status == "ok") {
        $("b").removeClass("gloading_img");

        this.setState({ corderdetail: nextProps.corderdetail[0].result_set });
        if (nextProps.corderdetail[0].common.total_records) {
          this.setState({
            totalcount: nextProps.corderdetail[0].common.total_records,
          });
        }
        if (
          this.state.showitems > nextProps.corderdetail[0].common.total_records
        ) {
          $(".load_more_data").hide();
        } else {
          $(".load_more_data").show();
        }
      } else {
        $(".load_more_data").hide();
      }
    }

    if (nextProps.porderdetail !== this.props.porderdetail) {
      if (nextProps.porderdetail && nextProps.porderdetail[0].status == "ok") {
        $("b").removeClass("gloading_img");
        this.setState({ porderdetail: nextProps.porderdetail[0].result_set });
        if (
          nextProps.porderdetail[0].common.total_records &&
          this.state.showpitems > nextProps.porderdetail[0].common.total_records
        ) {
          $(".load_more_data1").hide();
        } else {
          $(".load_more_data1").show();
        }
      } else {
        $(".load_more_data1").hide();
      }
    }

    if (nextProps.orderhistory !== this.props.orderhistory) {
      if (nextProps.orderhistory[0].status === "ok") {
        var ReceiptDetails = nextProps.orderhistory[0].result_set[0];

        this.setState(
          {
            CartItems: nextProps.orderhistory[0].result_set[0]["items"],
            ReceiptDetails: ReceiptDetails,
          },
          function () {
            if (this.state.ReceiptDetails.delivery_multi_location === "Yes") {
              $(".receipt_popup").addClass("receipt_multi_location");
            } else {
              $(".receipt_popup").removeClass("receipt_multi_location");
            }
            $("#dvLoading").fadeOut(5000);
            setTimeout(function () {
              $.magnificPopup.open({
                items: {
                  src: ".receipt_popup",
                },
                type: "inline",
              });
            }, 1000);
          }
        );
      } else {
        this.setState({ ReceiptDetails: [] });
      }
    }

    if (nextProps.printorder !== this.props.printorder) {
      if (nextProps.printorder[0].status === "ok") {
        $("#dvLoading").fadeOut(2000);
        window.open(nextProps.printorder[0].pdf_url, "_blank");
      }
    }
  }

  getReceipt(orderId) {
    $("#dvLoading").fadeIn();
    var params =
      "&customer_id=" + cookie.load("UserId") + "&order_id=" + orderId;
    this.props.getOrderHistory(params);
  }

  printReceipt(orderId, availabilityId) {
    $("#dvLoading").fadeIn();
    this.props.getPrintOrder(orderId, deliveryId);
  }

  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }

    return ordTmSlt;
  }

  getOrderItemData = (dataProp) => {
    var dataProp = dataProp !== undefined ? dataProp : Array();
    if (Object.keys(dataProp).length > 0) {
      return dataProp.map((item) => {
        const orderDate = Moment(item.order_date).format("DD/MM/YYYY");
        /* Advanced Slot */
        const orderTime = this.showOrdTimeSlot(item);
        return (
          <div key={item.order_id} className="current_order">
            <div className="myacc_head_sec">
              <div className="head_left">
                <div className="head-group">
                  <h4>ORDER NO - {item.order_local_no}</h4>
                </div>
              </div>
              <div className="head_right">
                <div className="head-group">
                  <h4>{item.status_name}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_body">
              <div className="order_no_deatails">
                Order placed at :{" "}
                {Moment(item.order_created_on).format("DD/MM/YYYY") +
                  " " +
                  Moment(item.order_created_on).format("h:mm A")}
                <span>Pay by : {item.order_method_name}</span>
              </div>
              {item.order_only_voucher !== "Yes" && (
                <div>
                  {item.order_availability_id === deliveryId ? (
                    <div className="delivery_total delivery_total_text">
                      <div className="delivery_total_left">
                        <h3>Order Handling By</h3>
                        <span>
                          {item.outlet_name !== "" && item.outlet_name !== null
                            ? stripslashes(item.outlet_name)
                            : ""}
                        </span>
                        {/* <span>
                      {addressFormat(
                        item.outlet_unit_number1,
                        item.outlet_unit_number2,
                        item.outlet_address_line1,
                        item.outlet_address_line2,
                        item.outlet_postal_code
                      )}
                    </span> */}
                        {/* item.outlet_phone !== "" && (
                      <span>Tel: +65-{item.outlet_phone}</span>
                    ) */}
                        {/* item.outlet_email !== "" && (
                      <span>{item.outlet_email}</span>
                    ) */}
                      </div>
                      <div className="delivery_total_left delivery_total_right">
                        {item.delivery_multi_location === "Yes" ? (
                          <h3>Multi Delivery Location</h3>
                        ) : (
                          <>
                            <h3>Delivery Location</h3>
                            <span>
                              {/* item.order_customer_unit_no2, */}
                              {addressFormat(
                                item.order_customer_unit_no1,
                                "",
                                item.order_customer_address_line1,
                                item.order_customer_address_line2,
                                item.order_customer_postal_code
                              )}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="delivery_total delivery_total_text pickup-order-div">
                      <div className="delivery_total_left">
                        <h3>Pickup Location</h3>
                        <span>
                          {item.outlet_name !== "" &&
                          item.outlet_name !== undefined
                            ? stripslashes(item.outlet_name)
                            : ""}
                        </span>
                        <span>
                          {addressFormat(
                            item.outlet_unit_number1,
                            item.outlet_unit_number2,
                            item.outlet_address_line1,
                            item.outlet_address_line2,
                            item.outlet_postal_code
                          )}
                        </span>
                        {item.outlet_phone !== "" && (
                          <span>Tel: +65-{item.outlet_phone}</span>
                        )}
                        {item.outlet_email !== "" && (
                          <span>{item.outlet_email}</span>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="delivery_total delivery_total_number">
                    <div className="delivery_total_left">
                      <h2>
                        {item.order_availability_id === deliveryId
                          ? "Delivery"
                          : "Pickup"}{" "}
                        Date
                      </h2>
                      <h4 className="checkoutDate">{orderDate}</h4>
                    </div>
                    <div className="delivery_total_left delivery_total_right">
                      <h2>
                        {item.order_availability_id === deliveryId
                          ? "Delivery"
                          : "Pickup"}{" "}
                        time
                      </h2>
                      <h4 className="checkoutTime">{orderTime}</h4>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="order_details_footer">
              <div className="order_amt">
                <div className="order_amt-left">
                  <h3>Total</h3>
                </div>
                <div className="order_amt-right">
                  <h3 className="text-right">
                    {showPrices(item.order_total_amount, "Y")}
                  </h3>
                </div>
              </div>
              <div className="order_btns">
                {item.order_only_voucher !== "Yes" && (
                  <a
                    href={void 0}
                    onClick={this.printReceipt.bind(
                      this,
                      item.order_primary_id
                    )}
                    className="button print_invoice"
                    title=""
                  >
                    Print Invoice
                  </a>
                )}
                <a
                  href={void 0}
                  onClick={this.getReceipt.bind(this, item.order_id)}
                  className={
                    item.order_only_voucher === "Yes"
                      ? "button view_recipt fullwidth"
                      : "button view_recipt"
                  }
                  title=""
                >
                  View Receipt
                </a>
              </div>
              {/* )} */}
            </div>
          </div>
        );
      });
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  };

  reservStatus(statusTxt) {
    var returnTxt = "Pending";
    statusTxt = statusTxt !== "" ? parseInt(statusTxt) : 0;
    if (statusTxt === 2) {
      returnTxt = "Cancel";
    } else if (statusTxt === 1) {
      returnTxt = "Assigned";
    } else if (statusTxt === 3) {
      returnTxt = "Arrived";
    } else if (statusTxt === 4) {
      returnTxt = "Not Arrived";
    } else if (statusTxt === 5) {
      returnTxt = "Completed";
    }

    return returnTxt;
  }

  showNoOfPax(adultCount, childCount) {
    var adultTxt =
      parseInt(adultCount) == 0
        ? ""
        : parseInt(adultCount) == 1
        ? adultCount + " Adult"
        : adultCount + " Adults";
    var childTxt =
      parseInt(childCount) == 0
        ? ""
        : parseInt(childCount) == 1
        ? childCount + " Child"
        : childCount + " Children";
    var paxText = adultTxt + " " + childTxt;

    return paxText;
  }
  openActiveTab(currentTab) {
    this.setState({ currentTab: currentTab });
  }

  printReceiptPop = (order_primary_id) => {
    this.printReceipt(order_primary_id);
  };

  render() {
    let customer_membership_type = "";
    let membership_displayname = "";
    let membership_spent_msg = "";
    if (Object.keys(this.props.customerdetail).length) {
      customer_membership_type =
        this.props.customerdetail[0].result_set.customer_membership_type;
      membership_displayname =
        this.props.customerdetail[0].result_set.customer_membership_displayname;
      membership_spent_msg =
        this.props.customerdetail[0].result_set.membership_spent_msg;
    }

    return (
      <div className="myacc-main-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>My Orders</p>
            <span>View Your Current & Past Orders Here</span>
          </div>
        </div>
        <div className="innersection_wrap myadmin_wrap">
          {/* container div - start */}
          <div className="container">
            <div className="mainacc_menusec">
              <Sidebar currentpage={"myorders"} />
              <div className="mainacc_menucontent">
                <Membership
                  customer_membership_type={customer_membership_type}
                  membership_displayname={membership_displayname}
                  membership_spent_msg={membership_spent_msg}
                />
                <div className="main_tabsec">
                  <div className="order-tab-section">
                    <div className="mainacc_mobrow">
                      <div className="tab_sec main_tabsec_inner">
                        <div className="myacc_filter">
                          <div className="tab_sec filter_tabsec" id="ordertab1">
                            <ul className="nav nav-tabs text-center">
                              <li
                                className={
                                  this.state.currentTab === "C" ? "active" : ""
                                }
                              >
                                <a
                                  data-toggle="tab"
                                  href={void 0}
                                  onClick={this.openActiveTab.bind(this, "C")}
                                  aria-expanded="true"
                                >
                                  <span>Current Orders</span>
                                </a>
                              </li>
                              <li
                                className={
                                  this.state.currentTab === "P" ? "active" : ""
                                }
                              >
                                <a
                                  data-toggle="tab"
                                  href={void 0}
                                  onClick={this.openActiveTab.bind(this, "P")}
                                  aria-expanded="false"
                                >
                                  <span>Past Orders</span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              {this.state.currentTab === "C" && (
                                <div
                                  id="tab-id-inn5"
                                  className="tab-pane fade active in"
                                >
                                  <h4 className="tab_mobtrigger inner_tab_border active">
                                    Current orders<i></i>
                                  </h4>
                                  <div className="tab_mobrow filter_tabin">
                                    <div className="order-delivery">
                                      <div className="ord-body">
                                        <div className="cur-order-body">
                                          <div className="myacc_order_details">
                                            {this.getOrderItemData(
                                              this.state.corderdetail
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="load_more_div">
                                    <button
                                      className="load_more_data"
                                      onClick={this.loadcurrentItems.bind(this)}
                                      style={{ display: "none" }}
                                    >
                                      Load More
                                    </button>
                                  </div>
                                </div>
                              )}
                              {this.state.currentTab === "P" && (
                                <div
                                  id="tab-id-inn6"
                                  className="tab-pane fade active in"
                                >
                                  <h4 className="tab_mobtrigger inner_tab_border active">
                                    Past Orders<i></i>
                                  </h4>
                                  <div className="tab_mobrow filter_tabin">
                                    <div className="pst-order-body">
                                      <div className="myacc_order_details">
                                        {this.getOrderItemData(
                                          this.state.porderdetail
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="load_more_div">
                                    <button
                                      className="load_more_data1"
                                      style={{ display: "none" }}
                                      onClick={this.loadpastItems.bind(this)}
                                    >
                                      Load More
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {/* next tab */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* container div - end */}
        </div>

        <Footer />

        <div
          id="receipt-popup"
          className="white-popup mfp-hide popup_sec receipt_popup"
        >
          <div className="pouup_in">
            <Viewreceipt
              details={this.state.ReceiptDetails}
              cartItems={this.state.CartItems}
              printReceiptPop={this.printReceiptPop}
            />
          </div>
        </div>
        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    corderdetail: state.corderdetail,
    porderdetail: state.porderdetail,
    printorder: state.printorder,
    orderhistory: state.orderhistory,
    activitycount: state.activitycount,
    customerdetail: state.customerdetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCorderDetail: (deliveryparams) => {
      dispatch({ type: GET_CORDERDETAIL, deliveryparams });
    },
    getPorderDetail: (deliverypastparams) => {
      dispatch({ type: GET_PORDERDETAIL, deliverypastparams });
    },
    getPrintOrder: (orderprimaryId, availabilityId) => {
      dispatch({ type: GET_PRINTORDER, orderprimaryId, availabilityId });
    },
    getOrderHistory: (params) => {
      dispatch({ type: GET_ORDERHISTORY, params });
    },
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};

Orders.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));

class Viewreceipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showItemList: [],
    };
  }

  getCartDetList(cartItems) {
    if (cartItems.length > 0) {
      return cartItems.map((item, index) => (
        <div className="cart_row product-details oreder_itm_row" key={index}>
          <div className="row-replace">
            <div className="col-sm-cls cart_left text-left">
              <div className="cart_info">
                <h4>
                  {stripslashes(item.item_name)} X {item.item_qty}
                </h4>
                {this.loadModifierItems(
                  "Component",
                  item.modifiers,
                  item.set_menu_component
                )}
              </div>
              {item.item_specification !== "" && (
                <p className="help-block">
                  Message On Cake: {stripslashes(item.item_specification)}
                </p>
              )}
              {item.item_card_notes !== "" && item.item_card_notes !== null && (
                <p className="help-block">
                  Message On Gift Card: {stripslashes(item.item_card_notes)}
                </p>
              )}
              {Object.keys(item.voucher_details).length > 0 &&
                item.voucher_details.order_item_gift_sub_category_id !== "" &&
                item.voucher_details.order_item_gift_sub_category_id !== null &&
                item.voucher_details.order_item_gift_sub_category_name !== "" &&
                item.voucher_details.order_item_gift_sub_category_name !==
                  null && (
                  <div className="cart_extrainfo">
                    <p>
                      <b>Gift Card</b> :{" "}
                      {item.voucher_details.order_item_gift_sub_category_name}
                    </p>
                  </div>
                )}
              {Object.keys(item.voucher_details).length > 0 &&
                item.voucher_details.voucher_for !== "me" && (
                  <div className="cart_extrainfo">
                    <p>
                      <b>Gift Details</b>
                    </p>
                    {item.voucher_details.voucher_gift_name !== "" &&
                      item.voucher_details.voucher_gift_name !== null && (
                        <p>Name: {item.voucher_details.voucher_gift_name}</p>
                      )}
                    {item.voucher_details.order_item_voucher_mobile !== "" &&
                      item.voucher_details.order_item_voucher_mobile !==
                        null && (
                        <p>
                          Mobile:{" "}
                          {item.voucher_details.order_item_voucher_mobile}
                        </p>
                      )}
                    {item.voucher_details.order_item_voucher_email !== "" &&
                      item.voucher_details.order_item_voucher_email !==
                        null && (
                        <p>
                          Email: {item.voucher_details.order_item_voucher_email}
                        </p>
                      )}
                    {item.voucher_details.order_item_voucher_message !== "" &&
                      item.voucher_details.order_item_voucher_message !==
                        null && (
                        <p>
                          Message:{" "}
                          {item.voucher_details.order_item_voucher_message}
                        </p>
                      )}
                  </div>
                )}

              {/*} 
				 <p className ="help-block">Special instruction for individual product items ...</p>
				 {*/}
            </div>
            <div className="col-sm-cls cart_right text-right">
              <div className="cart_price">
                <p>
                  {showPrices(parseFloat(item.item_total_amount).toFixed(2))}
                </p>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  }

  getMultiCartDetList(cartItems, multiLocationList) {
    if (multiLocationList.length > 0) {
      return multiLocationList.map((item, index) => {
        var deliveryItem = item.items;
        var totalItem = Object.keys(deliveryItem).length;

        var showItem =
          this.state.showItemList[index] !== "" &&
          typeof this.state.showItemList[index] !== undefined &&
          typeof this.state.showItemList[index] !== "undefined"
            ? this.state.showItemList[index]
            : 2;
        var showMore = false;
        if (totalItem >= 2) {
          showMore = true;
        }
        return (
          <div className="mor-inner" key={index}>
            <div className="more-count">{parseInt(index) + 1}</div>
            <div className="mor-inner-lhs">
              <ul>
                <li>
                  <span>Contact Details</span>
                  <p>
                    {item.order_name} | {item.order_phone_number}
                  </p>
                </li>
                <li>
                  <span>Delivery Address</span>
                  <p>{item.order_address}</p>
                </li>
                {item.order_message !== "" && item.order_message !== null && (
                  <li>
                    <span>Special Message</span>
                    <p>{stripslashes(item.order_message)}</p>
                  </li>
                )}
              </ul>
            </div>
            <div className="mor-inner-rhs">
              <ul>
                {Object.keys(deliveryItem).map((delItem, delindex) => {
                  return (
                    <li key={delindex}>
                      {cartItems.map((orderItem, orderindex) => {
                        if (
                          orderItem.item_id === delItem &&
                          orderindex < showItem
                        ) {
                          return (
                            <div key={orderindex}>
                              <div className="mor-inner-main">
                                <div className="mcart-img">
                                  <span className="count-mid">
                                    {deliveryItem[delItem]}
                                  </span>
                                  <img
                                    src={
                                      orderItem.item_image !== "" &&
                                      orderItem.item_image !== null
                                        ? orderItem.item_image
                                        : innerbanner
                                    }
                                  />
                                </div>

                                <div className="mcart-info">
                                  <h4>{stripslashes(orderItem.item_name)}</h4>
                                  {this.loadModifierItems(
                                    "Component",
                                    orderItem.modifiers,
                                    orderItem.set_menu_component
                                  )}
                                  {orderItem.item_specification !== "" && (
                                    <p>
                                      Message On Cake:{" "}
                                      {stripslashes(
                                        orderItem.item_specification
                                      )}
                                    </p>
                                  )}
                                  {orderItem.item_card_notes !== "" &&
                                    orderItem.item_card_notes !== null && (
                                      <p>
                                        Message On Gift Card:{" "}
                                        {stripslashes(
                                          orderItem.item_card_notes
                                        )}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="mor-inner-info">
                                <p>
                                  {showPrices(
                                    (
                                      parseFloat(orderItem.item_unit_price) *
                                      deliveryItem[delItem]
                                    ).toFixed(2)
                                  )}
                                </p>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </li>
                  );
                })}
              </ul>
              {showMore === true && totalItem > 2 && (
                <div className="mor-btm-link">
                  <a
                    href={void 0}
                    onClick={this.showMore.bind(this, index, totalItem)}
                  >
                    {showItem === 2 ? "VIEW MORE" : "LESS"}
                  </a>
                </div>
              )}
            </div>
          </div>
        );
      });
    }
  }
  showMore(index, totalItem) {
    var showItemList = this.state.showItemList;
    if (
      showItemList[index] !== "" &&
      typeof showItemList[index] !== undefined &&
      typeof showItemList[index] !== "undefined"
    ) {
      if (showItemList[index] > 2) {
        showItemList[index] = 2;
      } else {
        showItemList[index] = totalItem;
      }
    } else {
      showItemList[index] = totalItem;
    }
    this.setState({ showItemList: showItemList });
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;

    var html = '<div class="cart_extrainfo">';
    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        html +=
          "<p><b>" +
          stripslashes(modName) +
          ":</b></p><p> " +
          stripslashes(modval) +
          "</p>";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);
        html +=
          "<p><b>" +
          comboName +
          ":</b></p><p> " +
          comboVal +
          " " +
          this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) +
          "</p>";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]["menu_product_name"];
        var comboQty = combos[r]["menu_product_qty"];
        var comboPrice = combos[r]["menu_product_price"];
        var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
        var comboQtyChk = comboQty !== "" ? parseInt(comboQty) : 0;
        if (comboQtyChk > 0) {
          /* + newPrice */
          html += "<p>" + comboQty + " X " + comboPro + " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "";
    if (lenMod > 0) {
      html = "<div > ";
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["order_modifier_price"];
        if (parseFloat(modValPrice) > 0) {
          var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
          html +=
            "<p><b>" +
            modName +
            ":</b></p><p> " +
            modval +
            newModValPrice +
            "</p>";
        }
      }
      html += "</div>";
    }
    return html;
  }
  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }

    return ordTmSlt;
  }
  printReceipt_Pop(order_primary_id) {
    this.props.printReceiptPop(order_primary_id);
  }
  sendMail(order_primary_id) {
    axios
      .get(
        apiUrl +
          "ordersv1/order_email?app_id=" +
          appId +
          "&order_primary_id=" +
          order_primary_id +
          "&mode="
      )
      .then((res) => {
        if (res.data.status === "ok") {
          showAlert("Success", res.data.message);
        } else {
          showAlert("Error", "Somthing went wrong try again");
        }

        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      });
  }
  render() {
    const { details, cartItems } = this.props;
    if (details !== undefined) {
      return (
        <div className="Viewreceipt">
          <div className="tnk-you">
            <div className="tnk-header">
              <div className="tnk-detail">
                <h2>
                  <span>
                    <a
                      href={void 0}
                      onClick={this.printReceipt_Pop.bind(
                        this,
                        details.order_primary_id
                      )}
                      className="printer-icon"
                    >
                      {" "}
                      <img src={printer} />{" "}
                    </a>
                    <a
                      href={void 0}
                      onClick={this.sendMail.bind(
                        this,
                        details.order_primary_id
                      )}
                      className="email-icon"
                    >
                      {" "}
                      <img src={mailthank} />{" "}
                    </a>
                  </span>
                  YOUR ORDER DETAILS
                </h2>
                <div className="tnk-order">
                  <h3>Order No - {details.order_local_no}</h3>
                  <p>
                    Order placed at :{" "}
                    {Moment(details.order_created_on).format(
                      "DD-MM-YYYY hh:mm A"
                    )}
                    <br></br>Pay by : {details.order_method_name}
                  </p>
                  {details.delivery_multi_location === "Yes" && (
                    <h5 className="multinew">Multi Delivery Locations</h5>
                  )}
                </div>
              </div>
            </div>
            <div className="tnk-delivery">
              {details.order_only_voucher !== "Yes" && (
                <div>
                  {details.delivery_multi_location !== "Yes" && (
                    <div>
                      {details.order_availability_id === deliveryId ? (
                        <div className="delivery_total">
                          <div className="delivery_total_left">
                            <h3>Delivery From</h3>
                            <span>
                              {details.outlet_name !== "" &&
                              details.outlet_name !== undefined
                                ? stripslashes(details.outlet_name)
                                : ""}
                            </span>
                            {/* <span>
                          {addressFormat(
                            details.outlet_unit_number1,
                            details.outlet_unit_number2,
                            details.outlet_address_line1,
                            details.outlet_address_line2,
                            details.outlet_postal_code
                          )}
                        </span>
                        {details.outlet_phone !== "" && (
                          <span>Tel: +65{details.outlet_phone}</span>
                        )}
                        {details.outlet_email !== "" && (
                          <span>{details.outlet_email}</span>
                        )} */}
                          </div>
                          <div className="delivery_total_left delivery_total_right">
                            <h3>Deliver TO</h3>
                            <span>
                              {details.order_customer_address_line1}
                            </span>{" "}
                            <span>
                              Singapore {details.order_customer_postal_code}
                            </span>{" "}
                            <span>
                              {/*  &&
                          details.order_customer_unit_no2 != ""
                            "-" +
                               +details.order_customer_unit_no2
                             */}
                              {details.order_customer_unit_no1 != ""
                                ? details.order_customer_unit_no1
                                : ""}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="delivery_total pickup-order-div">
                          <div className="delivery_total_left">
                            <h3>Pickup Location</h3>
                            <span>
                              {details.outlet_name !== "" &&
                              details.outlet_name !== undefined
                                ? stripslashes(details.outlet_name)
                                : ""}
                            </span>
                            <span>
                              {addressFormat(
                                details.outlet_unit_number1,
                                details.outlet_unit_number2,
                                details.outlet_address_line1,
                                details.outlet_address_line2,
                                details.outlet_postal_code
                              )}
                            </span>
                            {details.outlet_phone !== "" && (
                              <span>Tel: +65-{details.outlet_phone}</span>
                            )}
                            {details.outlet_email !== "" && (
                              <span>{details.outlet_email}</span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="delivery_total delivery_total_number">
                    <div className="delivery_total_left">
                      <h2>
                        {details.order_availability_id === deliveryId
                          ? "Delivery"
                          : "Pickup"}{" "}
                        Date
                      </h2>
                      <h4 className="checkoutDate">
                        {Moment(details.order_date).format("DD-MM-YYYY")}
                      </h4>
                    </div>
                    <div className="delivery_total_left delivery_total_right">
                      <h2>
                        {details.order_availability_id === deliveryId
                          ? "Delivery"
                          : "Pickup"}{" "}
                        time
                      </h2>
                      {/* Advanced Slot */}
                      <h4 className="checkoutTime">
                        {this.showOrdTimeSlot(details)}
                      </h4>
                    </div>
                  </div>
                </div>
              )}

              <div className="order-items-maindiv">
                {details.delivery_multi_location === "Yes" ? (
                  <div className="multi-order-recipt">
                    {this.getMultiCartDetList(
                      cartItems,
                      details.delivery_multi_location_list
                    )}
                  </div>
                ) : (
                  this.getCartDetList(cartItems)
                )}

                {details.order_remarks !== "" && (
                  <>
                    <div className="remark_notesec text-left">
                      <h4>Remarks</h4>
                      <p>{details.order_remarks}</p>
                    </div>
                    <br />
                  </>
                )}

                {details.order_customer_send_gift === "Yes" && (
                  <>
                    {details.order_recipient_name !== "" &&
                      details.order_recipient_name !== null && (
                        <div className="remark_notesec text-left">
                          <h4>Recipient Name</h4>
                          <p>{details.order_recipient_name}</p>
                        </div>
                      )}
                    {details.order_recipient_contact_no !== "" &&
                      details.order_recipient_contact_no !== null && (
                        <div className="remark_notesec text-left">
                          <h4>Recipient Contact Number</h4>
                          <p>{details.order_recipient_contact_no}</p>
                        </div>
                      )}
                    {details.order_gift_message !== "" &&
                      details.order_gift_message !== null && (
                        <div className="remark_notesec text-left">
                          <h4>Gift Message</h4>
                          <p>{details.order_gift_message}</p>
                        </div>
                      )}
                    <br />
                  </>
                )}
              </div>

              <div className="cart_footer tnk_cart_footer">
                <div className="cart_row">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">SUBTOTAL</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>{showPrices(details.order_sub_total)}</span>
                    </div>
                  </div>
                </div>
                {parseFloat(details.order_discount_amount) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {details.order_discount_type === "redeem"
                            ? "Discount (-)"
                            : "Promocode (-)"}
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(details.order_discount_amount).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(details.order_voucher_discount_amount) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">Voucher Discount(-)</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              details.order_voucher_discount_amount
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {details.order_corporate_wallet_pay === "Yes" &&
                  details.order_corporate_wallet_amount !== "" &&
                  details.order_corporate_wallet_amount !== null &&
                  parseFloat(details.order_corporate_wallet_amount) > 0 > 0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">Corporate Wallet(-)</p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(
                              parseFloat(
                                details.order_corporate_wallet_amount
                              ).toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                {details.order_cpcustomer_discount_applied === "Yes" &&
                  details.order_cpcustomer_discount_amount !== "" &&
                  details.order_cpcustomer_discount_amount !== null &&
                  parseFloat(details.order_cpcustomer_discount_amount) >
                    0 >
                    0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            Corporate Discount(-)
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(
                              parseFloat(
                                details.order_cpcustomer_discount_amount
                              ).toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                {parseFloat(details.order_delivery_charge) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">Delivery Charges</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(details.order_delivery_charge).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(details.order_hourly_delivery_charge) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          Hourly Specific Delivery
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              details.order_hourly_delivery_charge
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(details.order_additional_delivery) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          Additional Delivery Charge
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              details.order_additional_delivery
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {details.order_tax_calculate_amount > 0 &&
                  parseFloat(details.order_tax_calculate_amount_inclusive) ===
                    0 && (
                    <div className="cart_row gst-row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {" "}
                            GST ({details.order_tax_charge}%){" "}
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(details.order_tax_calculate_amount)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                {parseFloat(details.order_tax_calculate_amount) > 0 &&
                  parseFloat(details.order_tax_calculate_amount_inclusive) >
                    0 && (
                    <div className="cart_row gst-row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            Inclusive GST(
                            {parseFloat(details.order_tax_charge)}%)
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(
                              parseFloat(
                                details.order_tax_calculate_amount
                              ).toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                <div className="cart_row cart_footer_totrow grant-total-cls">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">Total</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>{showPrices(details.order_total_amount, "Y")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
