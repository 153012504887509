/* eslint-disable */
import React from "react";
import cookie from "react-cookies";
import noimage from "../../common/images/noimg-800x800.jpg";
import { deliveryId, currencySymbol } from "./Config";
// import $ from "jquery";

/* stripslashes  */
export const stripslashes = function (str) {
  if (
    str !== "" &&
    str !== null &&
    typeof str !== undefined &&
    typeof str !== "undefined"
  ) {
    str = str.replace(/\\'/g, "'");
    str = str.replace(/\\"/g, '"');
    str = str.replace(/\\0/g, "\0");
    str = str.replace(/\\\\/g, "\\");
    return str;
  }
};

/* Random ID  */
export const randomId = function () {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < 50; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const showPrices = function (price, sub = "N") {
  if (price !== "" && sub === "Y") {
    return (
      <>
        <sup>{currencySymbol}</sup>
        {price}
      </>
    );
  } else {
    return (
      <>
        {currencySymbol}
        {price}
      </>
    );
  }
};

/* Call image */
export const callImage = function (
  image_source,
  image,
  width,
  height,
  timthumb,
  imageTag = "Yes"
) {
  var imagesource = "";

  if (image !== null && image !== "") {
    imagesource =
      timthumb +
      image_source +
      "/" +
      image +
      "&w=" +
      width +
      "&h=" +
      height +
      "&q=80";
  } else {
    imagesource = noimage;
  }
  if (imageTag === "Yes") {
    return <img src={imagesource} alt="" width={width} height={height} />;
  } else {
    return imagesource;
  }
};

/* Email Validation */
export const emailValidate = function (email) {
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  if (email !== "") {
    if (pattern.test(email)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

/* sample funtion */
export const showSubTotalValue = function (price) {
  price = price !== "" ? parseFloat(price) : 0.0;
  var priceTxt = price.toFixed(2);
  return priceTxt;
};

/*Reference ID Generate*/
export const getReferenceID = function () {
  if (typeof cookie.load("referenceId") === "undefined") {
    var randomKey = randomId();
    cookie.save("referenceId", randomKey, { path: "/" });
    return randomKey;
  } else {
    return cookie.load("referenceId");
  }
};

/*remove promotion value*/
export const removePromoCkValue = function () {
  cookie.remove("reedemPointVal", { path: "/" });
  cookie.remove("promoCodeVal", { path: "/" });
  cookie.remove("promotionApplied", { path: "/" });
  cookie.remove("promotionType", { path: "/" });
  cookie.remove("promotionAmount", { path: "/" });
  cookie.remove("promotionSource", { path: "/" });
  cookie.remove("promoIsDelivery", { path: "/" });
  cookie.remove("usedPoints", { path: "/" });
};

/*remove order date time*/
export const removeOrderDateTime = function () {
  cookie.remove("orderDateTime", { path: "/" });
  cookie.remove("deliveryDate", { path: "/" });
  cookie.remove("deliveryTime", { path: "/" });
};

/*remove promotion value*/
export const getPromoCkValue = function () {
  var reedemPointVal =
    typeof cookie.load("reedemPointVal") === "undefined"
      ? ""
      : cookie.load("reedemPointVal");
  var promoCodeVal =
    typeof cookie.load("promoCodeVal") === "undefined"
      ? ""
      : cookie.load("promoCodeVal");
  var promotionApplied =
    typeof cookie.load("promotionApplied") === "undefined"
      ? ""
      : cookie.load("promotionApplied");
  var promotionType =
    typeof cookie.load("promotionType") === "undefined"
      ? ""
      : cookie.load("promotionType");
  var promotionAmount =
    typeof cookie.load("promotionAmount") === "undefined"
      ? ""
      : cookie.load("promotionAmount");
  var promotionSource =
    typeof cookie.load("promotionSource") === "undefined"
      ? ""
      : cookie.load("promotionSource");
  var promoIsDelivery =
    typeof cookie.load("promoIsDelivery") === "undefined"
      ? ""
      : cookie.load("promoIsDelivery");
  var usedPoints =
    typeof cookie.load("usedPoints") === "undefined"
      ? 0
      : cookie.load("usedPoints");

  var resultArr = [];
  resultArr["reedemPointVal"] = reedemPointVal;
  resultArr["promoCodeVal"] = promoCodeVal;
  resultArr["promotionApplied"] = promotionApplied;
  resultArr["promotionType"] = promotionType;
  resultArr["promotionAmount"] = promotionAmount;
  resultArr["promotionSource"] = promotionSource;
  resultArr["promoIsDelivery"] = promoIsDelivery;
  resultArr["usedPoints"] = usedPoints;

  return resultArr;
};

/*get reedemPoint value*/
export const getCCPromoValue = function () {
  var ccpromoSubTotal =
    typeof cookie.load("ccpromoSubTotal") === "undefined"
      ? 0
      : cookie.load("ccpromoSubTotal");
  var ccpromoCodeVal =
    typeof cookie.load("ccpromoCodeVal") === "undefined"
      ? ""
      : cookie.load("ccpromoCodeVal");
  var ccpromotionApplied =
    typeof cookie.load("ccpromotionApplied") === "undefined"
      ? "No"
      : cookie.load("ccpromotionApplied");
  var ccpromotionAmount =
    typeof cookie.load("ccpromotionAmount") === "undefined"
      ? 0
      : cookie.load("ccpromotionAmount");
  var ccpromoIsDelivery =
    typeof cookie.load("ccpromoIsDelivery") === "undefined"
      ? "No"
      : cookie.load("ccpromoIsDelivery");

  var resultArr = [];
  resultArr["ccpromoSubTotal"] = ccpromoSubTotal;
  resultArr["ccpromoCodeVal"] = ccpromoCodeVal;
  resultArr["promotionApplied"] = ccpromotionApplied;
  resultArr["promotionAmount"] = ccpromotionAmount;
  resultArr["promoIsDelivery"] = ccpromoIsDelivery;

  return resultArr;
};

/* address format */
export const addressFormat = function (
  unitOne,
  unitTwo,
  addressOne,
  addressTwo,
  postCode
) {
  var unit =
    unitTwo !== "" && unitOne !== ""
      ? "#" + unitOne + "-" + unitTwo + ", "
      : unitOne !== ""
      ? "#" + unitOne + ", "
      : "";
  unit = addressOne !== "" ? unit + addressOne + ", " : unit;
  unit = addressTwo !== "" ? unit + addressTwo + ", " : unit;
  unit = postCode !== "" ? unit + " Singapore-" + postCode : unit;

  return unit !== "" ? unit.replace(/,\s*$/, "") : "N/A";
};

/* delivery charge */
export const getCalculatedAmount = function (
  settingsArr,
  zoneDetailsArr,
  cartDetailsArr,
  cartItems,
  promoTionArr,
  multideliverylocation,
  walletPayAmount,
  ccPromoTionArr
) {
  var deliveryAmount = 0;
  var additionalDelivery = 0;
  var hourlyDelivery = 0;
  var freeDeliveryAmnt = 0;
  var orderDisplayGst = 0;
  var orderGstAmount = 0;
  var orderDisplayInclusiveGst = 0;
  var cartSubTotal = 0;
  var promotionAmount = 0;
  var grandTotalAmount = 0;
  var voucherDiscountAmount = 0;
  var minOrderAmnt = 0;
  var promotionApplied = "";
  var promotionTypeTxt = "";
  var walletAmount = walletPayAmount !== "" ? parseFloat(walletPayAmount) : 0;
  var ccpromotionApplied = "";
  var ccpromotionAmount = 0;

  if (Object.keys(settingsArr).length > 0) {
    orderDisplayGst =
      settingsArr.client_tax_surcharge !== ""
        ? parseFloat(settingsArr.client_tax_surcharge)
        : 0;
    orderDisplayInclusiveGst =
      settingsArr.client_tax_surcharge_inclusive !== ""
        ? parseFloat(settingsArr.client_tax_surcharge_inclusive)
        : 0;
  }

  if (Object.keys(zoneDetailsArr).length > 0) {
    if (
      settingsArr.zone_order_value_delivery_charge !== undefined &&
      settingsArr.zone_order_value_delivery_charge === "1"
    ) {
      deliveryAmount =
        cartDetailsArr.cart_zone_delivery_charge !== "" &&
        typeof cartDetailsArr.cart_zone_delivery_charge !== undefined &&
        typeof cartDetailsArr.cart_zone_delivery_charge !== "undefined"
          ? parseFloat(cartDetailsArr.cart_zone_delivery_charge)
          : 0;
    } else {
      deliveryAmount =
        zoneDetailsArr[0].zone_delivery_charge !== ""
          ? parseFloat(zoneDetailsArr[0].zone_delivery_charge)
          : 0;
    }

    additionalDelivery =
      zoneDetailsArr[0].zone_additional_delivery_charge !== ""
        ? parseFloat(zoneDetailsArr[0].zone_additional_delivery_charge)
        : 0;
    minOrderAmnt =
      zoneDetailsArr[0].zone_min_amount !== ""
        ? parseFloat(zoneDetailsArr[0].zone_min_amount)
        : 0;

    freeDeliveryAmnt =
      zoneDetailsArr[0].zone_free_delivery !== ""
        ? parseFloat(zoneDetailsArr[0].zone_free_delivery)
        : 0;
  }
  var totalLocation = 1;
  if (Object.keys(cartDetailsArr).length > 0) {
    cartSubTotal = parseFloat(cartDetailsArr.cart_sub_total);
  }
  var firstDeliveryFee = 0;
  var multDelivery = "N";
  if (
    cookie.load("defaultAvilablityId") === deliveryId &&
    cookie.load("delivery_address_type") === "M"
  ) {
    var multideliveryCharge = 0;
    var multiadditionaldeliveryCharge = 0;
    if (multideliverylocation.length > 0) {
      totalLocation = multideliverylocation.length;
      multDelivery = "Y";
      multideliverylocation.map((item, index) => {
        if (index === 0) {
          firstDeliveryFee = parseFloat(item.delivery_charge);
        }

        multideliveryCharge =
          parseFloat(multideliveryCharge) + parseFloat(item.delivery_charge);

        multiadditionaldeliveryCharge =
          parseFloat(multiadditionaldeliveryCharge) +
          parseFloat(item.additional_delivery_charge);
      });

      deliveryAmount = multideliveryCharge;
      additionalDelivery = multiadditionaldeliveryCharge;
      freeDeliveryAmnt =
        parseFloat(settingsArr.client_free_delivery_amount) > 0
          ? settingsArr.client_free_delivery_amount
          : 0;
    }
  }

  if (Object.keys(cartDetailsArr).length > 0) {
    if (parseFloat(cartDetailsArr.cart_voucher_discount_amount) > 0) {
      voucherDiscountAmount = parseFloat(
        cartDetailsArr.cart_voucher_discount_amount
      );
    } else {
      voucherDiscountAmount = 0;
    }
  }

  if (
    ccPromoTionArr !== "" &&
    typeof ccPromoTionArr !== undefined &&
    typeof ccPromoTionArr !== "undefined"
  ) {
    if (ccPromoTionArr["promotionApplied"] === "Yes") {
      if (ccPromoTionArr["promoIsDelivery"] === "Yes") {
        deliveryAmount = 0;
      } else {
        ccpromotionAmount = ccPromoTionArr["promotionAmount"];
        ccpromotionAmount =
          ccpromotionAmount !== "" ? parseFloat(ccpromotionAmount) : 0;
      }
      ccpromotionApplied = "Yes";
    }
  }

  if (promoTionArr["promotionApplied"] === "Yes") {
    if (promoTionArr["promoIsDelivery"] === "Yes") {
      if (
        cookie.load("defaultAvilablityId") === deliveryId &&
        cookie.load("delivery_address_type") === "M"
      ) {
        deliveryAmount = deliveryAmount - firstDeliveryFee;
      } else {
        deliveryAmount = 0;
      }
    } else {
      promotionAmount = promoTionArr["promotionAmount"];
    }
  }

  minOrderAmnt =
    parseFloat(settingsArr.call_min_amount) > 0
      ? settingsArr.call_min_amount
      : 0;

  var applicable_free_delivery = "No";
  var checkinggrandTotalAmount =
    cartSubTotal - promotionAmount - ccpromotionAmount - walletAmount;

  if (minOrderAmnt > 0 && minOrderAmnt <= checkinggrandTotalAmount) {
    console.log(
      deliveryAmount,
      firstDeliveryFee,
      checkinggrandTotalAmount,
      promotionAmount,
      "deliveryAmountdeliveryAmountdeliveryAmount"
    );
    applicable_free_delivery = "Yes";
    if (multDelivery === "Y") {
      deliveryAmount = deliveryAmount - firstDeliveryFee;
      totalLocation = totalLocation - 1;
    } else {
      deliveryAmount = 0;
    }
  }
  if (cookie.load("delivery_time_type") === "2") {
    hourlyDelivery = totalLocation * 15;
  } else if (cookie.load("delivery_time_type") === "3") {
    hourlyDelivery = totalLocation * 35;
  }

  if (cookie.load("defaultAvilablityId") !== deliveryId) {
    if (
      cookie.load("defaultAvilablityId") === deliveryId &&
      cookie.load("delivery_address_type") === "M"
    ) {
      deliveryAmount = deliveryAmount - firstDeliveryFee;
    } else {
      deliveryAmount = 0;
    }

    additionalDelivery = 0;
  }

  var onlyVoucher = 0;
  var otherProduct = 0;
  if (
    cartItems !== "" &&
    typeof cartItems !== undefined &&
    typeof cartItems !== "undefined"
  ) {
    if (cartItems.length > 0) {
      cartItems.map(function (index) {
        if (index.cart_item_product_type === "5") {
          onlyVoucher++;
        } else {
          otherProduct++;
        }
      });
    }
  }
  if (onlyVoucher > 0 && otherProduct === 0) {
    deliveryAmount = 0;
    additionalDelivery = 0;
    minOrderAmnt = 0;
    freeDeliveryAmnt = 0;
  }

  grandTotalAmount =
    cartSubTotal +
    deliveryAmount +
    hourlyDelivery +
    additionalDelivery -
    promotionAmount -
    ccpromotionAmount -
    walletAmount;

  if (voucherDiscountAmount > 0) {
    grandTotalAmount = grandTotalAmount - voucherDiscountAmount;
    if (grandTotalAmount > 0) {
      grandTotalAmount = grandTotalAmount;
    } else {
      grandTotalAmount = "0.00";
    }
  }

  var inclusiveGST = "No";
  if (orderDisplayInclusiveGst > 0) {
    var vatDivisor = parseInt(1) + orderDisplayInclusiveGst / 100;
    var gstpercentage = orderDisplayInclusiveGst / 100;
    var productvalue = grandTotalAmount / vatDivisor;
    var orderGstInclusiveAmount = productvalue * gstpercentage;
    orderGstAmount = parseFloat(orderGstInclusiveAmount);
    orderDisplayGst = orderDisplayInclusiveGst;
    inclusiveGST = "Yes";
  } else if (orderDisplayGst > 0) {
    orderGstAmount = parseFloat((orderDisplayGst / 100) * grandTotalAmount);
  }
  if (inclusiveGST === "No") {
    grandTotalAmount =
      parseFloat(grandTotalAmount) + parseFloat(orderGstAmount);
  }

  var resultArr = [];
  resultArr["deliveryCharge"] = deliveryAmount;
  resultArr["additionalDelivery"] = additionalDelivery;
  resultArr["hourlyDelivery"] = hourlyDelivery;
  resultArr["freeDeliveryAmnt"] = freeDeliveryAmnt;
  resultArr["applicable_free_delivery"] = applicable_free_delivery;
  resultArr["minOrderAmnt"] = minOrderAmnt;
  resultArr["promotionApplied"] = promotionApplied;
  resultArr["promotionTypeTxt"] = promotionTypeTxt;
  resultArr["promotionAmount"] = promotionAmount;
  resultArr["voucherDiscountAmount"] = voucherDiscountAmount.toFixed(2);
  resultArr["orderDisplayGst"] = orderDisplayGst;
  resultArr["orderGstAmount"] = orderGstAmount.toFixed(2);
  resultArr["inclusiveGST"] = inclusiveGST;
  resultArr["cartSubTotalAmount"] = cartSubTotal.toFixed(2);
  resultArr["grandTotalAmount"] = grandTotalAmount.toFixed(2);
  resultArr["onlyVoucher"] =
    onlyVoucher > 0 && otherProduct === 0 ? "Yes" : "No";
  resultArr["ccpromotionApplied"] = ccpromotionApplied;
  resultArr["ccpromotionAmount"] = ccpromotionAmount;

  return resultArr;
};

/* show Alert */
export const showAlert = function (
  header,
  message,
  autoClose = "No",
  redirectLink = "",
  btnText = "OK",
  magnfPopup = "",
  addtionalBtn = "",
  addtionalBtnLink = ""
) {
  console.log(redirectLink);
  $(".alert_popup").remove();
  if (autoClose === "No") {
    var redirectBtnClass = "";
    if (redirectLink === "") {
      redirectLink = "javascript:;";
      redirectBtnClass = "popup-modal-dismiss";
    }
    var moreBrn = "";
    var onmoreclass = "";
    if (addtionalBtn !== "") {
      moreBrn = '<a href="';
      if (addtionalBtnLink !== "") {
        moreBrn += addtionalBtnLink;
      } else {
        moreBrn += "javascript:;";
      }
      moreBrn += '" class="';
      if (addtionalBtnLink == "") {
        moreBrn += "popup-modal-dismiss";
      } else {
        moreBrn += " continue-shp-btn";
      }
      moreBrn += ' button">' + addtionalBtn + "</a>";
      onmoreclass = "alert-shopping";
    }

    $("body").append(
      '<div class="white-popup mfp-hide popup_sec alert_popup custom-alrt-popupcls ' +
        onmoreclass +
        '" ><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"><a href=' +
        redirectLink +
        ' class="' +
        redirectBtnClass +
        ' button">' +
        btnText +
        "</a>" +
        moreBrn +
        "</div></div></div></div></div></div>"
    );
    if (redirectLink === "") {
      setTimeout(function () {
        magnfPopup.close();
      }, 1800);
    }
  } else {
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec alert_popup custom-alrt-popupcls" ><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"></div></div></div></div></div></div>'
    );
    setTimeout(function () {
      autoClose.close();
    }, 1800);
  }
};

/* smooth Scroll */
export const smoothScroll = function (limit, value) {
  var limitTxt = limit !== "" ? parseInt(limit) : 0;
  var valueTxt = value !== "" ? parseInt(value) : 0;

  var stickyTop = $(window).scrollTop();
  if (stickyTop > limitTxt) {
    var i = 10;
    var int = setInterval(function () {
      window.scrollTo(0, i);
      i += 10;
      if (i >= valueTxt) clearInterval(int);
    }, 20);
  }
};

/* show Custom Alert */
export const showCustomAlert = function (type, message) {
  var clsIdTxt = type === "success" ? "jquery-success-msg" : "jquery-error-msg";
  $(".custom_alertcls").hide();
  $("." + clsIdTxt).html(message);
  $("#" + clsIdTxt).fadeIn();
  setTimeout(function () {
    $(".custom_alertcls").hide();
  }, 6000);
};

/* show Custom Center Alert */
export const showCustomCenterAlert = function (type, message) {
  var clsIdTxt =
    type === "success"
      ? "jquery-common-success-msg"
      : "jquery-common-error-msg";
  $(".custom_center_alertcls").hide();
  $("." + clsIdTxt).html(message);
  $("#" + clsIdTxt).fadeIn();
  setTimeout(function () {
    $(".custom_center_alertcls").hide();
  }, 6000);
};

/* show Cart Count */
export const showCartItemCount = function (cartDetail) {
  var itemCount =
    Object.keys(cartDetail).length > 0 ? cartDetail.cart_total_items : 0;
  var subTotal =
    Object.keys(cartDetail).length > 0 ? cartDetail.cart_sub_total : 0;
  cookie.save("cartTotalItems", itemCount, { path: "/" });
  $(".hcart_round").html(itemCount);
  if (parseFloat(subTotal) > 0) {
    $(".crttotl_amt").show();
  } else {
    $(".crttotl_amt").hide();
  }
  var subTotalHtml = "<sup>$</sup>" + subTotal;
  $(".crttotl_amt").html(subTotalHtml);
};

/* show Loader */
export const showLoader = function (divRef, type) {
  if (type === "class") {
    $("." + divRef)
      .addClass("loader-main-cls")
      .append('<div class="loader-sub-div"></div>');
  } else {
    $("#" + divRef)
      .addClass("loader-main-cls")
      .append('<div class="loader-sub-div"></div>');
  }
};

/* hide Loader */
export const hideLoader = function (divRef, type) {
  if (type === "class") {
    $("." + divRef).removeClass("loader-main-cls");
    $("." + divRef)
      .find(".loader-sub-div")
      .remove();
  } else {
    $("#" + divRef).removeClass("loader-main-cls");
    $("#" + divRef)
      .find(".loader-sub-div")
      .remove();
  }
};

/* get subtotal value  */
export const getsubTotal = function (
  subTotal,
  OriginalAmount,
  promotionApplied,
  redeemptionApplied = null
) {
  if (promotionApplied === "Yes") {
    return subTotal;
  } else if (redeemptionApplied === "Yes") {
    return subTotal;
  } else {
    return OriginalAmount;
  }
};

/* get subtotal value  */
export const getDeliveryCharge = function (
  promotionApplied,
  deliveryEnabled,
  OriginalAmount,
  isFreeDelivery = null
) {
  if (
    (promotionApplied === "Yes" && deliveryEnabled === "Yes") ||
    isFreeDelivery === "Yes"
  ) {
    return 0;
  } else {
    return OriginalAmount;
  }
};

/* sample funtion */
export const showPriceValue = function (price) {
  price = price !== "" ? parseFloat(price) : 0.0;
  var priceTxt = "$" + price.toFixed(2);
  return priceTxt;
};

/* sample funtion */
export const showPriceValueSub = function (price) {
  price = price !== "" ? parseFloat(price) : 0.0;
  var priceTxt = (
    <>
      <sup>S$</sup>
      {price.toFixed(2)}
    </>
  );
  return priceTxt;
};

/* int to str funtion */
export const cnvrtStr = function (d) {
  return d < 10 ? "0" + d.toString() : d.toString();
};

/* sample funtion */
export const getGstValue = function (gst, subtotal, format) {
  gst = parseFloat(gst);
  subtotal = parseFloat(subtotal);
  var gstResult = 0;

  if (gst > 0) {
    gstResult = (gst / 100) * subtotal;
  }

  if (format === "format") {
    return gstResult.toFixed(2);
  } else {
    return gstResult;
  }
};

/* GST Reverse Calculation funtion */
export const getReverseGST = function (total, taxprecentage) {
  if (parseFloat(total) > 0 && parseFloat(taxprecentage) > 0) {
    taxprecentage = parseFloat(taxprecentage);
    var vatDivisor = 1 + taxprecentage / 100;
    var gstpercentage = taxprecentage / 100;
    var productvalue = total / vatDivisor;
    var gst = productvalue * gstpercentage;
    return parseFloat(gst);
  } else {
    return 0;
  }
};

/* time conversion  */
export const timeToConv12 = function (time24) {
  var ts = time24;
  if (ts !== "" && typeof ts !== "undefined") {
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h;
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
  }
  return ts;
};

/* Date conversion  */
export const getOrderDateTime = function (dateTxt, TatTxt) {
  var dateStr = new Date();
  var TatTxtVl =
    TatTxt !== "" && typeof TatTxt !== "undefined" ? parseInt(TatTxt) : 0;
  var deliveryTime =
    typeof cookie.load("deliveryTime") === "undefined"
      ? ""
      : cookie.load("deliveryTime");
  if (dateTxt !== "" && typeof dateTxt !== "undefined" && deliveryTime !== "") {
    dateTxt = dateTxt.replace(/\"/g, "");
    var dateTxtArr = dateTxt.split("T");
    var selectTmArr = deliveryTime.split(":");
    var seletedDate = new Date(dateTxtArr[0]);
    seletedDate.setHours(parseInt(selectTmArr[0]));
    seletedDate.setMinutes(parseInt(selectTmArr[1]));
    seletedDate.setSeconds(0);
    dateStr = seletedDate;
  } else {
    var CurrentDate = new Date();
    CurrentDate.setMinutes(CurrentDate.getMinutes() + TatTxtVl);
    dateStr = CurrentDate;
  }

  return dateStr;
};

/* Date conversion  */
export const dateConvFun = function (dateTxt, type) {
  var dateStr = dateTxt;
  if (dateStr !== "" && typeof dateStr !== "undefined") {
    var newDateTxtone = dateTxt.replace(/-/g, "/");
    var todayTime = new Date(newDateTxtone);
    var month = todayTime.getMonth() + 1;
    month = month > 9 ? month : "0" + month;
    var day = todayTime.getDate();
    day = day > 9 ? day : "0" + day;
    var year = todayTime.getFullYear();

    if (type === 1) {
      dateStr = day + "/" + month + "/" + year;
    } else if (type === 2) {
      dateStr = day + "-" + month + "-" + year;
    }
  }

  return dateStr;
};

/* Date conversion  */
export const getTimeFrmDate = function (timeTxt, type) {
  var timeStr = timeTxt;
  if (timeStr !== "" && typeof timeStr !== "undefined") {
    var newtimeStr = timeStr.replace(/-/g, "/");
    var todayTime = new Date(newtimeStr);
    var hours = todayTime.getHours();
    var minut = todayTime.getMinutes();

    hours = parseInt(hours) < 10 ? "0" + hours : hours;
    minut = parseInt(minut) < 10 ? "0" + minut : minut;

    if (type === 1) {
      timeStr = hours + " : " + minut;
    } else if (type === 2) {
      timeStr = hours + ":" + minut;
      timeStr = timeToConv12(timeStr);
    }
  }

  return timeStr;
};

/* Date conversion  */
export const getCurrentDateTm = function () {
  var dateTimeStr = "";
  var todayTime = new Date();

  var month = todayTime.getMonth() + 1;
  month = month > 9 ? month : "0" + month;
  var day = todayTime.getDate();
  day = day > 9 ? day : "0" + day;
  var year = todayTime.getFullYear();

  var hours = todayTime.getHours();
  var minut = todayTime.getMinutes();
  var second = todayTime.getSeconds();

  hours = parseInt(hours) < 10 ? "0" + hours : hours;
  minut = parseInt(minut) < 10 ? "0" + minut : minut;
  second = parseInt(minut) < 10 ? "0" + second : second;

  dateTimeStr =
    year + "-" + month + "-" + day + " " + hours + ":" + minut + ":" + second;

  return dateTimeStr;
};

/* sample funtion */
export const validateEmailFun = function (mailIdVal) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mailIdVal)) {
    return true;
  }

  return false;
};

/* sample funtion */
export const resetCrtStyle = function () {
  $(".cart_body").find(".cart_row").removeAttr("style");
};

/* sample funtion */
export const getAliasName = function (alias, productName) {
  return alias !== "" ? alias : productName;
};

/* Uc first funtion */
export const jsUcfirstFun = function (string) {
  if (string !== "") {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return "";
  }
};

/* surcharge splitup */
export const getSurchargesplitup = function (surcharge, surlog) {
  let sur_deliverysetup_fee = 0;
  let sur_ear_deliverysetup_fee = 0;
  let sur_lat_teardown_fee = 0;

  let sur_deliverysetup_fee_name = "";
  let sur_ear_deliverysetup_fee_name = "";

  if (surcharge > 0) {
    sur_deliverysetup_fee = parseFloat(surlog.OrderPax.charge);
    sur_deliverysetup_fee += parseFloat(surlog.OrderAmount.charge);
    sur_deliverysetup_fee += parseFloat(surlog.Surcharge.charge);

    /*Surchare based on date,time and day settings*/
    let sur_ear_deliverysetup_fee_time = 0;

    if (surlog.Surcharge.conditions !== undefined) {
      let basetimeArr = surlog.Surcharge.conditions.filter(
        (condobj) => condobj.surchage_based_on === "Time"
      );

      basetimeArr.forEach((item, i) => {
        sur_ear_deliverysetup_fee_time += parseFloat(item.price_value);

        sur_ear_deliverysetup_fee_name =
          "(" + item.subchr_from_time + " - " + item.subchr_to_time + ")";
      });

      if (basetimeArr.length != 1) {
        sur_ear_deliverysetup_fee_name = "";
      }

      sur_deliverysetup_fee =
        sur_deliverysetup_fee - sur_ear_deliverysetup_fee_time;
      sur_ear_deliverysetup_fee = sur_ear_deliverysetup_fee_time;
    }

    sur_lat_teardown_fee = surlog.After9pm.charge;
  }

  return {
    sur_deliverysetup_fee: sur_deliverysetup_fee.toFixed(2),
    sur_ear_deliverysetup_fee: sur_ear_deliverysetup_fee.toFixed(2),
    sur_lat_teardown_fee: sur_lat_teardown_fee.toFixed(2),
    sur_deliverysetup_fee_name: sur_deliverysetup_fee_name,
    sur_ear_deliverysetup_fee_name: sur_ear_deliverysetup_fee_name,
  };
};

/*get corporate value*/
export const getCorporateWalletValue = function () {
  var usedWalletAmount =
    typeof cookie.load("corporate_customer_wallet_pay_amount") === "undefined"
      ? ""
      : parseFloat(cookie.load("corporate_customer_wallet_pay_amount"));

  var corporate_wallet_pay = "";
  if (usedWalletAmount > 0) {
    corporate_wallet_pay = "Yes";
  } else {
    corporate_wallet_pay = "No";
  }

  var resultArr = [];
  resultArr["corporate_wallet_pay"] = corporate_wallet_pay;
  resultArr["usedWalletAmount"] = usedWalletAmount;
  console.log(resultArr, "usedWalletAmount");
  return resultArr;
};

export const showPoint_total = function (price) {
  price = parseFloat(price);
  if (price !== "" && price !== null) {
    return price.toFixed(0).replace(/./g, function (c, i, a) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "" + c : c;
    });
  }
};
