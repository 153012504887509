/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import moment from "moment";
import {
  stripslashes,
  getCalculatedAmount,
  showLoader,
  hideLoader,
  getOrderDateTime,
  getPromoCkValue,
  removePromoCkValue,
  resetCrtStyle,
  showAlert,
  showPrices,
  getCorporateWalletValue,
  getCCPromoValue,
} from "../Helpers/SettingHelper";
import {
  deliveryId,
  CountryTxt,
  currencySymbol,
  pickupId,
  mediaUrl,
} from "../Helpers/Config";
import productImg from "../../common/images/noimg-400x400.jpg";
import shoppingBag from "../../common/images/shopping-cart-white.svg";

import {
  GET_CART_DETAIL,
  UPDATE_CART_DETAIL,
  DELETE_CART_DETAIL,
  DESTROY_CART_DETAIL,
  GET_MULTI_DELIVERY_LOCATION,
} from "../../actions";
import { pick } from "underscore";
var Parser = require("html-react-parser");

class Cartsidebar extends Component {
  constructor(props) {
    super(props);
    var unitNoOne =
      typeof cookie.load("unitNoOne") === "undefined"
        ? ""
        : cookie.load("unitNoOne");
    var unitNoTwo =
      typeof cookie.load("unitNoTwo") === "undefined"
        ? ""
        : cookie.load("unitNoTwo");

    var delivery_address_type = "";
    var avilablityId =
      cookie.load("defaultAvilablityId") != "" &&
      cookie.load("defaultAvilablityId") != undefined
        ? cookie.load("defaultAvilablityId")
        : "";
    if (avilablityId === deliveryId) {
      delivery_address_type =
        cookie.load("delivery_address_type") !== "" &&
        typeof cookie.load("delivery_address_type") !== undefined &&
        typeof cookie.load("delivery_address_type") !== "undefined"
          ? cookie.load("delivery_address_type")
          : "";
    }
    var getWalletAmount = getCorporateWalletValue();
    var getCCPromoCkVal = getCCPromoValue();
    this.state = {
      updated: 1,
      delivery_address_type: delivery_address_type,
      cartlistdetail: [],
      overAllcart: [],
      cartItems: [],
      cartDetails: [],
      cartStatus: "",
      settings: [],
      cartTotalItmCount: 0,
      cartTotalAmount: 0,
      updateCartResponse: [],
      startMsg: 0,
      unitnumber1: unitNoOne,
      unitnumber2: unitNoTwo,
      corporate_customer_wallet_input: getWalletAmount["usedWalletAmount"],
      corporate_customer_wallet_pay_amount: getWalletAmount["usedWalletAmount"],
      corporate_wallet_pay: getWalletAmount["corporate_wallet_pay"],

      cpcustomer_coupon_applied: getCCPromoCkVal["promotionApplied"],
      cpcustomer_coupon_amount: getCCPromoCkVal["promotionAmount"],
      cpcustomer_promo_code: getCCPromoCkVal["ccpromoCodeVal"],
      cpcustomer_promotion_delivery_charge_applied:
        getCCPromoCkVal["promoIsDelivery"],
    };
  }

  componentWillMount() {
    this.props.getCartDetail();
    if (this.state.delivery_address_type) {
      this.props.getMultiDeliveryLocation();
    }
  }

  componentDidMount() {
    $(window).on("resize", this.handleResize);
  }
  componentWillUnmount() {
    $(window).off("resize", this.handleResize);
  }

  componentWillReceiveProps(headerProps) {
    if (headerProps.cartTriggerFlg === "yes") {
      headerProps.prpSateValChange("cartflg", "no");
      this.props.getCartDetail();
      resetCrtStyle();
    }
    hideLoader("product-details", "class");
    hideLoader("cart_body", "class");

    if (this.state.updateCartResponse !== headerProps.updateCartResponse) {
      if (Object.keys(headerProps.updateCartResponse).length > 0) {
        this.setState(
          { updateCartResponse: headerProps.updateCartResponse },
          function () {
            var Response = headerProps.updateCartResponse;
            if (Object.keys(Response).length > 0) {
              if (Response[0].status === "error") {
                if (this.state.startMsg === 1) {
                  this.handleShowAlertFunct("Error", Response[0].message);
                  this.setState({ startMsg: 0 });
                }
              }
            }
          }
        );
      }
    }
  }

  handleResize = () => {
    this.setState({ updated: -this.state.updated });
  };

  opencart() {
    if (this.props.location.pathname !== "/checkout") {
      $("body").css("overflow", "hidden auto;");
      if ($("#totalitmcount").val() > 0) {
        $("body").addClass("cart-items-open");
        $(this).toggleClass("active");
        $(".hcart_dropdown").toggleClass("open");
      }
    }
  }

  removePromoFun(event) {
    event.preventDefault();
    removePromoCkValue();
    this.props.getCartDetail();
  }

  handleShowAlertFunct(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  closeCartlist() {
    $("body").removeClass("cart-items-open");
    $(".hcartdd_trigger").removeClass("active");
    $(".hcart_dropdown").removeClass("open");
  }
  changeAddrrFun(event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: "#warning-popup",
      },
      type: "inline",
    });
    return false;
  }

  handleAddrChange(event) {
    if (event.target.name === "unit_no1") {
      cookie.save("unitNoOne", event.target.value, { path: "/" });
      this.setState({ unitnumber1: event.target.value });
    } else if (event.target.name === "unit_no2") {
      cookie.save("unitNoTwo", event.target.value, { path: "/" });
      this.setState({ unitnumber2: event.target.value });
    }
  }
  cartDetailsList() {
    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }
      var zoneDetails = this.props.zonedetails;
      var ccPromoTionArr = Array();
      ccPromoTionArr["promotionApplied"] = this.state.cpcustomer_coupon_applied;
      ccPromoTionArr["promotionAmount"] = this.state.cpcustomer_coupon_amount;
      ccPromoTionArr["promoIsDelivery"] =
        this.state.cpcustomer_promotion_delivery_charge_applied;

      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        cartItemsArr,
        promoTionArr,
        this.props.multideliverylocation,
        this.state.corporate_customer_wallet_pay_amount,
        ccPromoTionArr
      );

      var orderDateTime =
        typeof cookie.load("orderDateTime") === "undefined"
          ? ""
          : cookie.load("orderDateTime");
      var orderTAT =
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT");
      var orderDateTmTxt = getOrderDateTime(orderDateTime, orderTAT);

      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      return (
        <div className="hcart_dropdown">
          <div className="hcart_tt">
            <div className="row-replace">
              <div className="col-sm-cls text-center cart-hdr-top">
                <div
                  id="cart-close-span"
                  onClick={this.closeCartlist.bind(this)}
                ></div>
                <h3>Your Cart Details</h3>
                <div className="inner-cart">
                  <a href={void 0} className="">
                    <i></i>
                    <span className="hcart_round">
                      {this.props.cartTotalItmCount}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="hcart_scrollarea">
            <div className="cart_table">
              <div className="cart_body">
                {cookie.load("delivery_address_type") !== "M" && (
                  <div>
                    {
                      /* cookie.load("defaultAvilablityId") === deliveryId ? (
                      <div className="delivery-cart-div">
                        <div className="cart_row cart-header-first">
                          <div className="row-replace">
                            <div className="col-sm-cls cart_left text-left">
                              <h4>Order Handled By</h4>
                              <p>{cookie.load("orderOutletName")}</p>
                              <p>{cookie.load("orderHandledByText")}</p>
                              {cookie.load("orderOutletPhoneNumber") !== "" &&
                                typeof cookie.load("orderOutletPhoneNumber") !==
                                  undefined &&
                                typeof cookie.load("orderOutletPhoneNumber") !==
                                  "undefined" && (
                                  <p>
                                    Tel: +65-
                                    {cookie.load("orderOutletPhoneNumber")}
                                  </p>
                                )}
                              <p>{cookie.load("orderOutletEmail")}</p>
                            </div>
                            <div className="col-sm-cls cart_right text-right">
                              <h4>Delivery Location</h4>
                              <p>{cookie.load("orderDeliveryAddress")}</p>
                              <p>
                                {CountryTxt} {cookie.load("orderPostalCode")}
                              </p>
                              <div className="hcart_add_innr_loca">
                                <p>#</p>
                                <div className="input_field">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.unitnumber1}
                                    name="unit_no1"
                                    onChange={this.handleAddrChange.bind(this)}
                                  />
                                </div>
                                <div className="input_field">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.unitnumber2}
                                    name="unit_no2"
                                    onChange={this.handleAddrChange.bind(this)}
                                  />
                                </div>
                              </div>
                              <p>
                                <span>
                                  <a
                                    className="cart-loc-change"
                                    href="/"
                                    onClick={this.changeAddrrFun.bind(this)}
                                  >
                                    Change Delivery Location
                                  </a>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : ( */
                      cookie.load("defaultAvilablityId") === pickupId && (
                        <div className="pickup-cart-div">
                          <div className="cart_row cart-header-first">
                            <div className="row-replace">
                              <div className="col-xs-12 cart_left text-center">
                                <h4>Pickup Location</h4>
                                <p>{cookie.load("orderOutletName")}</p>
                                <p>{cookie.load("orderHandledByText")}</p>
                                {cookie.load("orderOutletPhoneNumber") !== "" &&
                                  typeof cookie.load(
                                    "orderOutletPhoneNumber"
                                  ) !== undefined &&
                                  typeof cookie.load(
                                    "orderOutletPhoneNumber"
                                  ) !== "undefined" && (
                                    <p>
                                      Tel: +65-
                                      {cookie.load("orderOutletPhoneNumber")}
                                    </p>
                                  )}
                                <p>{cookie.load("orderOutletEmail")}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                      /* ) */
                    }

                    {cookie.load("defaultAvilablityId") === pickupId && (
                      <div className="cart_row cart-header-second">
                        <div className="row-replace">
                          <div className="col-sm-cls text-left">
                            <h5>
                              {cookie.load("defaultAvilablityId") === deliveryId
                                ? "Delivery"
                                : "Pickup"}{" "}
                              Date
                            </h5>
                            <h3>
                              {moment(orderDateTmTxt).format("DD/MM/YYYY")}
                            </h3>
                          </div>
                          <div className="col-sm-cls text-right">
                            <h5>
                              {cookie.load("defaultAvilablityId") === deliveryId
                                ? "Delivery"
                                : "Pickup"}{" "}
                              Time
                            </h5>
                            {cookie.load("slotType") === "2" ? (
                              <h3>{cookie.load("orderSlotTxt")}</h3>
                            ) : (
                              <h3>
                                {moment(orderDateTmTxt).format("hh:mm A")}
                              </h3>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="hcart_tt">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <h3>Your Items</h3>
                    </div>
                    <div className="col-sm-cls text-right">
                      <a
                        href="/"
                        onClick={this.clearCartItm.bind(this)}
                        className="hclear_cart"
                        title="CLEAR CART"
                      >
                        CLEAR CART
                      </a>
                    </div>
                  </div>
                </div>

                {this.cartItemList()}
              </div>

              <div className="cart_footer">
                <div className="cart_row">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">SUBTOTAL</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>
                        {showPrices(calculatedAmount["cartSubTotalAmount"])}
                      </span>
                    </div>
                  </div>
                </div>
                {parseFloat(calculatedAmount["deliveryCharge"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">Delivery</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              calculatedAmount["deliveryCharge"]
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["hourlyDelivery"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          Hourly Specific Delivery
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              calculatedAmount["hourlyDelivery"]
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["additionalDelivery"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">Additional Delivery</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              calculatedAmount["additionalDelivery"]
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["promotionAmount"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">Promo Code</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              calculatedAmount["promotionAmount"]
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                    <a
                      href="/"
                      onClick={this.removePromoFun.bind(this)}
                      className="cart_remove"
                    ></a>
                  </div>
                )}
                {parseFloat(this.state.corporate_customer_wallet_pay_amount) >
                  0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">Corporate Discount (-)</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          (
                          {showPrices(
                            parseFloat(
                              this.state.corporate_customer_wallet_pay_amount
                            ).toFixed(2)
                          )}
                          )
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["orderGstAmount"]) > 0 &&
                  calculatedAmount["inclusiveGST"] !== "Yes" && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {" "}
                            GST ({calculatedAmount["orderDisplayGst"]} %)
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(
                              parseFloat(
                                calculatedAmount["orderGstAmount"]
                              ).toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                {parseFloat(calculatedAmount["orderGstAmount"]) > 0 &&
                  calculatedAmount["inclusiveGST"] === "Yes" && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {" "}
                            GST Inclusive ({calculatedAmount["orderDisplayGst"]}
                            %)
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            {showPrices(
                              parseFloat(
                                calculatedAmount["orderGstAmount"]
                              ).toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                {parseFloat(calculatedAmount["voucherDiscountAmount"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          Voucher Disocunt Amount
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              calculatedAmount["voucherDiscountAmount"]
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                <div className="cart_row grant-total-cls">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">Total</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>
                        {showPrices(calculatedAmount["grandTotalAmount"], "Y")}
                      </span>
                    </div>
                  </div>
                </div>
                {/* cookie.load("defaultAvilablityId") === deliveryId &&
                  this.loadMinimumPercentage() */}
                {cookie.load("defaultAvilablityId") === deliveryId &&
                  this.loadDeliveryPercentage()}

                {parseFloat(cartDetailsArr.cart_special_discount) > 0 && (
                  <div className="member-discount-total">
                    * {cartDetailsArr.cart_special_discount_type}
                    {showPrices(cartDetailsArr.cart_special_discount)} Applied
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="cart_action"
            key={this.state.updated}
            style={
              isMobile
                ? {
                    bottom: window.innerHeight !== $(window).height() ? 0 : 100,
                  }
                : {}
            }
          >
            <div className="cartaction_bottom">
              <Link
                to="/checkout"
                className="btn btn_pink cartaction_checkout"
                title="Checkout Now"
              >
                Checkout
              </Link>
            </div>
          </div>
        </div>
      );
    } else {
      $("body").removeClass("cart-items-open");
      $(".hcartdd_trigger").removeClass("active");

      return "";
    }
  }

  /* this  function used to load delivery percentage */
  loadDeliveryPercentage() {
    var freeDeliveryAmnt = 0;
    var DeliveryAmnt = 0;
    var remainAmnt = 0;
    var delPercentage = 0;

    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }

      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        cartItemsArr,
        promoTionArr,
        this.props.multideliverylocation,
        this.state.corporate_customer_wallet_pay_amount
      );

      freeDeliveryAmnt = parseFloat(calculatedAmount["freeDeliveryAmnt"]);
      DeliveryAmnt = parseFloat(calculatedAmount["deliveryCharge"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / freeDeliveryAmnt;
      percentage = Math.round(percentage);
      delPercentage = percentage;
      remainAmnt = parseFloat(freeDeliveryAmnt - subTotal).toFixed(2);
    }

    if (DeliveryAmnt > 0 && freeDeliveryAmnt > 0 && remainAmnt > 0) {
      return (
        <div className="cart_row progress_bar_div">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar"
                style={{ width: delPercentage + "%" }}
              />
            </div>
            <p className="help-block">
              {showPrices(remainAmnt)} more to FREE delivery!
            </p>
          </div>
        </div>
      );
    }
  }

  /* this  function used to load delivery percentage */
  loadMinimumPercentage() {
    var minOrderAmnt = 0;
    var remainAmnt = 0;
    var delPercentage = 0;

    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }

      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        cartItemsArr,
        promoTionArr,
        this.props.multideliverylocation,
        this.state.corporate_customer_wallet_pay_amount
      );

      minOrderAmnt = parseFloat(calculatedAmount["minOrderAmnt"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / minOrderAmnt;
      percentage = Math.round(percentage);
      delPercentage = percentage;
      remainAmnt = parseFloat(minOrderAmnt - subTotal).toFixed(2);
    }

    if (minOrderAmnt > 0 && remainAmnt > 0) {
      return (
        <div className="cart_row progress_bar_div progress_bar_min_amt">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar"
                style={{ width: delPercentage + "%" }}
              />
            </div>
            <p className="help-block">
              {showPrices(remainAmnt)} More to Min Order Amount
            </p>
          </div>
        </div>
      );
    }
  }

  cartItemList() {
    var cartItemsArr = this.props.cartItems;
    if (Object.keys(cartItemsArr).length > 0) {
      return cartItemsArr.map((product, index) => (
        <div
          className={
            product.cart_item_product_type === "5"
              ? parseFloat(product.cart_item_total_price) > 0
                ? "cart_row product-details"
                : "cart_row product-details redeemd-product-zero"
              : "cart_row product-details"
          }
          id={"rowcartid-" + product.cart_item_id}
          key={index}
        >
          <div className="row-replace">
            <div className="col-sm-cls cart_left">
              <div className="cart_img">
                {product.cart_item_gift_sub_category_id !== "" &&
                product.cart_item_gift_sub_category_image !== "" ? (
                  <img
                    src={
                      mediaUrl +
                      "giftsubcategory/" +
                      product.cart_item_gift_sub_category_image
                    }
                    alt=""
                  />
                ) : product.cart_item_product_image !== "" ? (
                  <img src={product.cart_item_product_image} alt="" />
                ) : (
                  <img src={productImg} alt="" />
                )}
              </div>
              <div className="cart_info text-left">
                <h4>{stripslashes(product.cart_item_product_name)}</h4>
                {product.cart_item_voucher_id !== "" &&
                  product.cart_item_voucher_id != null && (
                    <span className="voucher-discount-applied">
                      Discount Applied
                    </span>
                  )}
                {product.cart_item_gift_sub_category_id !== "" &&
                  product.cart_item_gift_sub_category_name !== "" && (
                    <div className="cart_extrainfo">
                      <p>
                        <b>Gift Card</b> :{" "}
                        {product.cart_item_gift_sub_category_name}
                      </p>
                    </div>
                  )}
                {product.cart_item_product_type === "5" &&
                  product.cart_item_product_voucher_gift_email !== "" &&
                  product.cart_item_product_voucher_gift_email !==
                    cookie.load("UserEmail") &&
                  product.cart_item_product_voucher_gift_mobile !== "" &&
                  (product.cart_voucher_order_item_id === "" ||
                    product.cart_voucher_order_item_id === null) && (
                    <div className="cart_extrainfo">
                      <p>
                        <b>Gift Details</b>
                      </p>
                      {product.cart_item_product_voucher_gift_name !== "" &&
                        product.cart_item_product_voucher_gift_name !==
                          null && (
                          <p>
                            Name: {product.cart_item_product_voucher_gift_name}
                          </p>
                        )}
                      {product.cart_item_product_voucher_gift_mobile !== "" &&
                        product.cart_item_product_voucher_gift_mobile !==
                          null && (
                          <p>
                            Mobile:{" "}
                            {product.cart_item_product_voucher_gift_mobile}
                          </p>
                        )}
                      {product.cart_item_product_voucher_gift_message !== "" &&
                        product.cart_item_product_voucher_gift_message !==
                          null && (
                          <p>
                            Email:{" "}
                            {product.cart_item_product_voucher_gift_email}
                          </p>
                        )}
                      {product.cart_item_product_voucher_gift_message !== "" &&
                        product.cart_item_product_voucher_gift_message !==
                          null && (
                          <p>
                            Message:{" "}
                            {product.cart_item_product_voucher_gift_message}
                          </p>
                        )}
                    </div>
                  )}
                {this.loadModifierItems(
                  product.cart_item_type,
                  product.modifiers,
                  product.set_menu_component
                )}

                {product.cart_item_special_notes !== "" &&
                  product.cart_item_special_notes !== null && (
                    <p className="help-block">
                      Message On Cake:{" "}
                      {stripslashes(product.cart_item_special_notes)}
                    </p>
                  )}
                {product.cart_item_card_notes !== "" &&
                  product.cart_item_card_notes !== null && (
                    <p className="help-block">
                      Message On Gift Card:{" "}
                      {stripslashes(product.cart_item_card_notes)}
                    </p>
                  )}

                {parseFloat(product.cart_item_promotion_discount) > 0 && (
                  <span className="member-discount-desc">
                    {showPrices(product.cart_item_promotion_discount)}{" "}
                    {product.cart_item_promotion_type} discount Applied
                  </span>
                )}
              </div>
            </div>
            <div className="col-sm-cls cart_right text-right">
              <div className="cart_price">
                <p>{showPrices(product.cart_item_total_price)}</p>
              </div>

              {product.cart_item_voucher_product_free != 1 ? (
                <div className="qty_bx">
                  <span
                    className="qty_minus"
                    onClick={this.updateCartQty.bind(this, product, "decr")}
                  >
                    -
                  </span>
                  <input type="text" value={product.cart_item_qty} readOnly />
                  <span
                    className="qty_plus"
                    onClick={this.updateCartQty.bind(this, product, "incr")}
                  >
                    +
                  </span>
                </div>
              ) : (
                <div className="qty_bx free_product">
                  <span className="qty_minus"></span>
                  <input type="text" value={product.cart_item_qty} readOnly />
                  <span className="qty_plus"></span>
                </div>
              )}
            </div>
          </div>
          <a
            href="/"
            onClick={this.deleteCartItm.bind(this, product)}
            className="cart_remove"
          ></a>
        </div>
      ));
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice =
          modVlPrice > 0
            ? " <b>(+" + currencySymbol + modVlPrice + ")</b>"
            : "";
        temp_html +=
          "<p><b>" + modName + ": </b>" + modval + newModVlPrice + "</b></p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        if (combo[i]["product_details"][0]["modifiers"].length) {
          html +=
            "<p><b>" +
            comboName +
            ":</b> </p><p>" +
            comboVal +
            "  " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        } else {
          html +=
            "<p><b>" +
            comboName +
            ":</b> </p><p>" +
            comboVal +
            " " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        }
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_menu_component_product_qty"]) > 0) {
          var comboPro = combos[r]["cart_menu_component_product_name"];
          var comboQty = combos[r]["cart_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_menu_component_product_price"];
          var newPrice =
            comboPrice > 0
              ? " <b>(+" + currencySymbol + comboPrice + ")</b>"
              : "";
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        html +=
          "<p><b>" + modName + ": </b> " + modval + newModValPrice + "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  updateCartQty(itemArr, type) {
    var productId = itemArr.cart_item_product_id;
    var cartItemId = itemArr.cart_item_id;
    var cartQty = itemArr.cart_item_qty;
    var orderVoucherId = itemArr.cart_voucher_order_item_id;
    var totalItmCount = this.props.cartTotalItmCount;

    var multi_Delivery_Locations = this.props.multideliverylocation;
    var incVal = 1;
    showLoader("rowcartid-" + cartItemId, "Idtext");

    if (type === "decr") {
      cartQty = parseInt(cartQty) - incVal;
      if (parseInt(totalItmCount) === 0) {
      } else if (parseInt(cartQty) === 0) {
        this.props.deleteCartDetail(cartItemId);
      } else {
        this.props.updateCartDetail(
          productId,
          cartItemId,
          cartQty,
          orderVoucherId
        );
      }
    } else {
      cartQty = parseInt(cartQty) + parseInt(incVal);
      this.props.updateCartDetail(
        productId,
        cartItemId,
        cartQty,
        orderVoucherId
      );
    }
    this.setState({ startMsg: 1 });
    removePromoCkValue();
  }

  deleteCartItm(itemArr, event) {
    event.preventDefault();
    var cartItemId = itemArr.cart_item_id;
    showLoader("rowcartid-" + cartItemId, "Idtext");
    this.props.deleteCartDetail(cartItemId);
    removePromoCkValue();
  }

  clearCartItm(event) {
    event.preventDefault();
    showLoader("cart_body", "class");
    this.props.destroyCartDetail();
    removePromoCkValue();
  }

  render() {
    var currenturl = window.location.href;
    var substring1 = "checkout";
    return (
      <>
        <a
          href={void 0}
          className={
            currenturl.includes(substring1) !== true
              ? "hcartdd_trigger"
              : "hcartdd_trigger_dis"
          }
          onClick={this.opencart.bind(this)}
        >
          <img src={shoppingBag} alt="Cart" />
          <span className="hcart_round">{this.props.cartTotalItmCount}</span>
          <input
            type="hidden"
            id="totalitmcount"
            value={this.props.cartTotalItmCount}
          />
        </a>

        {currenturl.includes(substring1) !== true ? this.cartDetailsList() : ""}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var updateCartResponse = Array();
  var cartTotalItmCount = 0;
  var cartStatus = "";

  if (Object.keys(state.updatecartdetail).length > 0) {
    if (state.updatecartdetail[0].status === "error") {
      updateCartResponse = state.updatecartdetail;
    }
  }

  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartDetails = resultSetArr.cart_details;
      cartItems = resultSetArr.cart_items;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
      cartStatus = "success";
    } else {
      cartStatus = "failure";
    }
  }
  var multideliverylocation = Array();
  if (Object.keys(state.multideliverylocation).length > 0) {
    var mresultSetArr = !("result_set" in state.multideliverylocation[0])
      ? Array()
      : state.multideliverylocation[0].result_set;
    if (
      state.multideliverylocation[0].status === "ok" &&
      Object.keys(mresultSetArr).length > 0
    ) {
      multideliverylocation = mresultSetArr;
    }
  }
  return {
    overAllcart: overAllcart,
    cartDetails: cartDetails,
    cartItems: cartItems,
    cartTotalItmCount: cartTotalItmCount,
    cartStatus: cartStatus,
    updateCartResponse: updateCartResponse,
    multideliverylocation: multideliverylocation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },
    updateCartDetail: (productId, cartItemId, cartQty, orderVoucherId) => {
      dispatch({
        type: UPDATE_CART_DETAIL,
        productId,
        cartItemId,
        cartQty,
        orderVoucherId,
      });
    },
    deleteCartDetail: (cartItemId) => {
      dispatch({ type: DELETE_CART_DETAIL, cartItemId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getMultiDeliveryLocation: () => {
      dispatch({ type: GET_MULTI_DELIVERY_LOCATION });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Cartsidebar);
