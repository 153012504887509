/* eslint-disable */
import React, { Component } from "react";
import {
  appId,
  apiUrlV2,
  defaultoutlet,
  deliveryId,
} from "../../Helpers/Config";
import { stripslashes } from "../../Helpers/SettingHelper";
import { Link } from "react-router-dom";
import axios from "axios";
import _ from "underscore";
import cookie from "react-cookies";
// import { type } from "jquery";
export default class MenuNavigationmob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuList: [],
      subCategory: [],
    };
  }

  componentDidMount() {
    var availabilityId =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : deliveryId;
    var orderOutletId =
      cookie.load("orderOutletId") === undefined ||
      cookie.load("orderOutletId") == ""
        ? defaultoutlet
        : cookie.load("orderOutletId");
    axios
      .get(
        apiUrlV2 +
          "products/getMenuNavigation?app_id=" +
          appId +
          "&availability=" +
          availabilityId +
          "&outletId=" +
          orderOutletId
      )
      .then((res) => {
        if (res.data.status == "ok") {
          this.setState({ menuList: res.data.result_set });
          if (res.data.result_set !== "" && res.data.result_set !== null) {
            cookie.save(
              "firstNavigation",
              res.data.result_set[0].pro_cate_slug,
              {
                path: "/",
              }
            );
          }
        }
      });
  }
  endSubMenuItems(submenu, categorySlug) {
    return submenu.map((loaddata, index) => (
      <li key={index}>
        {" "}
        <Link
          to={
            "/menu/category/" + categorySlug + "/" + loaddata.pro_subcate_slug
          }
          title={stripslashes(loaddata.pro_subcate_name)}
          onClick={(e) => {
            $(this).toggleClass("active");
            $(".hcategory_menu").toggleClass("open");
          }}
        >
          {stripslashes(loaddata.pro_subcate_name)}{" "}
        </Link>
      </li>
    ));
  }

  /* submenu listing */
  showSubMenu(isSubMenu, objects, categorySlug) {
    if (isSubMenu == "Yes") {
      var chunkValues = _.chunk(objects.sub_result_set, 4);
      const listingItems = chunkValues.map((submenu, index) => {
        return (
          <ul className="hcategory_submenu" key={index}>
            {this.endSubMenuItems(submenu, categorySlug)}{" "}
          </ul>
        );
      });
      return listingItems;
    }
  }

  goCategoryProducts(selectedNavigation, category) {
    this.setState({ selectedNavigation: selectedNavigation });
    $("html, body").animate(
      {
        scrollTop: $("#pro_cat_" + selectedNavigation).offset().top - 140,
      },
      1000
    );

    $(".hcategory_selected_text .mobile_category_name").html(category);
  }

  goSubCategoryProducts(selectedNavigation, category) {
    this.setState({ selectedNavigation: selectedNavigation });
    if ($("#subcat_" + selectedNavigation).length > 0) {
      $("html, body").animate(
        {
          scrollTop: $("#subcat_" + selectedNavigation).offset().top - 140,
        },
        1000
      );

      $(".hcategory_selected_text .mobile_category_name").html(category);
    }
  }

  render() {
    var currenturl = window.location.href;
    var mainMenu = "";
    if (this.state.menuList !== "" && this.state.menuList !== null) {
      mainMenu = this.state.menuList.map((loaddata, index) => {
        if (loaddata.menu_type == "main") {
          return (
            <li
              key={index}
              className={
                this.state.selectedNavigation === loaddata.pro_cate_slug
                  ? "active"
                  : ""
              }
            >
              {" "}
              <Link
                to={"/menu/category/" + loaddata.pro_cate_slug}
                title={loaddata.menu_custom_title}
                onClick={(e) => {
                  e.preventDefault();
                  this.goCategoryProducts(
                    loaddata.pro_cate_slug,
                    stripslashes(loaddata.menu_custom_title)
                  );
                }}
              >
                {stripslashes(loaddata.menu_custom_title)}{" "}
              </Link>{" "}
              {this.showSubMenu(
                loaddata.is_sub_list,
                loaddata.subcat_list_arr,
                loaddata.pro_cate_slug
              )}{" "}
            </li>
          );
        } else if (loaddata.menu_type == "sub") {
          return (
            <li
              key={index}
              className={
                currenturl.includes(loaddata.pro_cate_slug) ? "active" : ""
              }
            >
              {" "}
              <Link
                to={"/menu/subcategory/" + loaddata.pro_subcate_slug}
                title={stripslashes(loaddata.menu_custom_title)}
              >
                {" "}
                {loaddata.menu_custom_title}{" "}
              </Link>{" "}
            </li>
          );
        }
      });
    }
    return <ul className="hcategory_menulist">{mainMenu} </ul>;
  }
}
