import { SET_MULTI_DELIVERY_LOCATION } from "../actions";

const multideliverylocation = (state = [], action) => {
  switch (action.type) {
    case SET_MULTI_DELIVERY_LOCATION:
      return [...action.value];
    default:
      return state;
  }
};

export default multideliverylocation;
