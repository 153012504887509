/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_ORDER_DETAIL, SET_ORDER_DETAIL } from "../actions";
import { appId, apiUrl, deliveryId } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetOrderDetail = function* () {
  yield takeEvery(GET_ORDER_DETAIL, workerGetOrderDetail);
};

function* workerGetOrderDetail({ orderId, order_primary_id }) {
  try {
    var orderWhere = "";
    if (
      orderId !== "" &&
      typeof orderId !== undefined &&
      typeof orderId !== "undefined"
    ) {
      orderWhere = "&local_order_no=" + orderId;
    }

    if (
      order_primary_id !== "" &&
      typeof order_primary_id !== undefined &&
      typeof order_primary_id !== "undefined"
    ) {
      orderWhere = "&order_primary_id=" + order_primary_id + "&forscan=yes";
    }
    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      orderWhere += "&customer_id=" + cookie.load("UserId");
    }

    const uri = apiUrl + "reports/order_history?app_id=" + appId + orderWhere;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_ORDER_DETAIL, value: resultArr });
  } catch {
    console.log("Get Order Detail Failed");
  }
}
