/* eslint-disable */
import Slider from "react-slick";
import React, { Component } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom"

// import { stripslashes } from "../../Helpers/SettingHelper"
import { GET_BANNER_LIST } from "../../../actions";

// var Parser = require("html-react-parser");

class HomeBanner extends Component {
  constructor(props) {
    super(props);
    this.props.getBannerList();
  }

  render() {
    let bannerArr = this.props.banner;
    let bannerlist = [];
    let bannerimagesource = "";

    if (Object.keys(bannerArr).length > 0) {
      if (bannerArr[0].status === "ok") {
        bannerlist = bannerArr[0].result_set;
        bannerimagesource = bannerArr[0].common.image_source;
      }
    }

    var settingsGallery = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <>
        <div className="home-banner">
          {bannerlist.length > 0 && (
            <Slider {...settingsGallery}>
              {bannerlist.map((banner, index) => {
                return (
                  <div key={index}>
                    <a
                      href={
                        banner.banner_link !== "" && banner.banner_link !== null
                          ? banner.banner_link
                          : void 0
                      }
                    >
                      <img
                        src={bannerimagesource + banner.banner_image}
                        alt="Banner"
                        {...(index !== 0 ? { loading: "lazy" } : {})}
                      />
                      {/* <div className="banner-info">
                        <div className="homebanner-content-inner">{banner.banner_description != "" ? stripslashes(banner.banner_description) : ""}</div>
                      </div> */}
                    </a>
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    banner: state.banner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerList: () => {
      dispatch({ type: GET_BANNER_LIST });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(HomeBanner);
