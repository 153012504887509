/* eslint-disable */
import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import OwlCarousel from "react-owl-carousel2"
import cookie from "react-cookies"
import axios from "axios"
import update from "immutability-helper"
// import FacebookLogin from "react-facebook-login";
import { format } from "date-fns"
import jwt_decode from "jwt-decode"
var dateFormat = require("dateformat")
import {
  projectTitle,
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  pickupId,
  // baseUrl,
  // fbAppId,
  CountryTxt,
  outletUrl,
  mainurl,
  googleAppId,
  mediaUrl,
  defaultoutlet,
} from "../Helpers/Config"

import {
  GET_GLOBAL_SETTINGS,
  GET_ZONE_DETAIL,
  GET_STATIC_BLOCK,
  GET_PICKUP_OUTLETS,
  GET_ALL_OUTLETS,
  DESTROY_CART_DETAIL,
  GET_LOGINDATA,
  GET_FBLOGINDATA,
  GET_GOOGLELOGINDATA,
  GET_FORGET_PASSWORD,
  GET_CHANGEPASSWORD,
  GET_REGISTRATION,
  GET_MENUDATA,
  GET_ALLUSERSECADDRDATA,
} from "../../actions"
import { getReferenceID, showAlert, showLoader, hideLoader, getAliasName, stripslashes, removeOrderDateTime, removePromoCkValue, addressFormat } from "../Helpers/SettingHelper"

import OrderdatetimeSlot from "./OrderdatetimeSlot"
import OrderAdvancedDatetimeSlot from "./OrderAdvancedDatetimeSlot"
import MenuNavigationmob from "./MenuNavigation/MenuNavigationmob"
import { Login, Forgotpassword, Signup, Changepassword } from "../../components/Myaccount/Index"

/* import images */
import mainLogo from "../../common/images/logo.png"
import iconUnhappy from "../../common/images/sad-smiley.png"
import iconWin from "../../common/images/icon-win.svg"
import warningImg from "../../common/images/warning.svg"
import warningplus from "../../common/images/18plus.png"

import deliveryImg from "../../common/images/delivery-bike.svg"
import takeawayImg from "../../common/images/takeaway-bag.svg"

import loupe from "../../common/images/loupe.svg"
import cartLike from "../../common/images/like.png"
import cartLikeActive from "../../common/images/like-active-header.png"

import outlet1 from "../../common/images/loc-phone.png"
import outlet2 from "../../common/images/loc-map.png"
// import outlet3 from "../../common/images/loc-clock.png";
import awenav from "../../common/images/aweful-nav.png"
// import noimgplace from "../../common/images/noimg-800x800.jpg";
import offers from "../../common/images/offers-outline.png"
import cakeout from "../../common/images/cake-outline.png"
import threeline from "../../common/images/three-line.png"

import CartSideBar from "./CartSideBar"
import { Helmet } from "react-helmet"
var qs = require("qs")
var Parser = require("html-react-parser")
const infosetting = {
  items: 1,
  autoplay: true,
  loop: true,
  nav: false,
  margin: 25,
}
class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      seletedAvilablityId: "",
      seletedTempAvilablityId: "",
      errorAvailability: "",
      seletedOutletId: "",
      order_tat_time: 0,
      globalsettings: [],
      secondaryaddresslist: [],
      deliveryOutlets: [],
      deliveryOutletsList: [],
      pickupOutlets: [],
      pickupOutletsList: [],
      delivery_outlet_id: "",
      searchProResult: [],
      selectedProResult: [],
      orderHandled: "",
      orderDeliveryAddress: "",
      nextavail: "",
      logindata: "",
      deliveryInfo: [],
      pickupInfo: [],
      /* For Advanced Slot Start */
      getDateTimeFlg: "",
      seleted_ord_date: "",
      seleted_ord_time: "",
      seleted_ord_slot: "",
      seleted_ord_slotTxt: "",
      seleted_ord_slot_str: "",
      seleted_ord_slot_end: "",
      isAdvanced: "no",
      slotType: "0",
      /* For Advanced Slot End */

      fields: {
        email: "",
        password: "",
      },
      fieldsfgtpassword: {
        email: "",
      },
      fpstatus: "initiating",
      regstatus: "initiating",
      googlelogin: "No",
      fieldssignup: {
        firstname: "",
        email: "",
        password: "",
        rePassword: "",
        mobile: "",
        birthday: "",
        pdpa_consent: "",
        terms: "",
        captchavalue: "",
        completeReg: "N",
        check_ref_code: cookie.load("referCode") !== "" && typeof cookie.load("referCode") !== undefined && typeof cookie.load("referCode") !== "undefined" ? cookie.load("referCode") : "",
      },
      fieldschpassword: {
        oldpassword: "",
        newpassword: "",
        confirmpassword: "",
      },
      menuData: [],
      mobileMenu: [],
      openOutletTime: false,
      fbstatus: "ok",
      staticblacks: [],
      headenews: "",
      orderOutletPhoneNumber: "",
      orderOutletEmail: "",
      deliveryAddressType: "S",
      totalDeliveryLocation: [
        {
          postal_code: "",
          name: "",
          phone_number: "",
          floor_no: "",
          unit_no: "",
          error_postal_code: "",
          error_name: false,
          error_phone_number: false,
          error_floor_no: false,
          error_unit_no: false,
        },
      ],
      totalDeliveryAddress: [
        {
          delivery_address: "",
          zone_id: "",
          zone_error: "",
          outlet_id: "",
        },
      ],
      multideliveryerror: "",
      displayHeaderMenu: "",
      displayHeaderMenuLoading: true,
    }
  }

  loadInfo() {
    if (!this.state.loaded) {
      var currentNew = this
      var currentThis = this
      this.props.getGlobalSettings()
      this.props.getAllOutlets(deliveryId)
      this.props.getSecondaryAddress()

      var availbty = cookie.load("defaultAvilablityId")
      var outltIdTxt = typeof cookie.load("orderOutletId") === "undefined" ? "" : cookie.load("orderOutletId")
      var zoneIdTxt = typeof cookie.load("orderZoneId") === "undefined" ? "" : cookie.load("orderZoneId")

      if (availbty === deliveryId && outltIdTxt !== "" && zoneIdTxt !== "") {
        this.state["delivery_outlet_id"] = outltIdTxt
        this.props.getZoneDetail(outltIdTxt, zoneIdTxt)
      }
      if (window.google && window.google.accounts) {
        window.google.accounts.id.initialize({
          client_id: googleAppId,
          callback: (data) => this.handleCallbackResponse(currentNew, data),
        })
        window.google.accounts.id.renderButton(document.getElementById("signInDiv"), {
          theme: "outline",
          size: "large",
        })
        window.google.accounts.id.renderButton(document.getElementById("signInDiv1"), {
          theme: "outline",
          size: "large",
        })
      }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            currentThis.props.getPickupOutlets(position.coords)
          },
          function (error) {
            currentThis.props.getPickupOutlets()
          }
        )
      } else {
        this.props.getPickupOutlets()
      }
      this.props.getMenuData("header-menu-k189qcywnmzllzvwbmefevo72")
    }
  }

  componentDidMount() {
    //this.loadHeaderMenuNavigation();
    this.props.getStaticBlock()
    var currentNew = this
    var currentThis = this

    if (window.innerWidth > 1080 || this.props.match?.path?.includes("checkout")) {
      this.loadInfo()
    }

    $("body").css("overflow", "initial")

    $(".custom_close").click(function (e) {
      e.preventDefault()
      $(".custom_alertcls, .custom_center_alertcls").hide()
    })
    $("html, body").animate(
      {
        scrollTop: $("body").offset().top,
      },
      100
    )

    $(".test-popup-link").magnificPopup({
      type: "image",
      showCloseBtn: true,
      verticalFit: true,
      callbacks: {
        change: function () {
          this.wrap.addClass("awardpopup")
        },
      },
      // other options
    })

    if (this.props.match.path === "/sign-in") {
      if (!cookie.load("UserId")) {
        $.magnificPopup.open({
          items: {
            src: "#signup-popup",
          },
          type: "inline",
        })
      } else {
        this.props.history.push("/myaccount")
      }
    }
    if ($(".open-popup-link").length > 0) {
      $(".open-popup-link").magnificPopup({
        type: "inline",
        midClick: true,
      })
    }

    if ($(".trigger_login").length > 0) {
      $(".trigger_login").magnificPopup({
        type: "inline",
        midClick: true,
      })
    }

    /* Input lable animation */
    if ($(".input-focus").length > 0) {
      $(".input-focus").focus(function () {
        $(this).parents(".focus-out").addClass("focused")
      })
      $(".input-focus").blur(function () {
        var inputValue = $(this).val()
        if (inputValue == "") {
          $(this).removeClass("filled")
          $(this).parents(".focus-out").removeClass("focused")
        } else {
          $(this).addClass("filled")
        }
      })
    }

    if ($(".hsearch_trigger").length > 0) {
      $(".hsearch_trigger").click(function (e) {
        if (!currentThis.state.searchProResult.length) {
          currentThis.getSearchProductList()
        }
        e.preventDefault()
        e.stopPropagation()
        $(".search_result").hide()
        $(this).toggleClass("active")
        $(".hsearch_sec").toggleClass("open")
        $(".hsearch_sec .form-control").focus()
      })
      $(document).click(function (e) {
        if (!$(e.target).is(".hsearch_trigger, .hsearch_sec, .hsearch_sec * ")) {
          if ($(".hsearch_sec").is(":visible")) {
            $(".hsearch_sec").removeClass("open")
            $(".hsearch_trigger").removeClass("active")
            $(".hsearch_sec .form-control").blur()
          }
        }
      })
    }

    if ($(".hsearch_trigger_mbl").length > 0) {
      $(".hsearch_trigger_mbl").click(function (e) {
        e.preventDefault()
        e.stopPropagation()
        if (!currentThis.state.searchProResult.length) {
          currentThis.getSearchProductList()
        }
        $(".search_result_mbl").hide()
        $(this).toggleClass("active")
        $(".hsearch_sec_mbl").toggleClass("open")
        $(".hsearch_sec_mbl .form-control").focus()
      })
      $(document).click(function (e) {
        if (!$(e.target).is(".hsearch_trigger_mbl, .hsearch_sec_mbl, .hsearch_sec_mbl * ")) {
          if ($(".hsearch_sec_mbl").is(":visible")) {
            $(".hsearch_sec_mbl").removeClass("open")
            $(".hsearch_trigger_mbl").removeClass("active")
            $(".hsearch_sec_mbl .form-control").blur()
          }
        }
      })
    }

    if ($(".mobile_mainacc_menutrigger").length > 0) {
      $(".mobile_mainacc_menutrigger").click(function (e) {
        e.stopPropagation()
        if ($(".mobile_mainacc_menulist").is(":visible")) {
          $(".mobile_mainacc_menulist").hide()
        } else {
          $(".mobile_mainacc_menulist").show()
        }
      })
      $(document).click(function (e) {
        if (!$(e.target).is(".mobile_account_item, .mobile_account_item * ")) {
          if ($(".mobile_mainacc_menulist").is(":visible")) {
            $(".mobile_mainacc_menulist").hide()
          }
        }
      })
    }
    /* Mobile header menu */
    $(".hcategory_trigger").click(function () {
      $(this).toggleClass("active")
      $(".hcategory_menu").toggleClass("open")
    })

    $(document).click(function (e) {
      if ($(e.target).is(".hcategory_selected_text")) {
        if ($(".hcategory_menu").hasClass("open")) {
          $(".hcategory_menu").removeClass("open")
          $(".hcategory_trigger").removeClass("active")
        }
      }
    })

    $(".disbl_href_action").click(function (e) {
      e.preventDefault()
    })
  }

  componentWillReceiveProps(PropsDt) {
    if (PropsDt.homePageState !== undefined) {
      if (this.state.openOutletTime !== PropsDt.homePageState.openOutletTime) {
        this.setState({ openOutletTime: PropsDt.homePageState.openOutletTime }, function () {
          if (PropsDt.homePageState.openOutletTime === true) {
            this.pickOutlet(this.state.pickupOutlets[0])
            this.props.sateValChange("openOutletTime", false)
          }
        })
      }
    }

    if (PropsDt.staticblack !== this.state.staticblacks) {
      var headenews = ""
      /*  
      var homeTestmonial = "";
      var homeOurSpecialDishes = ""; */
      if (Object.keys(PropsDt.staticblack).length > 0) {
        PropsDt.staticblack.map((data) => {
          if (data.staticblocks_slug === "news") {
            headenews = data.staticblocks_description
            return ""
          }
        })
      }

      headenews = headenews !== "" && headenews !== null ? Parser(headenews) : headenews
      this.setState({
        staticblacks: PropsDt.staticblack,
        headenews: headenews,
      })
    }

    if (PropsDt.menudata !== this.props.menudata) {
      this.setState({ menudata: PropsDt.menudata[0].result_set })
    }

    if (PropsDt.outletslist !== this.state.pickupOutletsList) {
      this.setState(
        {
          pickupOutlets: PropsDt.outletslist,
          pickupOutletsList: PropsDt.outletslist,
        },
        function () {
          if (this.props.location.pathname === "/") {
            this.props.sateValChange("pickup_outlet", PropsDt.outletslist)
          }
        }
      )
    }

    if (PropsDt.alloutletslist !== this.state.deliveryOutletsList) {
      this.setState({
        deliveryOutlets: PropsDt.alloutletslist,
        deliveryOutletsList: PropsDt.outletslist,
      })
    }

    if (PropsDt.secondaryaddresslist !== this.state.secondaryaddresslist) {
      this.setState({ secondaryaddresslist: PropsDt.secondaryaddresslist })
    }

    if ("homePageState" in PropsDt) {
      if (PropsDt.homePageState.nextavail !== undefined && PropsDt.homePageState.nextavail !== "" && PropsDt.homePageState.nextavail !== this.state.nextavail) {
        this.setState({ nextavail: PropsDt.homePageState.nextavail })
      }
    }

    if (this.state.fbstatus === "loading") {
      if (PropsDt.fblogin !== undefined) {
        if (PropsDt.fblogin.length > 0) {
          this.setState({ fbstatus: "ok" }, function () {
            this.doLogin(PropsDt.fblogin[0])
          })
        }
      }
    }

    if (this.state.googlestatus === "loading") {
      if (PropsDt.googlelogin !== undefined) {
        if (PropsDt.googlelogin.length > 0) {
          this.setState({ googlestatus: "ok" }, function () {
            this.doLogin(PropsDt.googlelogin[0])
          })
        }
      }
    }

    if (PropsDt.logindata !== this.props.logindata) {
      this.doLogin(PropsDt.logindata[0])
    }

    if (this.state.fpstatus === "loading") {
      if (PropsDt.forgetpassword !== undefined) {
        this.setState({ fpstatus: "ok" })
        this.showMessage(PropsDt.forgetpassword[0])
      }
    }

    if (this.state.regstatus === "loading") {
      if (PropsDt.registration !== undefined) {
        var formPay_load = this.state.fieldssignup
        this.setState({
          regstatus: "ok",
          fieldssignup: {
            firstname: "",
            email: "",
            password: "",
            rePassword: "",
            mobile: "",
            pdpa_consent: "",
            terms: "",
            captchavalue: "",
            completeReg: "Y",
            check_ref_code: "",
          },
        })
        hideLoader("otpverify_submit", "class")
        this.showMessage(PropsDt.registration[0])
      }
    }
    if (PropsDt.changepassword !== this.props.changepassword) {
      //  const {changepassword} = nextProps.changepassword;
      $(".old_password").val("")
      $(".new_password").val("")
      $(".confirm_password").val("")
      hideLoader("change-pass-sbmt", "class")
      if (PropsDt.changepassword[0].status === "ok") {
        showAlert("Success", "Password changed successfully!")
      } else {
        if (PropsDt.changepassword[0].form_error) {
          showAlert("Alert", PropsDt.changepassword[0].form_error)
        } else {
          showAlert("Alert", PropsDt.changepassword[0].message)
        }
      }
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      })
    }
  }

  loadMobileMenu() {
    axios.get(apiUrl + "menu/menuv1?app_id=" + appId + "&menu_slug=mobile-menu").then((res) => {
      /* Success response */
      if (res.data.status === "ok") {
        this.setState({ mobileMenu: res.data.result_set })
      }
    })
  }

  /*  showPosition(position) {
    this.props.getPickupOutlets(position.coords);
  } */

  /*forget password  - start*/
  fieldforgot = (field, value) => {
    this.setState(update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } }))
  }

  forgotpassword = () => {
    this.setState({ fpstatus: "loading" })
    const formPayload = this.state.fieldsfgtpassword
    var postObject = {
      app_id: appId,
      type: "web",
      email_address: formPayload.email,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    }

    showLoader("forgotpassword-cls", "class")
    this.props.getForgetPassword(qs.stringify(postObject))
  }
  /*forget password  - end*/

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }))
  }

  handleSignin = () => {
    const formPayload = this.state.fields
    var postObject = {
      app_id: appId,
      type: "web",
      login_username: formPayload.email,
      login_password: formPayload.password,
    }
    showLoader("login_submit", "class")
    this.props.getLoginData(qs.stringify(postObject))
  }
  /* signin - end*/

  /* for signup - start*/
  fieldChangeSignup = (field, value) => {
    if (field == "terms") {
      value = $("#terms").prop("checked")
    }
    if (field == "pdpa_consent") {
      value = $("#pdpa_consent").prop("checked")
    }
    this.setState(update(this.state, { fieldssignup: { [field]: { $set: value } } }))
  }

  handleSignup = () => {
    const formPayload = this.state.fieldssignup
    this.setState({ regstatus: "loading" })
    var pdpaConsent = formPayload.pdpa_consent === true ? "yes" : "no"
    var postObject = {
      app_id: appId,
      type: "web",
      customer_first_name: formPayload.firstname,
      customer_email: formPayload.email,
      customer_password: formPayload.password,
      customer_phone: formPayload.mobile,
      customer_birthdate: formPayload.birthday,
      customer_pdpa_consent: pdpaConsent,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
      customer_ref_code: formPayload.check_ref_code,
    }

    showLoader("signup_submit", "class")
    this.props.getRegistration(qs.stringify(postObject))
  }

  /* for signup - end*/

  /* Change Password */
  fieldChangepassword = (field, value) => {
    this.setState(update(this.state, { fieldschpassword: { [field]: { $set: value } } }))
  }

  changepassword = () => {
    var old_password = $(".old_password").val()
    var new_password = $(".new_password").val()
    var confirm_password = $(".confirm_password").val()
    var postObject = {
      app_id: appId,
      type: "web",
      oldpassword: old_password,
      password: new_password,
      confirmpassword: confirm_password,
      refrence: cookie.load("UserId"),
    }
    showLoader("change-pass-sbmt", "class")
    this.props.getChangePassword(qs.stringify(postObject))
  }

  /* main - menu navigation -start*/

  createLink(menu) {
    if (menu.nav_type === "0" && menu.nav_parent_title == "") {
      return (
        <Link to={"/" + menu.nav_title_slug} title={menu.nav_title} onClick={this.closeMobileMenu.bind(this, menu.sub_menu)} className={menu.sub_menu.length > 0 && "main_sub_arrow"}>
          <span>{stripslashes(menu.nav_title)}</span>
        </Link>
      )
    } else if (menu.nav_type === "3" && menu.nav_parent_title == "") {
      var pageUrlTxt = menu.nav_pages
      if (pageUrlTxt.includes("http")) {
        return (
          <a
            href={menu.nav_pages}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
            onClick={this.closeMobileMenu.bind(this, menu.sub_menu)}
            className={menu.sub_menu.length > 0 ? "main_sub_arrow" : ""}
          >
            <span>{stripslashes(menu.nav_title)}</span>
          </a>
        )
      } else {
        return (
          <a
            href={menu.nav_pages === "#" ? void 0 : "/" + menu.nav_pages}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
            onClick={this.closeMobileMenu.bind(this, menu.sub_menu)}
            className={menu.sub_menu.length > 0 ? "main_sub_arrow" : ""}
          >
            <span>{stripslashes(menu.nav_title)}</span>
          </a>
        )
      }
    }
  }

  createSubmenu(menu, type, setp) {
    if (menu) {
      var checkIngVal = 0
      var liTxt = menu.map(function (menuparent, i) {
        checkIngVal = 1
        return (
          <li key={i}>
            <Link to={"/" + menuparent.nav_pages} title={menuparent.nav_title}>
              <span>{menuparent.nav_title}</span>
            </Link>
            {menuparent.sub_menu !== "" && typeof menuparent.sub_menu !== undefined && typeof menuparent.sub_menu !== "undefined" && menuparent.sub_menu.length > 0 && (
              <>
                {this.createSubmenu(menuparent.sub_menu, "ul", "second")}
                <a href={void 0} className="submenu-arow sub_sub_arrow"></a>
              </>
            )}
          </li>
        )
      }, this)

      if (type === "span" && checkIngVal === 1) {
        return <a href="/" className="submenu-arow disbl_href_action"></a>
      } else if (type === "ul" && checkIngVal === 1) {
        return <ul className={setp === "first" ? "main_sublist submenu_list" : "subsubmenu_list"}>{liTxt}</ul>
      } else {
        return ""
      }
    }
  }
  closeMobileMenu(sub_menu) {
    if (sub_menu.length === 0) {
      $("#close_mobile_menu").trigger("click")
    }
  }

  menuActiveCls(nav_pages) {
    var currenturl = window.location.href
    var returnClsTx = ""
    if (nav_pages === "home" || nav_pages === "#" || nav_pages === "/") {
      returnClsTx = this.props.match.path === "/home" || this.props.match.path === "/" ? "active" : ""
    } else if (nav_pages === "menu") {
      returnClsTx =
        this.props.match.path === "/menu" ||
        this.props.match.path === "/menu/:slugType/:slugValue" ||
        this.props.match.path === "/menu/:slugType/:slugValue/:proValue" ||
        this.props.match.path === "/checkout" ||
        this.props.match.path === "/thankyou/:orderId"
          ? "active"
          : ""
    } else {
      returnClsTx = currenturl.includes(nav_pages) ? "active" : nav_pages
    }
    return returnClsTx
  }

  listMainNavigation() {
    if (this.state.mobileMenu) {
      return this.state.mobileMenu.map(function (menu, i) {
        let nav_pages = menu.nav_pages
        if (menu.nav_type === "0") {
          nav_pages = menu.nav_title_slug
        }

        return (
          <li key={i + 100} className={this.menuActiveCls(nav_pages)}>
            {this.createLink(menu)}
            {menu.sub_menu.length > 0 && <a href={void 0} className="submenu-arow main_sub_arrow"></a>}
            {menu.sub_menu.length > 0 && this.createSubmenu(menu.sub_menu, "ul", "first")}

            {/* this.createSubmenu(menu, "span")}
            {this.createSubmenu(menu, "ul") */}
          </li>
        )
      }, this)
    }
  }

  loadHeaderMenuNavigation() {
    var whereCond = ""
    if (cookie.load("orderOutletId") !== "" && typeof cookie.load("orderOutletId") !== undefined && typeof cookie.load("orderOutletId") !== "undefined") {
      whereCond += "&outletId=" + cookie.load("orderOutletId")
    }
    if (cookie.load("defaultAvilablityId") !== "" && typeof cookie.load("defaultAvilablityId") !== undefined && typeof cookie.load("defaultAvilablityId") !== "undefined") {
      whereCond += "&availability=" + cookie.load("defaultAvilablityId")
    }

    axios.get(apiUrlV2 + "products/getHeaderMenuNavigation?app_id=" + appId + whereCond).then((res) => {
      /* Success response */
      if (res.data.status === "ok") {
        this.loadDisplayHeaderMenu(res.data.result_set)
      }
    })
  }

  listMainDesktopNavigation() {
    if (this.state.menudata) {
      return this.state.menudata.map(function (menu, i) {
        var start = 0
        return (
          <li key={i} className={menu.sub_menu.length > 0 ? "dropdown-li" : ""}>
            {this.createDesktopLink(menu)}
            {menu.sub_menu.length > 0 && (
              <div className="dropdown-menu mega-nav">
                <div className="mn-inner">
                  <div className="mn-inner-lhs">
                    {menu.sub_menu.map((item, index1) => {
                      return (
                        <Link to={"/" + item.nav_pages} title={item.nav_title} key={index1}>
                          {stripslashes(item.nav_title)}
                        </Link>
                      )
                    })}
                  </div>
                  <div className="mn-inner-rhs">
                    {menu.sub_menu.map((item1, index1) => {
                      if (item1.nav_icon !== "" && item1.nav_icon !== null && start <= 2) {
                        return (
                          <Link to={item1.nav_pages} key={index1}>
                            <img src={mediaUrl + "menu-icon/" + item1.nav_icon} loading="lazy" />
                          </Link>
                        )
                      }
                      start++
                    })}
                  </div>
                </div>
              </div>
            )}
          </li>
        )
      }, this)
    }
  }

  createDesktopLink(menu) {
    if (menu.nav_type === "0" && menu.nav_parent_title == "") {
      return (
        <Link to={"/" + menu.nav_title_slug} title={menu.nav_title}>
          {stripslashes(menu.nav_title)}
        </Link>
      )
    } else if (menu.nav_type === "3" && menu.nav_parent_title == "") {
      var pageUrlTxt = menu.nav_pages
      if (pageUrlTxt.includes("http")) {
        return (
          <a href={menu.nav_pages} title={menu.nav_title} target={menu.nav_link_type == "blank" ? "_blank" : ""}>
            {stripslashes(menu.nav_title)}
          </a>
        )
      } else {
        return (
          <Link to={menu.nav_pages != "#" ? "/" + menu.nav_pages : ""} title={menu.nav_title} target={menu.nav_link_type == "blank" ? "_blank" : ""}>
            {stripslashes(menu.nav_title)}
          </Link>
        )
      }
    }
  }

  loadDisplayHeaderMenu(menuList) {
    var displayHeaderMenu = ""
    if (menuList.length > 0) {
      displayHeaderMenu = menuList.map((item, index) => {
        var subcatList = item.is_sub_list === "Yes" ? item.subcat_list_arr.sub_result_set : []
        var imageList = []

        return (
          <li key={index} className={subcatList.length > 0 ? "dropdown-li" : ""}>
            <Link to={"/menu/category/" + item.pro_cate_slug}>{item.pro_cate_header_custom_title}</Link>
            {subcatList.length > 1 && (
              <div className="dropdown-menu mega-nav">
                <div className="mn-inner">
                  <div className="mn-inner-lhs">
                    {subcatList.map((item1, index1) => {
                      if (item1.pro_subcate_image !== "" && item1.pro_subcate_image !== null) {
                        imageList.push(item1.pro_subcate_image)
                      }
                      return (
                        <Link to={"/menu/category/" + item.pro_cate_slug} key={index1}>
                          {item1.pro_subcate_header_custom_title}
                        </Link>
                      )
                    })}
                  </div>
                  {imageList.length > 0 && (
                    <div className="mn-inner-rhs">
                      {imageList.map((item1, index1) => {
                        return (
                          <Link to={"/menu/category/" + item.pro_cate_slug} key={index1}>
                            <img src={mediaUrl + "products/subcategory-image/" + item1} loading="lazy" />
                          </Link>
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>
            )}
          </li>
        )
      })
    }
    this.setState({ displayHeaderMenu: displayHeaderMenu }, function () {
      this.setState({ displayHeaderMenuLoading: false })
    })
  }

  /* menu navigation -end */
  /* show message */
  showMessage(response) {
    hideLoader("signup_submit", "class")
    hideLoader("forgotpassword-cls", "class")
    if (response.status === "ok") {
      showAlert("Success", response.message)
    } else {
      if (response.form_error) {
        showAlert("Error", response.form_error)
      } else {
        showAlert("Error", response.message)
      }
    }
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    })
  }

  chooseTempAvailabilityFun(availability, event) {
    event.preventDefault()
    this.setState({
      seletedTempAvilablityId: availability,
      errorAvailability: false,
    })
  }

  chooseAvailabilityFun(availability, event) {
    if (event !== "" && typeof event !== undefined && typeof event !== "undefined") {
      event.preventDefault()
    }

    if (this.state.seletedTempAvilablityId !== "") {
      var defaultAvilTy = cookie.load("defaultAvilablityId")
      this.setState({ errorAvailability: false })
      if (defaultAvilTy !== availability) {
        var cartTotalItems = cookie.load("cartTotalItems")
        cartTotalItems = cartTotalItems != "" && cartTotalItems != undefined ? parseInt(cartTotalItems) : 0
        if (cookie.load("orderOutletId") != "" && cookie.load("orderOutletId") != undefined) {
          this.setState({ nextavail: availability })
          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          })
          return false
        }
      } else if (defaultAvilTy === availability && cookie.load("orderOutletId") != "" && cookie.load("orderOutletId") != undefined) {
        /*$.magnificPopup.close();
      this.props.history.push('/menu');
      return false;*/
      }

      var popupIdtxt = ""
      if (availability === deliveryId) {
        $(".delivery_outletpoup").find(".outlet_error").html("")
        var deliveryInfo = []
        var deliveryTAT = "30"
        if (this.props.alloutletslist.length > 0) {
          this.props.alloutletslist.map((item) => {
            if (item.oa_outlet_id === defaultoutlet) {
              deliveryTAT = item.outlet_delivery_tat
              deliveryInfo["orderZoneId"] = ""
              deliveryInfo["orderOutletId"] = item.outlet_id
              deliveryInfo["orderOutletName"] = stripslashes(item.outlet_name)
              deliveryInfo["orderPostalCode"] = ""
              deliveryInfo["orderTAT"] = item.outlet_delivery_tat
              deliveryInfo["orderDeliveryAddress"] = ""
              deliveryInfo["orderOutletPhoneNumber"] = item.outlet_phone
              deliveryInfo["orderOutletEmail"] = item.outlet_email
              deliveryInfo["orderHandled"] = ""
              deliveryInfo["defaultAvilablityId"] = deliveryId

              var unitNum = this.showUnitNum(item.outlet_unit_number1, item.outlet_unit_number2)
              var outlet_address_line2 = item.outlet_address_line2 !== "" ? " " + item.outlet_address_line2 : ""
              var orderHandledText = item.outlet_address_line1 + outlet_address_line2 + ", " + unitNum + " Singapore " + item.outlet_postal_code

              deliveryInfo["orderHandledByText"] = orderHandledText
            }
          })
        }
        this.setState({
          getDateTimeFlg: "yes",
          isAdvanced: "yes",
          slotType: 2,
          seletedOutletId: defaultoutlet,
          seletedAvilablityId: deliveryId,
          deliveryInfo: deliveryInfo,
          order_tat_time: deliveryTAT,
          orderDeliveryAddress: "",
        })
        popupIdtxt = "#awesome-popup"
      } else if (availability === pickupId) {
        if (this.state.pickupOutlets !== "" && typeof this.state.pickupOutlets !== undefined && typeof this.state.pickupOutlets !== undefined) {
          if (this.state.pickupOutlets.length === 1) {
            this.pickOutlet(this.state.pickupOutlets[0])
          } else {
            popupIdtxt = "#takeaway-popup"
          }
        } else {
          popupIdtxt = "#takeaway-popup"
        }
      }

      if (popupIdtxt !== "") {
        $.magnificPopup.open({
          items: {
            src: popupIdtxt,
          },
          type: "inline",
        })
      }
    } else {
      this.setState({ errorAvailability: true })
    }
  }

  chooseAvailabilityMbl() {
    var availability = cookie.load("defaultAvilablityId")
    var showtext = "Order Now"
    if (availability === deliveryId) {
      showtext = "Delivery"
    } else if (availability === pickupId) {
      showtext = "Takeaway"
    }
    return showtext
  }

  /* facebook login */
  responseFacebook = (response) => {
    if (response.name !== "" && response.id !== "" && response.name !== undefined && response.id !== undefined) {
      var birthday = response.birthday
      var photo = response.picture.data.url
      /* update gender field */
      var postGender = ""
      if (typeof response.gender !== "undefined" && response.gender === "male") {
        postGender = "M"
      } else if (typeof response.gender !== "undefined" && response.gender === "female") {
        postGender = "F"
      }
      /* update DOB */
      var dob = ""
      if (typeof birthday !== "undefined" && birthday !== "") {
        dob = dateFormat(response.birthday, "yyyy-mm-dd")
      }

      var postObject = {
        app_id: appId,
        type: "web",
        login_firstname: response.first_name,
        login_lastname: response.last_name,
        login_username: response.email,
        customer_fb_id: response.id,
        customer_gender: postGender,
        customer_photo: photo,
        customer_dob: dob,
      }
      this.setState({ fbstatus: "loading" })
      this.props.getFbLoginData(qs.stringify(postObject))
    }
  }

  /* Google Login Response */

  handleCallbackResponse(cuttentthis, response) {
    if (response.credential !== "" && typeof response.credential !== undefined && typeof response.credential !== "undefined") {
      var profile = jwt_decode(response.credential)
      var resultRes = {
        name: profile.given_name,
        email: profile.email,
        picture: profile.picture,
        id: profile.sub,
      }
      cuttentthis.loginGoogle(resultRes)
    }
  }
  loginGoogle(response) {
    if (response.name !== "" && response.email !== "" && response.name !== undefined && response.email !== undefined) {
      var vSplitName = response.name.split(" ")
      var firstname = vSplitName[0]
      var lastname = vSplitName[1]

      var qs = require("qs")
      // var photo = response.picture;
      /* update gender field */

      var postObject = {
        app_id: appId,
        login_firstname: response.name,
        login_lastname: lastname,
        login_username: response.email,
        customer_google_id: response.id,
        type: "web",
        /* customer_photo: photo, */
      }
      this.setState({ googlestatus: "loading" })
      this.props.getGoogleLoginData(qs.stringify(postObject))
    }
  }

  /* for login and facebook login*/
  doLogin(fbloginData) {
    hideLoader("login_submit", "class")
    if (fbloginData.status === "ok") {
      $.magnificPopup.close()
      var mobileno = "",
        cust_birthdate = ""
      if (typeof fbloginData.result_set.customer_phone === "undefined" || fbloginData.result_set.customer_phone === "null" || fbloginData.result_set.customer_phone === "") {
        mobileno = ""
      } else {
        mobileno = fbloginData.result_set.customer_phone
      }

      if (
        typeof fbloginData.result_set.customer_birthdate !== "undefined" &&
        fbloginData.result_set.customer_birthdate !== "null" &&
        fbloginData.result_set.customer_birthdate !== null &&
        fbloginData.result_set.customer_birthdate !== "" &&
        fbloginData.result_set.customer_birthdate !== "0000-00-00"
      ) {
        cust_birthdate = fbloginData.result_set.customer_birthdate
      }
      cookie.save("UserId", fbloginData.result_set.customer_id, { path: "/" })
      cookie.save("UserEmail", fbloginData.result_set.customer_email, {
        path: "/",
      })
      cookie.save("UserFname", fbloginData.result_set.customer_first_name !== "" ? fbloginData.result_set.customer_first_name : "", { path: "/" })
      cookie.save("UserLname", fbloginData.result_set.customer_last_name !== "" ? fbloginData.result_set.customer_last_name : "", { path: "/" })
      cookie.save("UserMobile", mobileno, { path: "/" })
      cookie.save("UserBirthdate", cust_birthdate, { path: "/" })
      cookie.save("UserDefaultAddress", fbloginData.result_set.customer_address_name2, { path: "/" })
      cookie.save("UserDefaultUnitOne", fbloginData.result_set.customer_address_line1, { path: "/" })
      cookie.save("UserDefaultUnitTwo", fbloginData.result_set.customer_address_line2, { path: "/" })
      cookie.save("UserDefaultPostalCode", fbloginData.result_set.customer_postal_code, { path: "/" })
      cookie.save("Userregister_type", fbloginData.result_set.customer_register_type_corporate, { path: "/" })

      cookie.save("customer_type", fbloginData.result_set.customer_type, {
        path: "/",
      })
      const { history } = this.props

      var postObject = {
        app_id: appId,
        reference_id: getReferenceID(),
        customer_id: fbloginData.result_set.customer_id,
        availability_id:
          cookie.load("defaultAvilablityId") !== "" && typeof cookie.load("defaultAvilablityId") !== undefined && typeof cookie.load("defaultAvilablityId") !== "undefined"
            ? cookie.load("defaultAvilablityId")
            : deliveryId,
      }

      axios.post(apiUrl + "cart/update_customer_info", qs.stringify(postObject)).then((res) => {
        showAlert("Success", "Logged in Successfully!")
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        })
        if (res.data.status === "ok") {
          if (cookie.load("loginpopupTrigger") === "fromcheckout") {
            cookie.remove("loginpopupTrigger", { path: "/" })
            if (cookie.load("deliveryoptions") === "Yes") {
              cookie.remove("deliveryoptions", { path: "/" })
              history.push("/delivery-locations")
            } else {
              history.push("/checkout")
            }

            return false
          }

          if (cookie.load("review_order_id") !== "" && typeof cookie.load("review_order_id") !== undefined && typeof cookie.load("review_order_id") !== "undefined") {
            var review_order_id = cookie.load("review_order_id")
            cookie.remove("review_order_id", { path: "/" })
            history.push("/reviews/" + review_order_id)
          } else {
            history.push("/myaccount")
          }
        } else {
          if (cookie.load("review_order_id") !== "" && typeof cookie.load("review_order_id") !== undefined && typeof cookie.load("review_order_id") !== "undefined") {
            var review_order_id = cookie.load("review_order_id")
            cookie.remove("review_order_id", { path: "/" })
            history.push("/reviews/" + review_order_id)
          } else {
            history.push("/myaccount")
          }
        }
      })
    } else {
      cookie.remove("loginpopupTrigger", { path: "/" })
      showAlert("Error", fbloginData.message)
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      })
    }
  }
  closepopup(event) {
    event.preventDefault()

    $.magnificPopup.open({
      items: {
        src: "#order-popup",
      },
      type: "inline",
    })
  }

  gobckOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#order-popup",
      },
      type: "inline",
    })
  }

  gobckPkupOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#takeaway-popup",
      },
      type: "inline",
    })
  }

  changeAvailability() {
    var tempArr = [],
      tempVl = ""
    this.setState({
      seletedOutletId: tempVl,
      deliveryInfo: tempArr,
      pickupInfo: tempArr,
      seleted_ord_date: tempVl,
      seleted_ord_time: tempVl,
    })

    /* For Advanced Slot Start */
    this.setState({
      seletedOutletId: tempVl,
      deliveryInfo: tempArr,
      pickupInfo: tempArr,
      seleted_ord_date: tempVl,
      seleted_ord_time: tempVl,
      slotType: tempVl,
      seleted_ord_slot: tempVl,
      seleted_ord_slotTxt: tempVl,
      seleted_ord_slot_str: tempVl,
      seleted_ord_slot_end: tempVl,
    })
    /* For Advanced Slot End */

    this.destroyCart("Yes")

    var warning_avilablity = $("#warning_avilablity").val()
    var nextavail = this.state.nextavail
    if (warning_avilablity !== "") {
      var nextavail = warning_avilablity
    }

    var popupIdtxt = ""
    if (nextavail === deliveryId) {
      popupIdtxt = "#awesome-popup"
      this.chooseAvailabilityFun(deliveryId)
    } else if (nextavail === pickupId) {
      if (this.state.pickupOutlets !== "" && typeof this.state.pickupOutlets !== undefined && typeof this.state.pickupOutlets !== undefined) {
        if (this.state.pickupOutlets.length === 1) {
          this.pickOutlet(this.state.pickupOutlets[0])
        } else {
          popupIdtxt = "#takeaway-popup"
        }
      } else {
        popupIdtxt = "#takeaway-popup"
      }
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      })
    }
  }

  destroyCart(clear = "No") {
    this.props.destroyCartDetail()
    this.deleteOrderCookie(clear)
  }

  deleteOrderCookie(clear = "Yes") {
    if (clear == "Yes") {
      cookie.remove("orderZoneId", { path: "/" })
      cookie.remove("orderOutletId", { path: "/" })
      cookie.remove("outletchosen", { path: "/" })
    }

    removeOrderDateTime()
    removePromoCkValue()
    cookie.remove("orderPaymentMode", { path: "/" })
    cookie.remove("orderTableNo", { path: "/" })
    cookie.remove("product_remarks", { path: "/" })
    cookie.remove("orderOutletName", { path: "/" })
    cookie.remove("carttotalitems", { path: "/" })
    cookie.remove("cartsubtotal", { path: "/" })
    cookie.remove("cartid", { path: "/" })
    cookie.remove("firstNavigation", { path: "/" })

    /* Delivery avilablity */
    cookie.remove("orderDateTime", { path: "/" })
    cookie.remove("deliveryDate", { path: "/" })
    cookie.remove("deliveryTime", { path: "/" })
    cookie.remove("unitNoOne", { path: "/" })
    cookie.remove("unitNoTwo", { path: "/" })

    /* For Advanced Slot */
    cookie.remove("isAdvanced", { path: "/" })
    cookie.remove("slotType", { path: "/" })
    cookie.remove("orderSlotVal", { path: "/" })
    cookie.remove("orderSlotTxt", { path: "/" })
    cookie.remove("orderSlotStrTime", { path: "/" })
    cookie.remove("orderSlotEndTime", { path: "/" })

    cookie.remove("promotion_id", { path: "/" })
    cookie.remove("promotion_applied", { path: "/" })
    cookie.remove("promotion_code", { path: "/" })
    cookie.remove("promotion_delivery_charge_applied", { path: "/" })
    cookie.remove("promotion_amount", { path: "/" })
    cookie.remove("promotion_category", { path: "/" })
    cookie.remove("prmo_type", { path: "/" })

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" })
    cookie.remove("voucher_code", { path: "/" })
    cookie.remove("voucher_amount", { path: "/" })

    cookie.remove("points_redeemed", { path: "/" })
    cookie.remove("points_used", { path: "/" })
    cookie.remove("points_amount", { path: "/" })
    cookie.remove("prmo_type", { path: "/" })
  }

  /* find Zone*/
  findOutletBasedZone(first, availability) {
    if (first) {
      var postalcode = $("#postalcode").val()
    } else {
      var postalcode = $("#postalcode1").val()
    }

    /*  var outletIdTxt = this.state.delivery_outlet_id;

  if (outletIdTxt === "") {
    $(".postal_error").html(
      '<span class="error">Go Back and Select your delivery outlet.</span>'
    );
    return false;
  } */

    if (postalcode.length < 5) {
      $(".postal_error").html('<span class="error">Please enter valid postal code.</span>')
      return false
    }

    showLoader("delivery_submit_cls", "class")

    axios
      .all([axios.get(apiUrlV2 + "outlets/findOutletZone?app_id=" + appId + "&skip_timing=Yes&availability_id=" + availability + "&postal_code=" + postalcode + "&&postalcode_basedoutlet=yes")])
      .then(
        axios.spread((res, timeslt) => {
          var deliveryInfo = []
          /* Success response */
          if (res.data.status === "ok") {
            cookie.save("outletchosen", availability, { path: "/" })

            var additionalTatTime = res.data.result_set.zone_additional_tat_time !== "" ? res.data.result_set.zone_additional_tat_time : 0
            var outletDeliveryTiming = res.data.result_set.outlet_delivery_timing !== "" ? res.data.result_set.outlet_delivery_timing : 0
            var outletDeliveryTaT = parseInt(outletDeliveryTiming) + parseInt(additionalTatTime)
            var zip_sname = res.data.result_set.postal_code_information.zip_sname !== "" ? " " + res.data.result_set.postal_code_information.zip_sname : ""
            var orderDeliveryAddress = res.data.result_set.postal_code_information.zip_buno + zip_sname
            var orderHandled = stripslashes(res.data.result_set.outlet_name) + ", Crew will be seeing you in " + outletDeliveryTaT + " Minutes"

            deliveryInfo["orderZoneId"] = res.data.result_set.zone_id
            deliveryInfo["orderOutletId"] = res.data.result_set.outlet_id
            deliveryInfo["orderOutletName"] = stripslashes(res.data.result_set.outlet_name)
            deliveryInfo["orderPostalCode"] = res.data.result_set.postal_code_information.zip_code
            deliveryInfo["orderTAT"] = outletDeliveryTaT
            deliveryInfo["orderDeliveryAddress"] = orderDeliveryAddress
            deliveryInfo["orderOutletPhoneNumber"] = res.data.result_set.outlet_phone
            deliveryInfo["orderOutletEmail"] = res.data.result_set.outlet_email
            deliveryInfo["orderHandled"] = orderHandled
            deliveryInfo["defaultAvilablityId"] = availability

            var unitNum = this.showUnitNum(res.data.result_set.outlet_unit_number1, res.data.result_set.outlet_unit_number2)
            var outlet_address_line2 = res.data.result_set.outlet_address_line2 !== "" ? " " + res.data.result_set.outlet_address_line2 : ""
            var orderHandledText = res.data.result_set.outlet_address_line1 + outlet_address_line2 + ", " + unitNum + " Singapore " + res.data.result_set.outlet_postal_code

            deliveryInfo["orderHandledByText"] = orderHandledText

            this.setState(
              {
                deliveryInfo: deliveryInfo,
                seletedAvilablityId: availability,
                seletedOutletId: res.data.result_set.outlet_id,
                order_tat_time: outletDeliveryTaT,
                orderHandled: orderHandled,
                orderDeliveryAddress: orderDeliveryAddress + ", Singapore " + res.data.result_set.postal_code_information.zip_code,
                orderOutletPhoneNumber: res.data.result_set.outlet_phone,
                orderOutletEmail: res.data.result_set.outlet_email,
              },
              function () {
                axios.get(apiUrlV2 + "settings/chkTimeslotIsAvaiable?app_id=" + appId + "&availability_id=" + availability + "&outletId=" + res.data.result_set.outlet_id).then((timeslt) => {
                  hideLoader("delivery_submit_cls", "class")
                  $.magnificPopup.close()
                  /* Success time slot response */
                  if (timeslt.data.status === "success") {
                    /* For Advanced Slot */
                    var isAdvanced = timeslt.data.isAdvanced,
                      slotType = "0"
                    if (isAdvanced === "yes") {
                      slotType = timeslt.data.slotType
                    }
                    this.setState({
                      getDateTimeFlg: "yes",
                      isAdvanced: isAdvanced,
                      slotType: slotType,
                    })
                    removeOrderDateTime()
                    removePromoCkValue()

                    $.magnificPopup.open({
                      items: {
                        src: "#awesome-popup",
                      },
                      type: "inline",
                    })
                  } else {
                    $.magnificPopup.open({
                      items: {
                        src: "#outlet-error-popup",
                      },
                      type: "inline",
                    })
                  }
                })
              }
            )
          }

          /* Error response */
          if (res.data.status === "error") {
            this.setState({ deliveryInfo: deliveryInfo })
            hideLoader("delivery_submit_cls", "class")
            $.magnificPopup.close()
            $.magnificPopup.open({
              items: {
                src: "#error-postal-popup",
              },
              type: "inline",
            })

            if (first === 0) {
              var mgsTxt = res.data.message !== "" ? res.data.message : "Please enter valid postal code."
              $(".postal_error").html('<span class="error">' + mgsTxt + "</span>")
            }
          }
        })
      )
  }

  /* Select outlet */
  selectOutlet(first, availability) {
    if (first) {
      var postalcode = $("#postalcode").val()
    } else {
      var postalcode = $("#postalcode1").val()
    }

    if (postalcode.length < 5) {
      $(".postal_error").html('<span class="error">Please enter valid postal code.</span>')
      return false
    }

    showLoader("delivery_submit_cls", "class")

    axios.get(apiUrl + "outlets/find_outlet?skip_timing=Yes&app_id=" + appId + "&availability_id=" + availability + "&postal_code=" + postalcode).then((res) => {
      hideLoader("delivery_submit_cls", "class")

      /* Success response */
      if (res.data.status === "ok") {
        $.magnificPopup.close()
        cookie.save("outletchosen", availability, { path: "/" })
        var zip_sname = res.data.result_set.postal_code_information.zip_sname !== "" ? " " + res.data.result_set.postal_code_information.zip_sname : ""
        var orderDeliveryAddress = res.data.result_set.postal_code_information.zip_buno + zip_sname
        var orderHandled = stripslashes(res.data.result_set.outlet_name) + ", Crew will be seeing you in " + res.data.result_set.outlet_delivery_timing + " Minutes"
        this.setState({
          orderDeliveryAddress: orderDeliveryAddress + " Singapore " + res.data.result_set.postal_code_information.zip_code,
        })
        this.setState({ orderHandled: orderHandled })
        cookie.save("orderOutletId", res.data.result_set.outlet_id, {
          path: "/",
        })
        cookie.save("orderOutletName", stripslashes(res.data.result_set.outlet_name), { path: "/" })
        cookie.save("orderPostalCode", res.data.result_set.postal_code_information.zip_code, { path: "/" })
        cookie.save("orderTAT", res.data.result_set.outlet_delivery_timing, {
          path: "/",
        })
        cookie.save("orderDeliveryAddress", orderDeliveryAddress, {
          path: "/",
        })
        cookie.save("orderHandled", orderHandled, { path: "/" })
        cookie.save("defaultAvilablityId", availability, { path: "/" })
        var outlet_address_line2 = res.data.result_set.outlet_address_line2 !== "" ? " " + res.data.result_set.outlet_address_line2 : ""
        var orderHandledText = res.data.result_set.outlet_address_line1 + outlet_address_line2 + ", Singapore " + res.data.result_set.outlet_postal_code
        cookie.save("orderHandledByText", orderHandledText, { path: "/" })

        removeOrderDateTime()
        removePromoCkValue()

        $.magnificPopup.open({
          items: {
            src: "#awesome-popup",
          },
          type: "inline",
        })
      }

      /* Error response */
      if (res.data.status === "error") {
        $.magnificPopup.close()
        $.magnificPopup.open({
          items: {
            src: "#error-postal-popup",
          },
          type: "inline",
        })

        if (first === 0) {
          var mgsTxt = res.data.message !== "" ? res.data.message : "Please enter valid postal code."
          $(".postal_error").html('<span class="error">' + mgsTxt + "</span>")
        }
      }
    })
  }

  gotoProducts() {
    if (cookie.load("orderOutletId") == undefined || cookie.load("orderOutletId") == "") {
      $(".outlet_error").html('<span class="error"> Please choose one outlet.</span>')
    } else {
      cookie.save("outletchosen", cookie.load("defaultAvilablityId"), {
        path: "/",
      })
      $.magnificPopup.close()
      if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
        cookie.remove("popuptriggerFrom", { path: "/" })
        this.props.history.push("/")
      } else {
        this.props.history.push("/menu")
      }
    }
  }

  selectDatetm() {
    var seletedOutletId = this.state.seletedOutletId
    var pickupInfo = this.state.pickupInfo
    if (seletedOutletId !== "" && Object.keys(pickupInfo).length > 0) {
      showLoader("takeaway-btn-act", "class")

      axios.get(apiUrlV2 + "settings/chkTimeslotIsAvaiable?app_id=" + appId + "&availability_id=" + pickupId + "&outletId=" + seletedOutletId).then((res) => {
        hideLoader("takeaway-btn-act", "class")

        /* Success response */
        if (res.data.status === "success") {
          /* For Advanced Slot */
          var isAdvanced = res.data.isAdvanced,
            slotType = "0"
          if (isAdvanced === "yes") {
            slotType = res.data.slotType
          }
          this.setState({
            getDateTimeFlg: "yes",
            isAdvanced: isAdvanced,
            slotType: slotType,
          })

          removeOrderDateTime()
          removePromoCkValue()

          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          })
        } else {
          $.magnificPopup.open({
            items: {
              src: "#outlet-error-popup",
            },
            type: "inline",
          })
        }
      })
    } else {
      $(".outlet_error").html('<span class="error"> Please choose one outlet.</span>')
    }
  }

  selectDlyOutlet() {
    if (this.state.delivery_outlet_id === "") {
      $(".delivery_outletpoup").find(".outlet_error").html('<span class="error"> Please choose one outlet.</span>')
    } else {
      $.magnificPopup.close()
      $.magnificPopup.open({
        items: {
          src: "#delevery-postcode-popup",
        },
        type: "inline",
      })
    }
  }

  handleKeyPress = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.pickupOutletsList.filter(function (item) {
        if (
          stripslashes(item.outlet_name).toLowerCase().indexOf(value) >= 0 ||
          item.outlet_address_line1.toLowerCase().indexOf(value) >= 0 ||
          item.outlet_postal_code.toLowerCase().indexOf(value) >= 0
        ) {
          return item
        }
      })

    this.setState({ pickupOutlets: matches })
  }

  /* load outlets  */
  loadOutletsList() {
    if (Object.keys(this.state.pickupOutlets).length > 0) {
      return this.state.pickupOutlets.map((loaddata, index) => {
        return (
          <li key={index} className={this.activeOutlet(loaddata.outlet_id)}>
            <span onClick={this.setPickupOutletFun.bind(this, loaddata, loaddata.outlet_id)}>
              <div className="outlet-list-lhs">
                {loaddata.outlet_image !== "" ? (
                  <img src={outletUrl + loaddata.outlet_image} alt={stripslashes(loaddata.outlet_name)} loading="lazy" />
                ) : (
                  <img loading="lazy" src={mainLogo} alt={stripslashes(loaddata.outlet_name)} />
                )}
              </div>
              <div className="outlet-list-rhs">
                <h4 className="outlet-list-name">{stripslashes(loaddata.outlet_name)}</h4>
                <p className="outlet-list-address">
                  {loaddata.outlet_address_line1}, {this.showUnitNum(loaddata.outlet_unit_number1, loaddata.outlet_unit_number2)}
                  {CountryTxt} {loaddata.outlet_postal_code}
                </p>
                <ul>
                  {loaddata.outlet_phone !== "" && loaddata.outlet_phone !== null && (
                    <li className="otulet-phone">
                      <img src={outlet1} loading="lazy" />
                      <span>+65 - {loaddata.outlet_phone}</span>
                    </li>
                  )}
                  {/* <li className="otulet-waiting">
                    <img src={outlet3} />
                    {loaddata.outlet_delivery_tat > 0 && (
                      <span>Waiting : {loaddata.outlet_delivery_tat} min</span>
                    )}
                  </li> */}
                  {loaddata.da_monday_start_time !== "" && loaddata.da_monday_start_time !== null && (
                    <li className="otulet-timing">
                      <img src={outlet2} loading="lazy" />
                      <span>Store Opening Time: {loaddata.da_monday_start_time}</span>
                    </li>
                  )}
                  {loaddata.distance != "" && typeof loaddata.distance !== undefined && typeof loaddata.distance !== "undefined" && (
                    <li className="otulet-direction">
                      <img src={outlet2} loading="lazy" />
                      <span>
                        {loaddata.distance != "" && parseFloat(loaddata.distance) >= 0 && parseFloat(loaddata.distance).toFixed(2)} km{" "}
                        {loaddata.outlet_map_link != "" && (
                          <a href={loaddata.outlet_map_link} target="_blank" onClick={this.openMapLink.bind(this, loaddata.outlet_map_link)}>
                            Direction
                          </a>
                        )}
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </span>
          </li>
        )
      })
    } else {
      return (
        <li>
          <a>No Outlet found</a>
        </li>
      )
    }
  }
  openMapLink(link, e) {
    e.stopPropagation()
    e.preventDefault()
    window.open(link, "_blank")
  }

  setPickupOutletFun(outletDetails, outletID) {
    this.setState({
      seletedOutletId: outletID,
      popupOutletDetails: outletDetails,
      error_pickup_outlet: "",
    })

    this.pickOutlet(outletDetails, "")
  }

  activeOutlet(outletID) {
    var seletedOutletId = this.state.seletedOutletId
    var pickupInfo = this.state.pickupInfo
    var actTxt = parseInt(seletedOutletId) === parseInt(outletID) && Object.keys(pickupInfo).length > 0 ? "active" : ""
    return actTxt
  }

  handleKeyPressDly = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.deliveryOutletsList.filter(function (item) {
        if (
          stripslashes(item.outlet_name).toLowerCase().indexOf(value) >= 0 ||
          item.outlet_address_line1.toLowerCase().indexOf(value) >= 0 ||
          item.outlet_postal_code.toLowerCase().indexOf(value) >= 0
        ) {
          return item
        }
      })

    this.setState({ deliveryOutlets: matches })
  }

  showUnitNum(unit1, unit2) {
    unit1 = typeof unit1 !== "undefined" ? unit1 : ""
    unit2 = typeof unit2 !== "undefined" ? unit2 : ""

    if (unit1 !== "") {
      var unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1
    } else {
      var unitNo = unit2
    }

    return unitNo !== "" ? "#" + unitNo : ""
  }

  /* load delivery outlets  */
  loadDeliveryOutletsList() {
    if (Object.keys(this.state.deliveryOutlets).length > 0) {
      return this.state.deliveryOutlets.map((loaddata, index) => (
        <li key={index} className={this.activeDlyOutlet(loaddata.outlet_id)}>
          <a href="/" onClick={this.deliveryOutlet.bind(this, loaddata)}>
            {stripslashes(loaddata.outlet_name)}, {loaddata.outlet_address_line1}, {this.showUnitNum(loaddata.outlet_unit_number1, loaddata.outlet_unit_number2)}, Singapore{" "}
            {loaddata.outlet_postal_code}
          </a>
        </li>
      ))
    } else {
      return (
        <li>
          <a>No Outlet found</a>
        </li>
      )
    }
  }

  activeDlyOutlet(outletID) {
    var orderOutletId = this.state.delivery_outlet_id !== "" ? this.state.delivery_outlet_id : cookie.load("orderOutletId")

    return orderOutletId === outletID ? "active" : ""
  }

  /* pick outlet */
  pickOutlet(loaddata, event) {
    if (event !== undefined) {
      //  event.preventDefault();
    }

    var unitNum = this.showUnitNum(loaddata.outlet_unit_number1, loaddata.outlet_unit_number2)
    var outlet_address_line2 = loaddata.outlet_address_line2 !== "" ? " " + loaddata.outlet_address_line2 : ""
    var orderHandled = stripslashes(loaddata.outlet_name) + " " + loaddata.outlet_address_line1 + outlet_address_line2 + ", " + unitNum + " Singapore " + loaddata.outlet_postal_code

    var pickupInfo = []
    pickupInfo["orderOutletId"] = loaddata.outlet_id
    pickupInfo["orderOutletName"] = stripslashes(loaddata.outlet_name)
    pickupInfo["orderPostalCode"] = loaddata.outlet_postal_code
    pickupInfo["orderTAT"] = loaddata.outlet_pickup_tat
    pickupInfo["orderHandled"] = orderHandled
    pickupInfo["orderHandledByText"] = orderHandled
    pickupInfo["defaultAvilablityId"] = pickupId
    pickupInfo["orderOutletPhoneNumber"] = loaddata.outlet_phone
    pickupInfo["orderOutletEmail"] = loaddata.outlet_email

    this.setState(
      {
        pickupInfo: pickupInfo,
        seletedAvilablityId: pickupId,
        seletedOutletId: loaddata.outlet_id,
        order_tat_time: loaddata.outlet_pickup_tat,
        orderHandled: orderHandled,
        orderOutletPhoneNumber: loaddata.outlet_phone,
        orderOutletEmail: loaddata.outlet_email,
      },
      function () {
        this.selectDatetm()
      }.bind(this)
    )
  }

  deliveryOutlet(loaddata, event) {
    event.preventDefault()
    $(".delivery_outletpoup").find(".outlet_error").html("")
    this.setState(
      { delivery_outlet_id: loaddata.outlet_id },
      function () {
        this.selectDlyOutlet()
      }.bind(this)
    )
  }

  trgContinuBtn(idTxt) {
    $("#" + idTxt).trigger("click")
  }

  componentDidUpdate() {}

  getSearchProductList() {
    var orderOutletIdtext = cookie.load("orderOutletId")
    var addquery_txt = ""
    if (typeof orderOutletIdtext !== "undefined" && orderOutletIdtext !== "") {
      addquery_txt = "&outletId=" + orderOutletIdtext
    }
    var searchResult = []
    var defaultAvilablityId =
      cookie.load("defaultAvilablityId") !== "" && typeof cookie.load("defaultAvilablityId") !== undefined && typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : deliveryId
    return axios.get(apiUrlV2 + "products/search_products?app_id=" + appId + "&status=A&availability=" + defaultAvilablityId + addquery_txt).then((response) => {
      if (response.data.status === "ok") {
        var resultData = response.data.result_set
        for (var key in resultData) {
          var subcatListArr = !("product_list" in resultData[key]) ? Array() : resultData[key].product_list

          if (Object.keys(subcatListArr).length > 0) {
            if (Object.keys(subcatListArr[0]).length > 0) {
              var subCatArr = !("subcategorie" in subcatListArr[0][0]) ? Array() : subcatListArr[0][0].subcategorie

              for (var sctkey in subCatArr) {
                var productsArr = !("products" in subCatArr[sctkey]) ? Array() : subCatArr[sctkey].products
                for (var prokey in productsArr) {
                  var proNameTxt = getAliasName(productsArr[prokey].product_alias, productsArr[prokey].product_name)
                  searchResult.push({
                    cate_slug: subCatArr[sctkey].pro_cate_slug,
                    subcate_slug: subCatArr[sctkey].pro_subcate_slug,
                    value: productsArr[prokey].product_slug,
                    label: stripslashes(proNameTxt),
                  })
                }
              }
            }
          }
        }
      }
      if (this.props.match.path === "/menu/:slugType/:slugValue" || this.props.match.path === "/menu") {
        this.props.sateValChange("productlist", searchResult)
      }

      this.setState({
        searchProResult: searchResult,
        selectedProResult: searchResult,
      })
    })
  }

  searchProKeyPress = (event) => {
    $(".search_result").show()

    var value = event.target.value.toLowerCase(),
      matches = this.state.searchProResult.filter(function (item) {
        if (item.label.toLowerCase().indexOf(value) >= 0) {
          return item
        }
      })

    $("#clearSearch").show()
    if (value === "") {
      $("#clearSearch").hide()
    }

    this.setState({ selectedProResult: matches })
  }

  /* load product search result  */
  loadProSearchList() {
    if (Object.keys(this.state.selectedProResult).length > 0) {
      if ($("#search_result_mbl").length > 0) {
        $("#search_result_mbl").show()
      }

      return this.state.selectedProResult.map((loaddata, index) => (
        <li key={index}>
          <Link to={"/menu/" + loaddata.cate_slug + "/" + loaddata.subcate_slug + "/" + loaddata.value} title="Product Details">
            {loaddata.label}
          </Link>
        </li>
      ))
    } else {
      if ($("#search_result_mbl").length > 0) {
        $("#search_result_mbl").show()
      }
      return <li className="no-pro-found">No Product found</li>
    }
  }

  clearSearchFun() {
    var emtytxt = ""
    $(".productsearch").val(emtytxt)
    this.setState({ selectedProResult: this.state.searchProResult })
  }

  ViewProducts(event) {
    var productSlug = event.value
    this.props.history.push("/menu/cat-ftrpro/slug-ftrpro/" + productSlug)
  }

  checkAblBtn() {
    var availability = cookie.load("defaultAvilablityId")
    var orderOutletId = cookie.load("orderOutletId")
    var actText = "Order Now"
    if (availability === deliveryId && orderOutletId !== "" && orderOutletId !== undefined) {
      actText = "Delivery"
    } else if (availability === pickupId && orderOutletId !== "" && orderOutletId !== undefined) {
      actText = "Self Collection"
    }

    return (
      <a
        href="/menu"
        /* onClick={this.closepopup.bind(this)} */
        className="hordertype_btn"
        title={actText}
      >
        {actText}
      </a>
    )
  }

  checkActiveDivHd(avlType) {
    var clsTxt = "ordericon_link "
    /*
      var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");  (availability == avlType &&
        orderOutletId !== "" &&
        orderOutletId !== undefined) || */
    if (this.state.seletedTempAvilablityId === avlType) {
      clsTxt += "active"
    }
    return clsTxt
  }

  checkActiveDivMbl(avlType) {
    var clsTxt = ""
    var availability = cookie.load("defaultAvilablityId")
    var orderOutletId = cookie.load("orderOutletId")
    if (availability == avlType && orderOutletId !== "" && orderOutletId !== undefined) {
      clsTxt += "active"
    }
    return clsTxt
  }

  myAccountAction() {
    var currenturl = window.location.href
    var substringtxt = "myaccount"

    if (currenturl.includes(substringtxt) !== true) {
      return (
        <div>
          <Link to={"/myaccount"} className="myacunt_act" title="My Account">
            {" "}
            My Account{" "}
          </Link>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      )
    } else {
      return (
        <div>
          <a className="myacunt_act disbl_href_action" href="/">
            {" "}
            My Account{" "}
          </a>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      )
    }
  }

  changPostalValue(type, pstVl) {
    if (type === 1) {
      $("#postalcode").val(pstVl)
    } else {
      $("#postalcode1").val(pstVl)
    }
  }

  userAddressList(typeTxt) {
    if (this.state.secondaryaddresslist.length > 0) {
      var addListTxt = this.state.secondaryaddresslist.map((addr, index) => (
        <div className="address_linfo" key={typeTxt + "-" + index}>
          <div className="custom_radio">
            <input type="radio" name={"address_chk" + typeTxt} value={addr.postal_code} className="address_chk" onChange={this.changPostalValue.bind(this, typeTxt, addr.postal_code)} />
            <span>{addressFormat(addr.unit_code, addr.unit_code2, addr.address, addr.country, addr.postal_code)}</span>
          </div>
        </div>
      ))

      return (
        <div>
          {addListTxt}
          <div className="address_linfo" key={typeTxt + "dfl"}>
            <div className="custom_radio">
              <input type="radio" name={"address_chk" + typeTxt} value="" defaultChecked={true} className="address_chk" onChange={this.changPostalValue.bind(this, typeTxt, "")} />
              <span>Enter New Address</span>
            </div>
          </div>
        </div>
      )
    } else {
      return ""
    }
  }

  /* For Advanced Slot */
  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value })
    } else if (field == "ordDate") {
      var ordTime = ""
      $(".ordrdatetime_error").html("")
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      })
    } else if (field == "ordTime") {
      var tmSltArr = value
      var ordTime = ""
      $(".ordrdatetime_error").html("")
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      })
    } else if (field == "ordSlotDate") {
      var ordTime = ""
      $(".ordrdatetime_error").html("")
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      })
    } else if (field == "ordSlotTime") {
      var tmSltArr = value
      $(".ordrdatetime_error").html("")
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: tmSltArr["ordSlotVal"],
        seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
        seleted_ord_slot_str: tmSltArr["ordSlotStr"],
        seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
      })
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      })
    }
  }

  setOrderOutletDateTimeData() {
    var seletedOrdDate = this.state.seleted_ord_date
    var seletedOrdTime = this.state.seleted_ord_time
    if (seletedOrdDate !== "" && seletedOrdTime !== "" && seletedOrdDate !== null && seletedOrdTime !== null) {
      var orderInfoData = this.state.seletedAvilablityId === pickupId ? this.state.pickupInfo : this.state.deliveryInfo
      if (Object.keys(orderInfoData).length > 0) {
        var OrderDate = format(seletedOrdDate, "yyyy-MM-dd")
        /* For Advanced Slot */
        var OrderHours = seletedOrdTime.getHours()
        var OrderMunts = seletedOrdTime.getMinutes()
        var OrderSecnd = seletedOrdTime.getSeconds()
        var orderDateTime = new Date(OrderDate)
        orderDateTime.setHours(OrderHours)
        orderDateTime.setMinutes(OrderMunts)
        orderDateTime.setSeconds(OrderSecnd)

        var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy")
        var deliveryTime = this.convPad(OrderHours) + ":" + this.convPad(OrderMunts) + ":" + this.convPad(OrderSecnd)
        cookie.save("orderDateTime", orderDateTime, { path: "/" })
        cookie.save("deliveryDate", deliveryDate, { path: "/" })
        cookie.save("deliveryTime", deliveryTime, { path: "/" })

        /* For Advanced Slot */
        var isAdvanced = this.state.isAdvanced
        var slotType = this.state.slotType
        var orderSlotVal = "",
          orderSlotTxt = "",
          orderSlotStrTime = "",
          orderSlotEndTime = ""
        if (slotType === "2") {
          orderSlotVal = this.state.seleted_ord_slot
          orderSlotTxt = this.state.seleted_ord_slotTxt
          orderSlotStrTime = this.state.seleted_ord_slot_str
          orderSlotEndTime = this.state.seleted_ord_slot_end
        }
        cookie.save("isAdvanced", isAdvanced, { path: "/" })
        cookie.save("slotType", slotType, { path: "/" })
        cookie.save("orderSlotVal", orderSlotVal, { path: "/" })
        cookie.save("orderSlotTxt", orderSlotTxt, { path: "/" })
        cookie.save("orderSlotStrTime", orderSlotStrTime, { path: "/" })
        cookie.save("orderSlotEndTime", orderSlotEndTime, { path: "/" })
        /* For Advanced Slot End */

        if (this.state.seletedAvilablityId === deliveryId) {
          cookie.save("orderZoneId", orderInfoData["orderZoneId"], {
            path: "/",
          })
          cookie.save("orderDeliveryAddress", orderInfoData["orderDeliveryAddress"], { path: "/" })
        }
        cookie.save("orderOutletPhoneNumber", orderInfoData["orderOutletPhoneNumber"], {
          path: "/",
        })
        cookie.save("orderOutletEmail", orderInfoData["orderOutletEmail"], {
          path: "/",
        })

        cookie.save("orderOutletId", orderInfoData["orderOutletId"], {
          path: "/",
        })
        cookie.save("orderOutletName", orderInfoData["orderOutletName"], {
          path: "/",
        })
        cookie.save("orderPostalCode", orderInfoData["orderPostalCode"], {
          path: "/",
        })
        cookie.save("orderTAT", orderInfoData["orderTAT"], { path: "/" })
        cookie.save("orderHandled", orderInfoData["orderHandled"], {
          path: "/",
        })
        cookie.save("defaultAvilablityId", orderInfoData["defaultAvilablityId"], { path: "/" })
        cookie.save("orderHandledByText", orderInfoData["orderHandledByText"], {
          path: "/",
        })
        cookie.save("outletchosen", orderInfoData["defaultAvilablityId"], {
          path: "/",
        })
        $.magnificPopup.close()
        if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
          cookie.remove("popuptriggerFrom", { path: "/" })
          this.props.history.push("/")
        } else if (cookie.load("redirectfavourite") === "Yes") {
          cookie.remove("redirectfavourite", { path: "/" })
          this.props.history.push("/favourite")
        } else {
          if (this.props.location.pathname === "/menu" || this.props.location.pathname === "/menu/:slugType/:slugValue" || this.props.location.pathname === "/menu/:slugType/:slugValue/:proValue") {
            $("#dvLoading").show()
            location.reload()
            // this.props.history.push("/refpage/menu");
          } else {
            this.props.history.push("/menu")
          }
        }
      } else {
        $.magnificPopup.open({
          items: {
            src: "#outlet-error-popup",
          },
          type: "inline",
        })
      }
    } else {
      $(".ordrdatetime_error").html('<span class="error"> Please select order date and time.</span>')
    }
  }

  convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString()
  }

  searBlkFun(typeTxt) {
    var defaultAvilTyId = cookie.load("defaultAvilablityId")
    var liTopCls = "htico_search"
    var scearDivTopCls = "hsearch_sec"
    var triggerActCls = "hsearch_trigger"
    var searchResultCls = "search_result"
    var searchIconcls = "search_i_icon"
    if (typeTxt === "mbl") {
      liTopCls = "htico_search_mbl"
      scearDivTopCls = "hsearch_sec_mbl"
      triggerActCls = "hsearch_trigger_mbl"
      searchResultCls = "search_result_mbl"
      searchIconcls = "fa fa-search"
    }

    return (
      <li className={liTopCls}>
        <a
          href="#"
          /* data-effect="mfp-zoom-in" */
          className={triggerActCls}
          title="Search"
        >
          <img src={loupe} alt="" />
        </a>
        <div className={scearDivTopCls}>
          <div className="input-sec">
            <input type="text" name="searchkey" className="productsearch" id={"productsearch_" + typeTxt} placeholder="Search..." autoComplete="off" onKeyUp={this.searchProKeyPress} />

            <a href="/" id="clearSearch" onClick={this.clearSearchFun.bind(this)} className="search_text_clear disbl_href_action">
              X
            </a>
          </div>

          <ul className={searchResultCls} id={searchResultCls}>
            {this.loadProSearchList()}
          </ul>
        </div>
      </li>
    )
  }
  selectdeliveryAddressType(type) {
    this.setState({ deliveryAddressType: type })
  }

  addMoreLocation() {
    var total_Delivery_Location = this.state.totalDeliveryLocation
    total_Delivery_Location.push({
      postal_code: "",
      name: "",
      phone_number: "",
      floor_no: "",
      unit_no: "",
      error_postal_code: "",
      error_name: false,
      error_phone_number: false,
      error_floor_no: false,
      error_unit_no: false,
    })
    var total_Delivery_Address = this.state.totalDeliveryAddress
    total_Delivery_Address.push({
      delivery_address: "",
      zone_id: "",
      zone_error: "",
      outlet_id: "",
    })
    this.setState({
      totalDeliveryLocation: total_Delivery_Location,
      totalDeliveryAddress: total_Delivery_Address,
    })
  }
  removeLocation(removeIndex) {
    var newDeliveryLocation = []
    var newtotalDeliveryAddress = []
    if (this.state.totalDeliveryLocation.length > 0) {
      this.state.totalDeliveryLocation.map((item, index) => {
        if (removeIndex !== index) {
          newDeliveryLocation.push(item)
          newtotalDeliveryAddress.push(totalDeliveryAddress[index])
        }
      })
    }
    this.setState({
      totalDeliveryLocation: newDeliveryLocation,
      totalDeliveryAddress: newtotalDeliveryAddress,
    })
  }

  handleMultiKeyPress = (addressIndex, inputkey, event) => {
    var value = event.target.value
    var regex1 = /^[0-9\b]+$/
    if ((inputkey === "postal_code" || inputkey === "phone_number") && value !== "") {
      if (!regex1.test(value)) {
        return false
      }
    }
    var newDeliveryLocation = []
    if (this.state.totalDeliveryLocation.length > 0) {
      this.state.totalDeliveryLocation.map((item, index) => {
        if (addressIndex === index) {
          var postal_code = ""
          var error_postal_code = item.error_postal_code
          if (inputkey === "postal_code") {
            postal_code = value
            if (value === "") {
              error_postal_code = "This field is required"
            }
          } else {
            postal_code = item.postal_code
            error_postal_code = item.error_postal_code
          }
          var name = ""
          var errornamen = item.error_name
          if (inputkey === "name") {
            name = value
            if (value === "") {
              errornamen = true
            } else {
              errornamen = false
            }
          } else {
            name = item.name
          }

          var phone_number = ""
          var errorphonenumbern = item.error_phone_number
          if (inputkey === "phone_number") {
            phone_number = value
            if (value === "") {
              errorphonenumbern = true
            } else {
              errorphonenumbern = false
            }
          } else {
            phone_number = item.phone_number
          }

          var floor_no = ""
          var errorfloornon = item.error_floor_no
          if (inputkey === "floor_no") {
            floor_no = value
            if (value === "") {
              errorfloornon = true
            } else {
              errorfloornon = false
            }
          } else {
            floor_no = item.floor_no
          }
          var unit_no = ""
          if (inputkey === "unit_no") {
            unit_no = value
          } else {
            unit_no = item.unit_no
          }
          var appendLocation = {
            postal_code: postal_code,
            name: name,
            phone_number: phone_number,
            floor_no: floor_no,
            unit_no: unit_no,
            error_postal_code: error_postal_code,
            error_floor_no: errorfloornon,
            error_name: errornamen,
            error_phone_number: errorphonenumbern,
            /* error_unit_no: false, */
          }
          if (inputkey === "postal_code" && value.length === 6 && item.postal_code !== value) {
            this.loadZoneAddress(addressIndex, value)
          }

          if (Object.keys(appendLocation).length > 0) {
            newDeliveryLocation.push(appendLocation)
          }
        } else {
          newDeliveryLocation.push(item)
        }
      })
    }
    if (newDeliveryLocation.length > 0) {
      this.setState({ totalDeliveryLocation: newDeliveryLocation })
    }
  }

  loadZoneAddress(addressIndex, value) {
    var totalDeliveryAddress = []
    axios.get(apiUrlV2 + "outlets/findOutletZone?app_id=" + appId + "&skip_timing=Yes&availability_id=" + deliveryId + "&postal_code=" + value + "&postalcode_basedoutlet=yes").then((res) => {
      var appendaddresss = {
        delivery_address: "",
        zone_id: "",
        zone_error: "",
        outlet_id: "",
      }
      if (res.data.status === "ok") {
        var deliveryInfo = []
        var additionalTatTime = res.data.result_set.zone_additional_tat_time !== "" ? res.data.result_set.zone_additional_tat_time : 0
        var outletDeliveryTiming = res.data.result_set.outlet_delivery_timing !== "" ? res.data.result_set.outlet_delivery_timing : 0
        var outletDeliveryTaT = parseInt(outletDeliveryTiming) + parseInt(additionalTatTime)
        var zip_sname = res.data.result_set.postal_code_information.zip_sname !== "" ? " " + res.data.result_set.postal_code_information.zip_sname : ""
        var orderDeliveryAddress = res.data.result_set.postal_code_information.zip_buno + zip_sname
        var orderHandled = stripslashes(res.data.result_set.outlet_name) + ", Crew will be seeing you in " + outletDeliveryTaT + " Minutes"

        deliveryInfo["orderZoneId"] = res.data.result_set.zone_id
        deliveryInfo["orderOutletId"] = res.data.result_set.outlet_id
        deliveryInfo["orderOutletName"] = stripslashes(res.data.result_set.outlet_name)
        deliveryInfo["orderPostalCode"] = res.data.result_set.postal_code_information.zip_code
        deliveryInfo["orderTAT"] = outletDeliveryTaT
        deliveryInfo["orderDeliveryAddress"] = orderDeliveryAddress
        deliveryInfo["orderOutletPhoneNumber"] = res.data.result_set.outlet_phone
        deliveryInfo["orderOutletEmail"] = res.data.result_set.outlet_email
        deliveryInfo["orderHandled"] = orderHandled
        deliveryInfo["defaultAvilablityId"] = deliveryId

        var unitNum = this.showUnitNum(res.data.result_set.outlet_unit_number1, res.data.result_set.outlet_unit_number2)
        var outlet_address_line2 = res.data.result_set.outlet_address_line2 !== "" ? " " + res.data.result_set.outlet_address_line2 : ""
        var orderHandledText = res.data.result_set.outlet_address_line1 + outlet_address_line2 + ", " + unitNum + " Singapore " + res.data.result_set.outlet_postal_code

        deliveryInfo["orderHandledByText"] = orderHandledText
        if (addressIndex === 0) {
          this.setState({
            deliveryInfo: deliveryInfo,
            seletedAvilablityId: deliveryId,
            seletedOutletId: res.data.result_set.outlet_id,
            order_tat_time: outletDeliveryTaT,
            orderHandled: orderHandled,
            orderDeliveryAddress: orderDeliveryAddress + ", Singapore " + res.data.result_set.postal_code_information.zip_code,
            orderOutletPhoneNumber: res.data.result_set.outlet_phone,
            orderOutletEmail: res.data.result_set.outlet_email,
          })
        }

        appendaddresss = {
          delivery_address: orderDeliveryAddress + ", Singapore " + res.data.result_set.postal_code_information.zip_code,
          zone_id: res.data.result_set.zone_id,
          zone_error: "",
          outlet_id: res.data.result_set.outlet_id,
        }
      } else {
        var appendaddresss = {
          delivery_address: "",
          zone_id: "",
          zone_error: "We can`t deliver your postal code",
          outlet_id: "",
        }
      }
      if (this.state.totalDeliveryAddress.length > 0) {
        this.state.totalDeliveryAddress.map((item, index) => {
          if (index === addressIndex) {
            totalDeliveryAddress.push(appendaddresss)
          } else {
            totalDeliveryAddress.push(item)
          }
        })
        this.setState({ totalDeliveryAddress: totalDeliveryAddress })
      }
    })
  }
  continueMultiDelivery() {
    var deriveryerror = 0
    var errormsg = ""
    var total_delivery_address = this.state.totalDeliveryAddress
    var total_delivery_locationn = this.state.totalDeliveryLocation

    var updatedaddress = []
    var updatedLocation = []
    if (total_delivery_address.length > 1) {
      total_delivery_address.map((item, index) => {
        if (item.zone_error !== "" || this.state.totalDeliveryLocation[index].postal_code === "") {
          deriveryerror++
        }
        var zone_error = ""
        if (item.zone_id === "" && total_delivery_locationn[index].postal_code === "") {
          zone_error = "This field is required"
        } else {
          zone_error = item.zone_error
        }
        updatedaddress.push({
          delivery_address: item.delivery_address,
          outlet_id: item.outlet_id,
          zone_error: zone_error,
          zone_id: item.outlet_id,
        })
        var errorfloorno = false
        if (total_delivery_locationn[index].floor_no === "") {
          errorfloorno = true
        }
        var errorname = false
        if (total_delivery_locationn[index].name === "") {
          errorname = true
        }
        var errorphonenumber = false
        if (total_delivery_locationn[index].phone_number === "") {
          errorphonenumber = true
        }

        updatedLocation.push({
          error_floor_no: errorfloorno,
          error_name: errorname,
          error_phone_number: errorphonenumber,
          error_postal_code: "",
          error_unit_no: false,
          floor_no: total_delivery_locationn[index].floor_no,
          name: total_delivery_locationn[index].name,
          phone_number: total_delivery_locationn[index].phone_number,
          postal_code: total_delivery_locationn[index].postal_code,
          unit_no: total_delivery_locationn[index].unit_no,
        })
      })
      this.setState({
        totalDeliveryAddress: updatedaddress,
        totalDeliveryLocation: updatedLocation,
      })

      if (deriveryerror > 0) {
        errormsg = "Please enter correct you address"
      }
    } else {
      deriveryerror++
      errormsg = "Please enter at least 2 delivery address"
    }
    if (deriveryerror === 0) {
      showLoader("multiple_location_continue", "class")
      this.setState({ multideliveryerror: "" })
      var finaldeliveryaddress = []

      total_delivery_address.map((item, index) => {
        finaldeliveryaddress.push({
          postal_code:
            total_delivery_locationn[index].postal_code !== "" && typeof total_delivery_locationn[index].postal_code !== undefined && typeof total_delivery_locationn[index].postal_code !== "undefined"
              ? total_delivery_locationn[index].postal_code
              : "",
          zone_id: item.zone_id,
          delivery_address: item.delivery_address,
          name:
            total_delivery_locationn[index].name !== "" && typeof total_delivery_locationn[index].name !== undefined && typeof total_delivery_locationn[index].name !== "undefined"
              ? total_delivery_locationn[index].name
              : "",
          phone_number:
            total_delivery_locationn[index].phone_number !== "" &&
            typeof total_delivery_locationn[index].phone_number !== undefined &&
            typeof total_delivery_locationn[index].phone_number !== "undefined"
              ? total_delivery_locationn[index].phone_number
              : "",
          floor_no:
            total_delivery_locationn[index].floor_no !== "" && typeof total_delivery_locationn[index].floor_no !== undefined && typeof total_delivery_locationn[index].floor_no !== "undefined"
              ? total_delivery_locationn[index].floor_no
              : "",
          unit_no:
            total_delivery_locationn[index].unit_no !== "" && typeof total_delivery_locationn[index].unit_no !== undefined && typeof total_delivery_locationn[index].unit_no !== "undefined"
              ? total_delivery_locationn[index].unit_no
              : "",
        })
      })

      var postObject = {
        app_id: appId,
        reference_id: getReferenceID(),
        customer_id: cookie.load("UserId") !== "" && typeof cookie.load("UserId") !== undefined && typeof cookie.load("UserId") !== "undefined" ? cookie.load("UserId") : "",
        delivery_address: JSON.stringify(finaldeliveryaddress),
      }
      axios.post(apiUrl + "cart/create_delivery_location", qs.stringify(postObject)).then((res) => {
        hideLoader("multiple_location_continue", "class")

        cookie.save("delivery_address_type", this.state.deliveryAddressType, {
          path: "/",
        })

        if (res.data.status === "ok") {
          axios.get(apiUrlV2 + "settings/chkTimeslotIsAvaiable?app_id=" + appId + "&availability_id=" + deliveryId + "&outletId=" + total_delivery_address[0].outlet_id).then((timeslt) => {
            hideLoader("delivery_submit_cls", "class")
            $.magnificPopup.close()
            /* Success time slot response */
            if (timeslt.data.status === "success") {
              /* For Advanced Slot */
              var isAdvanced = timeslt.data.isAdvanced,
                slotType = "0"
              if (isAdvanced === "yes") {
                slotType = timeslt.data.slotType
              }
              this.setState({
                getDateTimeFlg: "yes",
                isAdvanced: isAdvanced,
                slotType: slotType,
              })
              removeOrderDateTime()
              removePromoCkValue()

              $.magnificPopup.open({
                items: {
                  src: "#awesome-popup",
                },
                type: "inline",
              })
            } else {
              $.magnificPopup.open({
                items: {
                  src: "#outlet-error-popup",
                },
                type: "inline",
              })
            }
          })
        } else {
        }
      })
    } else {
      this.setState({ multideliveryerror: errormsg })
    }
  }

  uploadBulkDelivery(event) {
    var selectedFile = event.target.files[0]
    const formData = new FormData()
    formData.append("app_id", appId)
    formData.append("bulk_delivery", selectedFile)
    axios.post(apiUrl + "cart/uploadmultilocation", formData).then((res) => {
      $("#bulk_delivery").val("")
      if (res.data.status === "ok") {
        var postalCodeList = res.data.result_set

        if (postalCodeList.length > 0) {
          var uploadedLocation = []
          var uploadeAddress = []
          postalCodeList.map((item) => {
            uploadedLocation.push({
              postal_code: item.postal_code,
              name: item.name,
              phone_number: item.phone_number,
              floor_no: item.floor_no,
              unit_no: item.unit_no,
              error_postal_code: "",
              error_name: false,
              error_phone_number: false,
              error_floor_no: false,
              error_unit_no: false,
            })
            uploadeAddress.push({
              delivery_address: "",
              zone_id: "",
              zone_error: "",
              outlet_id: "",
            })
          })
          this.setState(
            {
              totalDeliveryLocation: uploadedLocation,
              totalDeliveryAddress: uploadeAddress,
            },
            function () {
              this.state.totalDeliveryLocation.map((item, index) => {
                this.loadZoneAddress(index, item.postal_code)
              })
            }
          )
        }
      } else {
      }
    })
  }
  gobackOrderType() {
    $.magnificPopup.open({
      items: {
        src: "#order-popup",
      },
      type: "inline",
    })
  }
  gobackPrevStep() {
    if (this.state.seletedAvilablityId === deliveryId) {
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      })
    } else {
      $.magnificPopup.open({
        items: {
          src: "#takeaway-popup",
        },
        type: "inline",
      })
    }
  }
  openLogin() {
    if (cookie.load("UserId") !== "" && typeof cookie.load("UserId") !== undefined && typeof cookie.load("UserId") !== "undefined") {
      this.props.history.push("/membership")
    } else {
      $.magnificPopup.open({
        items: {
          src: "#signup-popup",
        },
        type: "inline",
      })
    }
  }

  handleClickOutside(e) {
    if (!$(e.target).closest(".mobile-menu").length && !$(e.target).is(".menu_icon")) {
      $(".mobile-menu").removeClass("active")
      $("body").removeClass("mbl-hiden")
      $("body").css("overflow", "visible")
    }
  }

  handleClickMobileMenu = (e) => {
    this.loadMobileMenu()
    this.loadInfo()
    e.stopPropagation()
    $("body").toggleClass("mbl-hiden")
    setTimeout(function () {
      $(".mobile-menu").toggleClass("active")
    }, 300)
    $(document).on("click", this.handleClickOutside)
  }

  handleClickCloseMobileMenu = (e) => {
    e.stopPropagation()
    $(".mobile-menu").removeClass("active")
    $("body").removeClass("mbl-hiden")
    $("body").css("overflow", "visible")
    $(document).off("click", this.handleClickOutside)
  }

  render() {
    var currenturl = window.location.href
    var substring = "menu"
    var isCheckout = "checkout"

    var showCatryName = typeof this.props.showCatryName !== "undefined" ? this.props.showCatryName : "Category Name"

    /* For Advanced Slot Start */
    var defaultAvilTyId = cookie.load("defaultAvilablityId")
    var settingsArr = this.props.globalsettings
    var advancedTimeslotEnable = "0"
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        advancedTimeslotEnable = settingsArr[0].result_set.client_advanced_timeslot_enable
      }
    }

    return (
      <>
        <Helmet>
          <title>Awfully Chocolate | Shop Famous Chocolate Cake Online With Delivery in Singapore - Awfully Chocolate</title>
          <meta name="description" content="Baked fresh daily and handcrafted from scratch, the All Chocolate Cake remains Awfully Chocolate’s best seller since 1998! Click here to shop now" />
          <meta
            name="keywords"
            content="awfully chocolate,chocolate,chocolate cakes,singapore chocolate cakes,handmade cakes,chocolate bars,chocolate truffles,handmade cookies,chocolate gift,chocolate hampers"
          />

          <meta property="og:title" content="Awfully Chocolate | Shop Famous Chocolate Cake Online With Delivery in Singapore - Awfully Chocolate" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://www.awfullychocolate.com/img/logosocial_awfullychocolate.png" />
          <meta property="og:url" content="https://www.awfullychocolate.com/" />
          <meta property="og:description" content="Baked fresh daily and handcrafted from scratch, the All Chocolate Cake remains Awfully Chocolate’s best seller since 1998! Click here to shop now" />
        </Helmet>
        <div className="news-scrolling">
          <div className="container">{this.state.headenews !== "" && <OwlCarousel options={infosetting}>{this.state.headenews || null}</OwlCarousel>}</div>
        </div>
        <header>
          <div className="header-top-cls">
            <div className="container">
              <div className="logo-main-section">
                <div className="menu_icon trigger_menunav_act" onClick={this.handleClickMobileMenu}>
                  <span className="icon-bar icon-bar1"></span>
                  <span className="icon-bar icon-bar2"></span>
                  <span className="icon-bar icon-bar3"></span>
                </div>
                <div className="mobile-menu">
                  <div className="mobile-menu-close" onClick={this.handleClickCloseMobileMenu}>
                    <span id="close_mobile_menu"></span>
                  </div>
                  <div className="mobile-menu-body">
                    <ul className="menu">
                      {!cookie.load("UserId") && (
                        <>
                          <li>
                            <a href="#login-popup" data-effect="mfp-zoom-in" className="open-popup-link htico_sign" title="Login">
                              <span>Login</span>
                            </a>
                          </li>
                          <li>
                            <a href="#signup-popup" data-effect="mfp-zoom-in" className="open-popup-link" title="Sign up">
                              {" "}
                              <span>Sign Up</span>
                            </a>
                          </li>
                        </>
                      )}

                      {cookie.load("UserId") && (
                        <>
                          <li>
                            <Link
                              className={
                                this.props.match.path === "/myaccount" ||
                                this.props.match.path === "/myorders" ||
                                this.props.match.path === "/rewards" ||
                                this.props.match.path === "/voucher" ||
                                this.props.match.path === "/mypromotions"
                                  ? "htico_sign active"
                                  : "htico_sign"
                              }
                              to="/myaccount"
                              title="My Account"
                            >
                              <i /> <span>My Account</span>
                            </Link>
                          </li>
                          <li>
                            <Link className="htico_sign" to="/logout" title="Logout">
                              <i /> <span>Logout</span>
                            </Link>
                          </li>
                        </>
                      )}
                      {this.state.mobileMenu && this.listMainNavigation()}
                    </ul>
                  </div>
                </div>

                <div className="logo">
                  <Link to={"/"} title={projectTitle}>
                    <img src={mainLogo} alt={projectTitle} />
                  </Link>
                </div>

                <div className="hmenu-login-section">
                  <ul className="hmenu-login-act">
                    {!cookie.load("UserId") && (
                      <li className="hsign_sec">
                        <a href="#login-popup" data-effect="mfp-zoom-in" className="open-popup-link htico_sign" title="Login">
                          <span>Login</span>
                        </a>
                        <span>|</span>
                        <a href="#signup-popup" data-effect="mfp-zoom-in" className="open-popup-link" title="Sign up">
                          {" "}
                          <span>Sign Up</span>
                        </a>
                      </li>
                    )}

                    {cookie.load("UserId") && (
                      <li className="hsign_sec">
                        <Link
                          className={
                            this.props.match.path === "/myaccount" ||
                            this.props.match.path === "/myorders" ||
                            this.props.match.path === "/rewards" ||
                            this.props.match.path === "/voucher" ||
                            this.props.match.path === "/mypromotions"
                              ? "htico_sign active"
                              : "htico_sign"
                          }
                          to="/myaccount"
                          title="My Account"
                        >
                          <i /> <span>My Account</span>
                        </Link>
                        <span>|</span>
                        <Link className="htico_sign" to="/logout" title="Logout">
                          <i /> <span>Logout</span>
                        </Link>
                      </li>
                    )}
                    <li className={this.props.match.path === "/favourite" ? "hcart_like active" : "hcart_like"}>
                      <Link
                        to={"/favourite"}
                        onClick={(e) => {
                          e.preventDefault()
                          if (!cookie.load("UserId")) {
                            window.$.magnificPopup.open({
                              items: {
                                src: "#login-popup",
                              },
                              type: "inline",
                            })
                          } else {
                            this.props.history.push("/favourite")
                          }
                        }}
                      >
                        <img src={cartLike} alt="Favourite" />
                        <img src={cartLikeActive} alt="Favourite" />
                      </Link>
                    </li>
                    {this.searBlkFun("mbl")}
                    {this.searBlkFun("desktop")}

                    <li className="hordertype_sec">
                      {this.checkAblBtn()}
                      {/*  <a href="/" className="hordertype_btn" title="Order Now">
                        Order Now
                      </a> */}
                    </li>

                    <li className="htico_cart">
                      <CartSideBar {...this.props} headerState={this.state} prpSateValChange={this.props.sateValChange} pageName="header" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="header-btm">
            <div className="container">
              <div className="hmenu_sec">
                <ul className="hmenu_list desktop_hmenu_list"></ul>
              </div>
              <div className="megamenu">
                <ul>
                  <li>
                    <Link to={"/page/the-awfully-chocolate-bakery-cafe"}>
                      <img className="concept-img" src={awenav} />
                    </Link>
                  </li>
                  {this.state.menudata && this.listMainDesktopNavigation()}
                  <li>
                    <Link to={"/membership"}>Members Portal</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* mobile_accountdel_body - start */}
          {currenturl.includes(substring) && (
            <div className="hcategory_sec">
              <a href={void 0} className="hcategory_selected_text disbl_href_action">
                <strong>{showCatryName}</strong>{" "}
                <span className="hcategory_trigger">
                  <img src={threeline} alt="" />
                </span>
              </a>
              <div className="hcategory_menu ">
                <MenuNavigationmob />
              </div>
            </div>
          )}

          {this.props.match.path !== "/menu" &&
            this.props.match.path !== "/menu/:slugType/:slugValue" &&
            this.props.match.path !== "/menu/category/:slugValue/:subcatSlug" &&
            this.props.match.path !== "/menu/:slugType/:slugValue/:proValue" && (
              <div className="mobile-order-nowbtn">
                <Link className="mobile_account_menu_click" to="/menu">
                  Order Now
                </Link>
              </div>
            )}

          <div className="custom_alertcls alert-success alert-dismissible1 alert_fixed success_hide" id="jquery-success-msg" style={{ display: "none" }}>
            {" "}
            <a href="/" type="button" className="custom_close" data-dismiss="alert" aria-label="Close">
              {" "}
              {/*  <span aria-hidden="true">×</span> */}{" "}
            </a>{" "}
            <p className="jquery-success-msg">Nice! Products added to your cart</p>{" "}
          </div>

          <div className="custom_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide" id="jquery-error-msg" style={{ display: "none" }}>
            {" "}
            <a href="/" className="custom_close" data-dismiss="alert" aria-label="Close">
              {" "}
              {/* <span aria-hidden="true">×</span> */}{" "}
            </a>{" "}
            <p className="jquery-error-msg">Something went wrong</p>{" "}
          </div>

          <div className="custom_center_alertcls alert-success alert-dismissible1 alert_fixed success_hide" id="jquery-common-success-msg" style={{ display: "none" }}>
            {" "}
            <a href="/" type="button" className="custom_close" data-dismiss="alert" aria-label="Close">
              {" "}
              <span aria-hidden="true">×</span>{" "}
            </a>{" "}
            <p className="jquery-common-success-msg">Nice! Products added to your cart</p>{" "}
          </div>

          <div className="custom_center_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide" id="jquery-common-error-msg" style={{ display: "none" }}>
            {" "}
            <a href="/" className="custom_close" data-dismiss="alert" aria-label="Close">
              {" "}
              <span aria-hidden="true">×</span>{" "}
            </a>{" "}
            <p className="jquery-common-error-msg">Something went wrong</p>{" "}
          </div>

          {/* login popup */}
          <div id="login-popup" className="mfp-hide popup_sec login-popup">
            <div className="pop-whole full-login-new">
              <div className="full-login-new-header">
                <h3> Login </h3>
                <p>Already A Member? Sign In Below!</p>
              </div>
              <div className="full-login-new-body">
                <Login fields={this.state.fields} onChange={this.fieldChange} onValid={this.handleSignin} onInvalid={() => console.log("Form invalid!")} />
                {/*   <button
                class="g-recaptcha"
                data-sitekey="6Le8C-YjAAAAAD-QMPjpQOxzmoBtaGFXZr2xC1EY"
                data-callback="onSubmit"
                data-action="submit"
              >
                Submit
              </button> */}

                <div className="or-break">Or</div>
                <div className="cp-social">
                  <div className="cp-social-inner">
                    <div className="google-btn">
                      <div id="signInDiv"></div>
                      <span>Sign in with Google</span>
                    </div>

                    {/* <div className="fb-btn">
                    <FacebookLogin
                      appId={fbAppId}
                      fields="name,email,picture,first_name,last_name,birthday,gender"
                      callback={this.responseFacebook}
                      scope="public_profile,email,user_birthday"
                      cssclassName="fa fa-facebook"
                      redirectUri={baseUrl}
                      icon="fa-facebook"
                      textButton="Sign in with Facebook"
                    />
                  </div> */}
                  </div>

                  <div className="controls two-links">
                    <a href="#signup-popup" data-effect="mfp-zoom-in" className="open-popup-link">
                      {" "}
                      Not A Member Yet? Create an account
                    </a>
                    <span className="spilter"> </span>
                    <a href="#forgot-password-popup" className="open-popup-link">
                      Forgot Password
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Signup popup */}
          <div id="signup-popup" className="mfp-hide popup_sec signup-popup">
            <div className="pop-whole full-login-new">
              <div className="full-login-new-header">
                <h3> Create an Account </h3>
                <p>YOU’RE JUST A FEW STEPS AWAY FROM CHOCOLATE HEAVEN</p>
              </div>
              <div className="full-login-new-body two-col-member">
                <div className="two-colm-lhs">
                  <Signup fields={this.state.fieldssignup} onChange={this.fieldChangeSignup} onValid={this.handleSignup} onInvalid={() => console.log("Form invalid!")} />
                </div>
                <div className="two-colm-rhs">
                  <div className="two-offer-top">
                    <h5>MEMBER BENEFITS</h5>
                    <p>
                      It’s free to become an Awfully Chocolate member. Sign up today and get insider access to exclusive offers and benefits, birthday treats and start earning points! -{" "}
                      <strong>and an introductory 10% off your next purchase online!</strong>
                    </p>
                  </div>
                  <ul>
                    <li>
                      <a href={void 0} className="offer-box eo-box">
                        <img src={offers} loading="lazy" />
                        <span>
                          MORE MEMBERSHIP <br />
                          EXCLUSIVE OFFERS{" "}
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href={void 0} className="offer-box bt-box">
                        <img src={cakeout} loading="lazy" />
                        <span>
                          {" "}
                          BIRTHDAY TREATS <br />
                          ON THE HOUSE
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Forgot Password Popup */}

          <div id="forgot-password-popup" className="mfp-hide popup_sec forgot-password-popup">
            <div className="pop-whole full-login-new">
              <div className="full-login-new-header">
                <h3>Forgot your Password?</h3>
                <p>You can reset your Password here.</p>
              </div>

              <div className="full-login-new-body">
                <Forgotpassword fields={this.state.fieldsfgtpassword} onChange={this.fieldforgot} onValid={this.forgotpassword} onInvalid={() => console.log("Form invalid!")} />
              </div>
            </div>
          </div>

          {/* Change Password */}
          <div id="change-password-popup" className="white-popup mfp-hide popup_sec changepw_popup">
            <div className="pouup_in">
              <div id="form-msg"></div>
              <h3 className="title1 text-center">Change Password</h3>
              <Changepassword fields={this.state.fieldschpassword} onChange={this.fieldChangepassword} onValid={this.changepassword} onInvalid={() => console.log("Form invalid!")} />
            </div>
          </div>

          {/* Order popup - start */}
          <div id="order-popup" className="white-popup mfp-hide popup_sec order_popup">
            <div className="order-body">
              <div className="order-body-header">
                <h2>How would you like to enjoy</h2>
                <p>
                  <img className="minmise" src={mainLogo} alt="" loading="lazy" />
                </p>
              </div>
              <div className="order_delivery_row">
                <div className="order_delivery_col">
                  <p className="choose-txt">Please Choose </p>
                  <ul className="order_delivery_item">
                    <li>
                      <a href="/" onClick={this.chooseTempAvailabilityFun.bind(this, deliveryId)} className={this.checkActiveDivHd(deliveryId)}>
                        <div className="header-ordernow-single-img">
                          <img className="order_type_img" src={deliveryImg} loading="lazy" />
                        </div>
                        <h3>Delivery</h3>
                      </a>
                    </li>

                    <li>
                      <a href="/" onClick={this.chooseTempAvailabilityFun.bind(this, pickupId)} className={this.checkActiveDivHd(pickupId)}>
                        <div className="header-ordernow-single-img">
                          <img className="order_type_img" src={takeawayImg} loading="lazy" />
                        </div>
                        <h3>Self Collection</h3>
                      </a>
                    </li>
                  </ul>
                  {this.state.errorAvailability === true && <span className="error">Please Choose Any One Availability</span>}
                  <div className="continue">
                    <a href={void 0} className="button" onClick={this.chooseAvailabilityFun.bind(this, this.state.seletedTempAvilablityId)}>
                      Continue
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Order popup - end */}

          {/* Delivery Popup - Start */}
          <div id="delevery-popup" className="white-popup mfp-hide popup_sec delivery_outletpoup self_popup">
            <div className="order-body">
              <div className="self_popup_hea_row">
                <div className="self_popup_hea_col_left">
                  <img className="outlet-scooter-img" src={deliveryImg} loading="lazy" />
                </div>
                <div className="self_popup_hea_col">
                  <h2>Please Select</h2>
                  <p>Your Delivery Outlet</p>
                </div>
              </div>

              <div className="self_pop_row">
                <div className="self_pop_col self_pop_col_right">
                  <div className="self_pop_item">
                    <div className="self_pop_locbx">
                      <div className="form-group">
                        <div className="focus-out">
                          <label>Search Outlet</label>
                          <input type="input" className="form-control input-focus" onKeyUp={this.handleKeyPressDly} />
                          <div className="outlet_error"></div>
                        </div>
                      </div>
                    </div>

                    <div className="self_outlet">
                      <h2>Nearby Outlets</h2>
                      <ul className="self_outlet_inner">{this.loadDeliveryOutletsList()}</ul>
                      <a className="button disbl_href_action" id="delivery-continue-link" href="/" onClick={this.selectDlyOutlet.bind(this)}>
                        Continue
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Delivery Popup - end */}

          {/*  Delivery Postal code Popup - start */}
          <div id="delevery-postcode-popup" className="white-popup mfp-hide popup_sec delevery_popup">
            <div className="order-body">
              <div className="order-body-header">
                <h2>Delivery</h2>
                <p>Please Update Your Delivery Details</p>
              </div>
              <div className="order_delivery_col">
                {cookie.load("UserId") && <div className="address-list-cls address-list-main">{this.userAddressList(1)}</div>}

                <div className="delivery-tab">
                  <ul>
                    <li className={this.state.deliveryAddressType === "S" ? "active" : ""}>
                      {" "}
                      <a href={void 0} onClick={this.selectdeliveryAddressType.bind(this, "S")}>
                        Deliver to Single Location
                      </a>{" "}
                    </li>
                    <li className={this.state.deliveryAddressType === "M" ? "active" : ""}>
                      {" "}
                      <a href={void 0} onClick={this.selectdeliveryAddressType.bind(this, "M")}>
                        Deliver To Many Locations
                      </a>
                    </li>
                  </ul>
                </div>
                {this.state.deliveryAddressType === "S" && (
                  <>
                    <div className="form-group top-formgroup">
                      <div className={this.state.secondaryaddresslist.length > 0 ? "focus-out focused" : "focus-out"}>
                        <input type="text" placeholder="Enter your postal code" id="postalcode" pattern="\d*" maxLength="6" className="form-control input-focus" />
                        <div className="postal_error"></div>
                      </div>
                    </div>
                    <div className="btn_sec">
                      <div className="two-button-row">
                        <div className="first-btn">
                          <a href="/" onClick={this.gobckOutletpopup.bind(this)} className="button disbl_href_action" title="Go Back">
                            Go Back
                          </a>
                        </div>
                        <div className="second-btn">
                          <input type="button" onClick={this.findOutletBasedZone.bind(this, 1, deliveryId)} className="button" value="Continue" />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {this.state.deliveryAddressType === "M" && (
                  <>
                    <div className="delivery-many">
                      <div className="delivery-top">
                        <ul>
                          {this.state.totalDeliveryLocation.length > 0 &&
                            this.state.totalDeliveryLocation.map((item, index) => {
                              return (
                                <li key={index}>
                                  <span className="number-round">{parseInt(index) + 1}</span>
                                  {this.state.totalDeliveryLocation.length > 1 && (
                                    <a className="close-round" href={void 0} onClick={this.removeLocation.bind(this, index)}>
                                      x
                                    </a>
                                  )}
                                  <div className="delivery-top-box">
                                    <div className="form-group dtp-one">
                                      <div className="focus-out">
                                        <label>Postal Code</label>
                                        <input
                                          type="text"
                                          className="form-control input-focus"
                                          pattern="\d*"
                                          maxLength="6"
                                          value={item.postal_code}
                                          onChange={this.handleMultiKeyPress.bind(this, index, "postal_code")}
                                        />
                                        {item.error_postal_code !== "" ? (
                                          <span className="error">{item.error_postal_code}</span>
                                        ) : (
                                          this.state.totalDeliveryAddress.length > 0 &&
                                          this.state.totalDeliveryAddress[index] !== "" &&
                                          typeof this.state.totalDeliveryAddress[index] !== undefined &&
                                          typeof this.state.totalDeliveryAddress[index] !== "undefined" &&
                                          this.state.totalDeliveryAddress[index].zone_error !== "" && <span className="error">{this.state.totalDeliveryAddress[index].zone_error}</span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="form-group dtp-two">
                                      <div className="focus-out">
                                        <label>Name</label>
                                        <input type="text" className="form-control input-focus" value={item.name} onChange={this.handleMultiKeyPress.bind(this, index, "name")} maxLength={"6"} />
                                        {item.error_name === true && <span className="error">This field is required</span>}
                                      </div>
                                    </div>
                                    <div className="form-group dtp-three">
                                      <div className="focus-out">
                                        <label>Tel Number</label>
                                        <input
                                          type="text"
                                          className="form-control input-focus"
                                          value={item.phone_number}
                                          onChange={this.handleMultiKeyPress.bind(this, index, "phone_number")}
                                          maxLength={"8"}
                                        />
                                        {item.error_phone_number === true && <span className="error">This field is required</span>}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="delivery-btm-box">
                                    <div className="form-group add-grp">
                                      <span>Deliver to</span>
                                      <p>
                                        {this.state.totalDeliveryAddress.length > 0 &&
                                        this.state.totalDeliveryAddress[index] !== "" &&
                                        typeof this.state.totalDeliveryAddress[index] !== undefined &&
                                        typeof this.state.totalDeliveryAddress[index] !== "undefined"
                                          ? this.state.totalDeliveryAddress[index].delivery_address
                                          : "-"}
                                      </p>
                                      {}
                                    </div>
                                    <div className="form-group dtp-four">
                                      <div className="focus-out">
                                        <label>Floor No</label>
                                        <input type="text" className="form-control input-focus" value={item.floor_no} onChange={this.handleMultiKeyPress.bind(this, index, "floor_no")} />
                                        {item.error_floor_no === true && <span className="error">This field is required</span>}
                                      </div>
                                    </div>
                                    <div className="form-group dtp-five">
                                      <div className="focus-out">
                                        <label>Unit No</label>
                                        <input type="text" className="form-control input-focus" value={item.unit_no} onChange={this.handleMultiKeyPress.bind(this, index, "unit_no")} />
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )
                            })}
                        </ul>
                        <div className="plusmore">
                          <a href={void 0} onClick={this.addMoreLocation.bind(this)}>
                            <span>+</span>Add More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="delivery-botm">
                      <div className="delivery-botm-lhs">
                        <h4>Upload by CSV Template</h4>
                        <p>
                          Download your csv template <a href={mainurl + "lib/file/multiple_delivery_locations.csv"}>here</a> and upload back to us{" "}
                        </p>
                        {/* <a href="#" className="button">
                        Upload Address List
                      </a> */}
                        <div className="upload-address">
                          <input type="file" name="bulk_delivery" id="bulk_delivery" onChange={this.uploadBulkDelivery.bind(this)} />
                          <label for="bulk_delivery">Upload Address List</label>
                        </div>
                      </div>
                      <div className="delivery-botm-rhs">
                        <h4>Do you have more than 20 delivery locations?</h4>
                        <p>
                          Download your csv template{" "}
                          <a href={mainurl + "lib/file/multiple_delivery_locations.csv"} target={"_blank"}>
                            here
                          </a>{" "}
                          send us via email to <a href="#">sales@awfullychocolate.com</a>
                        </p>
                      </div>
                    </div>
                    <div className="two-button-row">
                      <div className="first-btn">
                        <a href="/" onClick={this.gobckOutletpopup.bind(this)} className="button disbl_href_action" title="Go Back">
                          Go Back
                        </a>
                      </div>
                      <div className="second-btn">
                        <input type="button" onClick={this.continueMultiDelivery.bind(this)} className="button multiple_location_continue" value="Continue" />
                      </div>
                      {this.state.multideliveryerror !== "" && <span className="error">{this.state.multideliveryerror}</span>}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* Delevery Popup - end */}

          {/* Delevery Popup error - start */}
          <div id="error-postal-popup" className="white-popup mfp-hide popup_sec error_postal_popup">
            <div className="popup_equalrw">
              <div className="popup_ttsec">
                <div className="innervmid_in">
                  <div className="pop_title poptt_icontop text-center">
                    <img src={iconUnhappy} loading="lazy" />
                    <h2 className="text-uppercase">Sorry </h2>
                    <small>We cant find your postal code</small>
                  </div>
                </div>
              </div>
              <div className="popup_right">
                <div className="innervmid_in">
                  {/*<h4>Enter your postal code</h4>*/}

                  {cookie.load("UserId") && <div className="address-list-cls address-list-error">{this.userAddressList(2)}</div>}

                  <form className="form_sec">
                    <div className="form-group">
                      <div className={this.state.secondaryaddresslist.length > 0 ? "focus-out focused" : "focus-out"}>
                        <label>Enter your postal code</label>
                        <input type="text" id="postalcode1" pattern="\d*" maxLength="6" className="form-control input-focus" />
                        <div className="postal_error"></div>
                      </div>
                    </div>
                    <div className="btn_sec delivery_submit_cls delivery_submit_div">
                      {/*<input type="button" onClick={this.selectOutlet.bind(this, 0, deliveryId)} className="button delivery_submit" value="Continue" />*/}
                      <input type="button" onClick={this.findOutletBasedZone.bind(this, 0, deliveryId)} className="button delivery_submit" value="Continue" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Delevery Popup error - Start */}

          {/* success popup - Start */}
          <div id="awesome-popup" className="white-popup mfp-hide popup_sec delevery_popup delevery_popup_datetime">
            <div className="order-body-header">
              <h2>{this.state.seletedAvilablityId === deliveryId ? "Choose Delivery Date" : "Choose Self Collection Date"}</h2>
              <p>Please choose your {this.state.seletedAvilablityId === deliveryId ? "delivery" : "self collection"} date</p>
            </div>

            <div className="popup_right">
              {this.state.seletedAvilablityId === pickupId && (
                <div>
                  <div className="inner-sure-title">
                    <img className="pop-scooter-img" src={iconWin} loading="lazy" />
                    <h4>Awesome</h4>

                    <p>You Can Pickup From</p>
                  </div>

                  <div className="awesome_del">
                    <h2>{this.state.orderHandled}</h2>
                  </div>
                </div>
              )}
              <div className="datetime_selt_sec">
                <div className="datetime_selt_lbl">{this.state.seletedAvilablityId === deliveryId ? "Select Your Delivery Date & Time" : "Choose Self Collection Date"}</div>

                {!currenturl.includes(isCheckout) && (
                  <div>
                    {advancedTimeslotEnable === "1" ? (
                      <OrderAdvancedDatetimeSlot {...this.props} hdrState={this.state} setdateTimeFlg={this.setdateTimeFlg} page="header" />
                    ) : (
                      <OrderdatetimeSlot {...this.props} hdrState={this.state} setdateTimeFlg={this.setdateTimeFlg} />
                    )}
                  </div>
                )}

                <div className="ordrdatetime_error"></div>
              </div>

              <div className="two-button-row">
                <div className="first-btn">
                  <a href="/" onClick={this.gobackPrevStep.bind(this)} className="button disbl_href_action" title="Go Back">
                    Go Back
                  </a>
                </div>
                <div className="second-btn">
                  {/*<input type="button" onClick={this.selectOutlet.bind(this, 1, deliveryId)} className="button button-right delivery_submit" value="Continue" />*/}
                  <input
                    type="button"
                    /* onClick={this.selectOutlet.bind(this, 1, deliveryId)} */
                    onClick={this.setOrderOutletDateTimeData.bind(this)}
                    className="button"
                    value="Continue"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* success popup - end */}

          {/* success popup - Start */}
          <div id="awesome-popup-old" className="white-popup mfp-hide awesome_popup">
            <div className="popup_equalrw">
              <div className="popup_ttsec">
                <div className="innervmid_in">
                  <div className="pop_title poptt_icontop text-center">
                    <img src={iconWin} loading="lazy" />
                    <h2 className="text-uppercase">Awesome</h2>
                    <small>We can Deliver !</small>
                  </div>
                  <div className="awesome_del">
                    <h5>Your Delivery Address :</h5>
                    <h2>{this.state.orderDeliveryAddress}</h2>
                  </div>
                  <div className="btn_sec">
                    <input type="button" onClick={this.gotoProducts.bind(this)} className="button" value="Continue" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* success popup - end */}

          {/* error Popup - start */}
          <div id="outlet-error-popup" className="white-popup mfp-hide popup_sec warning_popup outlet_error_popup">
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="alert_body">
                    <img className="warning-popup-img" src={warningImg} loading="lazy" />
                    <h2 className="text-uppercase">Sorry</h2>
                    <p>{"We can`t Deliver At the Moment!"}</p>
                    <p>Please come back again.</p>
                    <div className="alt_btns">
                      {this.state.seletedAvilablityId === pickupId ? (
                        <a href="/" onClick={this.gobckPkupOutletpopup.bind(this)} className="button button-right popup-modal-dismiss disbl_href_action">
                          change outlet
                        </a>
                      ) : (
                        <a href="/" onClick={this.gobckOutletpopup.bind(this)} className="button button-right popup-modal-dismiss disbl_href_action">
                          change address
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* error Popup - end */}

          {/* Takeaway Popup - Start */}
          <div id="takeaway-popup" className="white-popup mfp-hide popup_sec self_popup">
            <div className="order-body">
              <div className="order-body-header">
                <h2>Self Collection</h2>
                <p>Let us Know your Takeaway outlet</p>
              </div>

              <div className="self_pop_row">
                <div className="self_pop_col self_pop_col_right">
                  <div className="self_pop_item">
                    <div className="self_pop_locbx">
                      {/*<h4>Search Cedele Outlet.</h4>*/}
                      <div className="form-group">
                        <div className="focus-out">
                          <input type="input" placeholder="Search Outlet" className="form-control input-focus" onKeyUp={this.handleKeyPress} />
                          <div className="outlet_error"></div>
                        </div>
                      </div>
                    </div>

                    <div className="self_outlet">
                      <ul className="self_outlet_inner">{this.loadOutletsList()}</ul>

                      <div className="two-button-row">
                        <div className="first-btn">
                          <a className="button disbl_href_action" id="takeaway-continue-link" href="/" onClick={this.gobackOrderType.bind(this)}>
                            Go Back
                          </a>
                        </div>
                        <div className="second-btn">
                          <a className="button disbl_href_action" id="takeaway-continue-link" href="/" onClick={this.selectDatetm.bind(this)}>
                            Continue
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Takeaway Popup - end */}

          {/*  Coming Soon Popup - start */}
          <div id="comingsoon-popup" className="white-popup mfp-hide popup_sec comingsoon_popup">
            <div className="order-body">
              <div className="comingsoon_popup_hea_row">
                <div className="comingsoon_popup_hea_col">
                  <h2>COMING SOON.</h2>
                </div>
              </div>
              <div className="comingsoon_pop_row">
                <p> can you select another availability.</p>
                <a href="/" onClick={this.closepopup.bind(this)} className="button disbl_href_action" title="Go Back">
                  Go Back
                </a>
              </div>
            </div>
          </div>
          {/* Coming Soon Popup - end */}

          {/* Warning Popup - start */}
          <div id="warning-popup" className="white-popup mfp-hide popup_sec warning_popup">
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="alert_header">Warning</div>
                  <div className="alert_body">
                    <img className="warning-popup-img" src={warningplus} loading="lazy" />
                    <p>By switching you are about to clear your cart.</p>
                    <p>Do you wish to proceed ?</p>
                    <div className="alt_btns">
                      <a href="/" className="popup-modal-dismiss button button-left disbl_href_action">
                        No
                      </a>
                      <a href="/" onClick={this.changeAvailability.bind(this)} className="button button-right popup-modal-dismiss disbl_href_action">
                        Yes
                      </a>
                      <input type="hidden" id="warning_avilablity" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Warning Popup - end */}

          <div className="mbl-menu-overly"></div>
        </header>
      </>
    )
  }
}

const mapStateTopProps = (state) => {
  var zonedetailArr = Array()
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set
    }
  }

  var blacksArr = Array()
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set
    }
  }

  var outletsArr = Array()
  if (Object.keys(state.outlets).length > 0) {
    if (state.outlets[0].status === "ok") {
      outletsArr = state.outlets[0].result_set
    }
  }

  var alloutletsArr = Array()
  if (Object.keys(state.alloutlets).length > 0) {
    if (state.alloutlets[0].status === "ok") {
      alloutletsArr = state.alloutlets[0].result_set
    }
  }

  var secondarydataArr = Array()
  if (Object.keys(state.secondaryaddress).length > 0) {
    if (state.secondaryaddress[0].status === "ok") {
      secondarydataArr = state.secondaryaddress[0].result_set
    }
  }

  return {
    globalsettings: state.settings,
    staticblack: blacksArr,
    zonedetails: zonedetailArr,
    outletslist: outletsArr,
    alloutletslist: alloutletsArr,
    logindata: state.login,
    fblogin: state.fblogin,
    googlelogin: state.googlelogin,
    forgetpassword: state.forgetpassword,
    registration: state.registration,
    menudata: state.menudata,
    secondaryaddresslist: secondarydataArr,
    changepassword: state.changepassword,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS })
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK })
    },
    getZoneDetail: (outletId, zoneId) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId })
    },
    getPickupOutlets: (coordinate) => {
      dispatch({ type: GET_PICKUP_OUTLETS, coordinate })
    },
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability })
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL })
    },
    getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload })
    },
    getFbLoginData: (formPayload) => {
      dispatch({ type: GET_FBLOGINDATA, formPayload })
    },
    getGoogleLoginData: (formPayload) => {
      dispatch({ type: GET_GOOGLELOGINDATA, formPayload })
    },
    getRegistration: (formPayload) => {
      dispatch({ type: GET_REGISTRATION, formPayload })
    },
    getForgetPassword: (formPayload) => {
      dispatch({ type: GET_FORGET_PASSWORD, formPayload })
    },
    getChangePassword: (formPayload) => {
      dispatch({ type: GET_CHANGEPASSWORD, formPayload })
    },
    getMenuData: (menuslug) => {
      dispatch({ type: GET_MENUDATA, menuslug })
    },
    getSecondaryAddress: () => {
      dispatch({ type: GET_ALLUSERSECADDRDATA })
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Header))
