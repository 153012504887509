/* eslint-disable */

import React from "react";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { format, add } from "date-fns";
import premium from "../../common/images/premium-badge.png";
import gold from "../../common/images/premiem-table.png";
import bronze from "../../common/images/standard-table.png";
var Parser = require("html-react-parser");
class Membership extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reward_point_count: 0,
      voucher_point_count: 0,
      goingtoexpiry: 0,
      expiry_points_text: "",
      voucher_points_text: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({
            reward_point_count:
              nextProps.activitycount[0].result_set.reward_ponits,
            voucher_point_count:
              nextProps.activitycount[0].result_set.voucher_points,
            voucher_points_text:
              nextProps.activitycount[0].result_set.voucher_points_text,
            goingtoexpiry: nextProps.activitycount[0].result_set.expiry_points,
            expiry_points_text:
              nextProps.activitycount[0].result_set.expiry_points_text,
          });
        }
      }
    }
  }

  render() {
    const todayDate = format(
      add(new Date(), {
        days: 180,
      }),
      "yyyy-MM-dd"
    );
    return (
      <div className="checkout-profile-details myaccount-pd">
        <div className="ch-pro-title">
          <div className="ch-pro-title-lhs">
            <img
              src={(() => {
                if (this.props.customer_membership_type === "Normal") {
                  return bronze;
                } else if (this.props.customer_membership_type === "Silver") {
                  return gold;
                } else if (this.props.customer_membership_type === "Gold") {
                  return premium;
                }
              })()}
              alt={this.props.membership_displayname}
            />{" "}
          </div>
          <div className="ch-pro-title-rhs">
            <span>Welcome</span>

            {this.props.firstname !== "" &&
            typeof this.props.firstname !== undefined &&
            typeof this.props.firstname !== "undefined" ? (
              <h4>
                {this.props.firstname}{" "}
                {this.props.lastname !== "" &&
                  typeof this.props.lastname !== undefined &&
                  typeof this.props.lastname !== "undefined" &&
                  this.props.lastname}
              </h4>
            ) : (
              <h4>
                {" "}
                {cookie.load("UserFname")}{" "}
                {cookie.load("UserLname") !== "" &&
                typeof cookie.load("UserLname") !== undefined &&
                typeof cookie.load("UserLname") !== "undefined"
                  ? cookie.load("UserLname")
                  : ""}
              </h4>
            )}
          </div>
        </div>
        <div className="ch-pro-points textcenter">
          {((parseFloat(this.state.goingtoexpiry) > 0 &&
            this.state.expiry_points_text != "") ||
            (parseFloat(this.state.voucher_point_count) > 0 &&
              this.state.voucher_points_text != "")) && (
            <span>You have earned </span>
          )}
          {parseFloat(this.state.goingtoexpiry) > 0 &&
            this.state.expiry_points_text !== "" &&
            this.state.expiry_points_text !== null && (
              <p className="earned_points_divcls">
                {Parser(this.state.expiry_points_text)}
              </p>
            )}
          {parseFloat(this.state.voucher_point_count) > 0 &&
            this.state.voucher_points_text != "" && (
              <p className="voucher_points_divcls">
                {this.state.voucher_points_text}
              </p>
            )}

          {this.props.membership_spent_msg}
          <br />
          <Link to={"/membership"}>View Member Benefits</Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activitycount: state.activitycount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
Membership.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};
export default connect(mapStateToProps, mapDispatchToProps)(Membership);
