/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { connect } from "react-redux";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import FeaturedProducts from "./HomeSubCompo/FeaturedProducts";
import SameDayDeliveryProducts from "./HomeSubCompo/SameDayDeliveryProducts";

import HomeBanner from "./HomeSubCompo/HomeBanner";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import smartPhone from "../../common/images/smart-phone.png";

import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";

import {
  projectTitle,
  appId,
  apiUrl,
  deliveryId,
  pickupId,
  mediaUrl,
  proFolderUrl,
  apiUrlV2,
} from "../Helpers/Config";
import { GET_NORMAL_POPUP, GET_MENU_NAVIGATION } from "../../actions";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
  stripslashes,
  removePromoCkValue,
  showAlert,
} from "../Helpers/SettingHelper";
import newletterImg from "../../common/images/AWC0283.jpg";
import cusimg from "../../common/images/customer-img.png";
import corimg from "../../common/images/corporate-img.png";
import noimg from "../../common/images/noimg-470x240.jpg";
import maintanceImg from "../../common/images/maintance.png";

var Parser = require("html-react-parser");
var qs = require("qs");
const settingsGallery = {
  items: 5,
  autoplay: true,
  loop: true,
  nav: true,
  margin: 15,
};

const promoGallery = {
  items: 3,
  autoplay: true,
  loop: true,
  nav: true,
  margin: 25,
  responsive: {
    0: {
      items: 2,
    },
    680: {
      items: 2,
      margin: 10,
    },
    900: {
      items: 2,
      margin: 20,
    },
    1080: {
      items: 3,
    },
  },
};
const reviewGallery = {
  items: 3,
  autoplay: false,
  loop: false,
  nav: true,
  margin: 80,
  responsive: {
    0: {
      items: 1,
      center: false,
    },
    680: {
      items: 2,
      margin: 20,
    },
    900: {
      items: 2,
      margin: 30,
    },
    1080: {
      items: 3,
      margin: 30,
    },
  },
};
class Home extends Component {
  constructor(props) {
    super(props);

    var normalpopupFlg = "initial";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes" ||
      cookie.load("promoPopupTrigger") === "Yes"
    ) {
      normalpopupFlg = "trigger";
    }

    this.state = {
      users: [],
      defaultAvilablityId:
        cookie.load("defaultAvilablityId") !== "" &&
        typeof cookie.load("defaultAvilablityId") !== undefined &&
        typeof cookie.load("defaultAvilablityId") !== "undefined"
          ? cookie.load("defaultAvilablityId")
          : "",
      nextavail: "",
      cartTriggerFlg: "No",
      staticblacks: [],

      normalpopuplist: [],
      normalpopupdata: [],
      normalpopup_status: "no",
      normalpopup_flg: normalpopupFlg,
      normalpopupstatus: "initial",
      promo_mail_id: "",
      displayType: "all",
      pickup_outlet: [],
      promotions: [],
      hometopsection: "",
      ourstory: "",
      ourstoryimage: "",
      homepromotion: "",
      homepromoslider: "",
      reviewProductList: [],
      displyreviewProduct: "",
      samedaydelivery: "",
      bastseller: "",
      homemember: "",
      whatsHappening: "",
      displayWhatsHappening: "",
      promomail_error: "",
      maintanace_popup_hide: false,
    };
  }
  
  componentDidMount() {
    setTimeout(() => {
      this.props.getMenuNavigation();
      this.props.getNormalPopup();
      this.loadReviewProducts();
      this.loadWhatsHappening();
    }, window.innerWidth <= 1080 ? 6000 : 0)

    if (
      cookie.load("triggerAvlPop") != "" &&
      cookie.load("triggerAvlPop") != undefined
    ) {
      var availabilityId = cookie.load("triggerAvlPop");
      cookie.remove("triggerAvlPop", { path: "/" });
      this.chooseAvailability(availabilityId);
    }

    if (cookie.load("orderPopuptrigger") === "Yes") {
      cookie.remove("orderPopuptrigger", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
    }

    if (
      (cookie.load("subscribepopup") === "" ||
        typeof cookie.load("subscribepopup") === undefined ||
        typeof cookie.load("subscribepopup") === "undefined") &&
      cookie.load("site_maintenance_verify") === "1"
    ) {
      $.magnificPopup.open({
        items: {
          src: "#promo-check-popup",
        },
        type: "inline",
        showCloseBtn: false,
        midClick: true,
        closeOnBgClick: false,
      });
    }

    if (cookie.load("loginpopupTrigger") === "Yes") {
      cookie.save("loginpopupTrigger", "fromcheckout", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    var RctThis = this;
    $("body")
      .off("click", ".act_order_popup")
      .on("click", ".act_order_popup", function (e) {
        e.preventDefault();

        var odrType = $(this).attr("data-acturl");
        var availabilityId = "";
        if (odrType === "delivery") {
          availabilityId = deliveryId;
        } else if (odrType === "pickup") {
          availabilityId = pickupId;
        }

        if (availabilityId !== "") {
          RctThis.chooseAvailability(availabilityId);
        } else if (availabilityId === "" && odrType === "ordernow") {
          $.magnificPopup.open({
            items: {
              src: "#order-popup",
            },
            type: "inline",
          });
        }
      });
    $("body")
      .off("click", ".sign_up_popup")
      .on("click", ".sign_up_popup", function (e) {
        e.preventDefault();
        $.magnificPopup.open({
          items: {
            src: "#signup-popup",
          },
          type: "inline",
        });
      });

    $("body")
      .off("click", ".home-signup")
      .on("click", ".home-signup", function (e) {
        e.preventDefault();
        if (
          cookie.load("UserId") !== "" &&
          typeof cookie.load("UserId") !== undefined &&
          typeof cookie.load("UserId") !== "undefined"
        ) {
          RctThis.props.history.push("/myaccount");
        } else {
          $.magnificPopup.open({
            items: {
              src: "#signup-popup",
            },
            type: "inline",
          });
        }
      });
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.staticblacks) {
      var hometopsection = "";
      var ourstory = "";
      var ourstoryimage = "";
      var homepromotion = "";
      var homepromoslider = "";
      var samedaydelivery = "";
      var bastseller = "";
      var homemember = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data) => {
          if (data.staticblocks_slug === "home-top-section") {
            hometopsection = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "our-story") {
            ourstory = data.staticblocks_description;
            ourstoryimage = data.gallery_images[0];
            return "";
          }
          if (data.staticblocks_slug === "home-promotion") {
            homepromotion = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-promo-slider") {
            homepromoslider = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "same-day-delivery") {
            samedaydelivery = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "best-seller") {
            bastseller = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-member") {
            homemember = data.staticblocks_description;
            return "";
          }
        });
      }
      hometopsection =
        hometopsection !== "" && hometopsection !== null
          ? Parser(hometopsection)
          : hometopsection;
      ourstory =
        ourstory !== "" && ourstory !== null ? Parser(ourstory) : ourstory;
      homepromotion =
        homepromotion !== "" && homepromotion !== null
          ? Parser(homepromotion)
          : homepromotion;

      homepromoslider =
        homepromoslider !== "" && homepromoslider !== null
          ? Parser(homepromoslider)
          : homepromoslider;

      samedaydelivery =
        samedaydelivery !== "" && samedaydelivery !== null
          ? Parser(samedaydelivery)
          : samedaydelivery;

      bastseller =
        bastseller !== "" && bastseller !== null
          ? Parser(bastseller)
          : bastseller;

      homemember =
        homemember !== "" && homemember !== null
          ? Parser(homemember)
          : homemember;

      this.setState(
        {
          staticblacks: PropsData.staticblack,
          hometopsection: hometopsection,
          ourstory: ourstory,
          ourstoryimage: ourstoryimage,
          homepromotion: homepromotion,
          homepromoslider: homepromoslider,
          samedaydelivery: samedaydelivery,
          bastseller: bastseller,
          homemember: homemember,
        },
        function () {
          if (
            cookie.load("UserId") !== "" &&
            typeof cookie.load("UserId") !== undefined &&
            typeof cookie.load("UserId") !== "undefined"
          ) {
            $(".premium-member-action").hide();
          }
        }
      );
    }

    if (
      PropsData.normalpopuplist !== this.state.normalpopuplist &&
      this.state.normalpopup_flg === "initial"
    ) {
      var normalpopupMain = PropsData.normalpopuplist,
        normalpopupdata = [],
        normalpopupStatus = "no";
      if (normalpopupMain !== "") {
        if (Object.keys(normalpopupMain).length > 0) {
          var normalpopupIds = cookie.load("normalpopupIds");
          var normalpopupIdArr =
            normalpopupIds != "" && normalpopupIds != undefined
              ? normalpopupIds.split("~~")
              : Array();
          if (
            $.inArray(normalpopupMain[0].normalpopup_id, normalpopupIdArr) ===
            -1
          ) {
            normalpopupdata = normalpopupMain[0];
            normalpopupStatus = "yes";
          }
          this.setState({
            normalpopuplist: normalpopupMain,
            normalpopupdata: normalpopupdata,
            normalpopup_status: normalpopupStatus,
            normalpopup_flg: "trigger",
          });
        }
      }
    }

    if (PropsData.normalpopupstatus !== this.state.normalpopupstatus) {
      this.setState({ normalpopupstatus: PropsData.normalpopupstatus });
    }

    if (PropsData.globalsettings !== this.props.globalsettings) {
      var settingsArrList = PropsData.globalsettings[0];
      if (Object.keys(settingsArrList).length > 0) {
        if (settingsArrList.status === "ok") {
          let checkMaintananceMode =
            settingsArrList.result_set.client_maintenance_mode;
          this.setState({ maintanace_mode: checkMaintananceMode });
          if (checkMaintananceMode === "1") {
            if (cookie.load("site_maintenance_verify") !== "1") {
              $.magnificPopup.close();
            }
            cookie.save("site_maintenance_popup_open", "1", { path: "/" });
          }
        }
      }
    }
  }

  loadWhatsHappening() {
    axios.get(apiUrl + "cms/whats_happening?app_id=" + appId).then((res) => {
      if (res.data.status === "ok") {
        this.displayWhatsHappening(
          res.data.result_set,
          res.data.common.image_source
        );
      }
    });
  }
  displayWhatsHappening(WhatsHappening, image_source) {
    var displayWhatsHappening = "";
    if (WhatsHappening.length > 0) {
      displayWhatsHappening = WhatsHappening[0].gallery_image.map(
        (item, index) => {
          return (
            <div className="promogallery" key={index}>
              <div className="progallery-img">
                <a
                  href={
                    item.gallery_link !== "" ? "/" + item.gallery_link : void 0
                  }
                >
                  <img
                    src={image_source + item.gallery_image}
                    alt={item.gallery_title}
                    loading="lazy"
                  />{" "}
                </a>
              </div>
            </div>
          );
        }
      );
    }

    this.setState({ displayWhatsHappening: displayWhatsHappening });
  }

  chooseAvailability(availability) {
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    } else if (field === "pickup_outlet" && value !== "") {
      this.setState({ pickup_outlet: value });
    }
  };

  triggerNormalPopup(trgType) {
    var otherPageActTrigger = "no";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes"
    ) {
      otherPageActTrigger = "yes";
    }

    if (
      trgType === "loading" &&
      otherPageActTrigger === "no" &&
      this.state.normalpopup_status === "yes" &&
      Object.keys(this.state.normalpopupdata).length > 0 &&
      cookie.load("promoPopupTrigger") !== "Yes"
    ) {
      var normalpopupIds = cookie.load("normalpopupIds");
      var normalpopupIdsNew =
        normalpopupIds != "" && normalpopupIds != undefined
          ? normalpopupIds + "~~" + this.state.normalpopupdata.normalpopup_id
          : this.state.normalpopupdata.normalpopup_id;
      var normalpopupIdArr = [];
      normalpopupIdArr["normalpopupids"] = normalpopupIdsNew;
      cookie.save("normalpopupIds", normalpopupIdsNew, { path: "/" });
      var $_this_rec = this;
      $.magnificPopup.open({
        items: {
          src: "#normal-popup",
        },
        type: "inline",
        midClick: true,
        closeOnBgClick: false,
        callbacks: {
          close: function () {
            $_this_rec.normalPopupUpdate();
          },
        },
      });
    }

    if (
      (cookie.load("promoPopupTrigger") === "Yes" ||
        (otherPageActTrigger === "no" &&
          this.state.normalpopup_status === "no" &&
          Object.keys(this.state.normalpopupdata).length === 0)) &&
      cookie.load("mailpopopuptrg") !== "yes"
    ) {
      cookie.save("mailpopopuptrg", "yes", { path: "/" });
      cookie.remove("promoPopupTrigger", { path: "/" });
    }
  }

  normalPopupUpdate() {
    if (cookie.load("mailpopopuptrg") !== "yes") {
      this.props.history.push("/refpage/promopopup");
    }
  }

  handleEmailChange(event) {
    this.setState({ promo_mail_id: event.target.value, promomail_error: "" });
  }

  checkEmail() {
    if (this.state.promo_mail_id !== "") {
      var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
      if (!pattern.test(this.state.promo_mail_id)) {
        this.setState({ promomail_error: "Please enter valid email" });
      } else {
        this.setState({ promomail_error: "" });
        showLoader("f-newletter-form", "class");
        var postObject = {
          app_id: appId,
          email: this.state.promo_mail_id,
        };
        axios
          .post(
            apiUrl + "newsletter/subscription_new",
            qs.stringify(postObject)
          )
          .then((res) => {
            hideLoader("f-newletter-form", "class");
            if ($("#dont_show:checked").length > 0) {
              cookie.save("subscribepopup", "Yes", { path: "/" });
            }

            if (res.data.status === "ok") {
              this.setState({ newsLetterEmail: "" });
              var magnfPopup = $.magnificPopup.instance;
              showAlert("Success", "Newsletter Subscribed", magnfPopup);
            } else {
              showAlert("Alert", res.data.message, magnfPopup);
            }
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          });
      }
    } else {
      this.setState({ promomail_error: "This field is required" });
    }
  }

  sendPromoMailFun() {
    var promoEmail = this.state.promo_mail_id;

    var mailErrorTxt = "";
    if (promoEmail === "") {
      mailErrorTxt = "Email Address is required.";
    } else if (!validateEmailFun(promoEmail)) {
      mailErrorTxt = "Invalide Email Address";
    }

    if (mailErrorTxt !== "") {
      this.setState({ promomail_error: mailErrorTxt });
      return false;
    } else {
      showLoader("promomailpopup-cls", "class");

      var qs = require("qs");
      var postObject = {
        app_id: appId,
        email_address: promoEmail,
      };

      axios
        .post(apiUrl + "promotion/user_promotion", qs.stringify(postObject))
        .then((response) => {
          hideLoader("promomailpopup-cls", "class");
          $.magnificPopup.close();
          if (response.data.status === "ok") {
            showCustomAlert(
              "success",
              "You are now a Member of " +
                projectTitle +
                ". Please check your email for more information."
            );
          } else {
            var errMsgtxt =
              response.data.message !== ""
                ? response.data.message
                : "Sorry! You didn`t have promo code.";
            showCustomAlert("error", errMsgtxt);
          }
          return false;
        });
    }

    return false;
  }
  loadReviewProducts() {
    axios
      .get(apiUrl + "review/review_all_product?app_id=" + appId)
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState(
            { reviewProductList: res.data.result_set },
            function () {
              this.displayReviewProduct();
            }
          );
        }
      });
  }
  displayReviewProduct() {
    var displayReviewProduct = "";
    if (this.state.reviewProductList.length > 0) {
      displayReviewProduct = (
        <div className="review-gallery textcenter">
          <h3>
            Product <strong>Reviews</strong>
          </h3>
          <OwlCarousel options={reviewGallery}>
            {this.state.reviewProductList.map((item, index) => {
              var prodivId = "proIndexF-" + item.product_primary_id;
              var comboProId = "comboPro-" + item.product_slug;
              return (
                <div key={index} id={prodivId}>
                  <div className="rg-parent">
                    <div className="rg-img">
                      {item.product_thumbnail !== "" ? (
                        <img
                          loading="lazy"
                          src={
                            proFolderUrl +
                            "main-image/" +
                            item.product_thumbnail
                          }
                        />
                      ) : (
                        <img src={noimg} />
                      )}
                    </div>
                    <div className="rg-desc">
                      <h4>
                        {" "}
                        {stripslashes(item.product_name)}
                        {item.product_alias !== ""
                          ? stripslashes(item.product_alias)
                          : stripslashes(item.product_name)}
                      </h4>
                      <div className="rating-star">
                        {this.loadRatingstar(item.review_rating)}
                      </div>
                      <div className="rg-content">
                        <p>{stripslashes(item.review_order_description)}</p>
                      </div>
                      <div className="rg-reviewer">
                        <h5>
                          {item.customer_first_name}
                          {item.customer_last_name !== "" &&
                            item.customer_last_name !== null &&
                            " " + item.customer_last_name}
                        </h5>
                      </div>
                      <div className="rg-cart">
                        <div
                          className="addcart_row addcart_done_maindiv"
                          style={{ display: "none" }}
                        >
                          <div className="addcart-inner-flex">
                            <div className="qty_bx">
                              <span
                                className="qty_minus"
                                onClick={this.proQtyAction.bind(
                                  this,
                                  item.product_primary_id,
                                  "decr"
                                )}
                              >
                                -
                              </span>
                              <input
                                type="text"
                                className="proqty_input"
                                readOnly
                                value="1"
                              />
                              <span
                                className="qty_plus"
                                onClick={this.proQtyAction.bind(
                                  this,
                                  item.product_primary_id,
                                  "incr"
                                )}
                              >
                                +
                              </span>
                            </div>
                            <button
                              className="btn btn_black order_done"
                              onClick={this.addToCartSimple.bind(
                                this,
                                item,
                                "done"
                              )}
                            >
                              Done
                            </button>
                          </div>
                        </div>

                        {item.product_stock > 0 ||
                        item.product_stock === null ? (
                          item.product_type === "1" ? (
                            <a
                              className="button order_nowdiv smiple_product_lk disbl_href_action"
                              href="/"
                              onClick={this.addToCartSimple.bind(
                                this,
                                item,
                                "initial"
                              )}
                            >
                              Add To Cart
                            </a>
                          ) : (
                            <a
                              href="/"
                              onClick={this.viewProDetail.bind(this, item)}
                              title="Product Details"
                              id={comboProId}
                              className="button order_nowdiv compo_product_lk"
                            >
                              Add to Cart
                            </a>
                          )
                        ) : (
                          <a
                            className="button disabled disbl_href_action"
                            href="/"
                          >
                            Sold Out
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      );
    }

    this.setState({ displayReviewProduct: displayReviewProduct });
  }

  loadRatingstar(points) {
    if (points !== "" && points !== null) {
      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            (parseInt(splitrating[1]) === 5 || parseInt(splitrating[1]) === 50)
          ) {
            return (
              <i
                className="fa fa-star-half-o"
                aria-hidden="true"
                key={index}
              ></i>
            );
          } else {
            return (
              <i className="fa fa-star" aria-hidden="true" key={index}></i>
            );
          }
        }
      });
    }
  }
  viewProDetail(productDetail, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    /*  if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) { */

    var productSlug = productDetail.product_slug;
    if (productSlug !== "") {
      this.props.history.push(
        "/menu/" +
          productDetail.pro_cate_slug +
          "/" +
          productDetail.pro_subcate_slug +
          "/" +
          productSlug
      );
    }
    /*  } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    } */
    return false;
  }
  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndexF-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndexF-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }
  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();

    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#proIndexF-" + IndexFlg).addClass("active");
        $("#proIndexF-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndexF-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
      } else {
        showLoader("proIndexF-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var proqtyQty = $("#proIndexF-" + IndexFlg)
          .find(".proqty_input")
          .val();

        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
        };

        axios
          .post(apiUrlV2 + "cart/simpleCartInsert", qs.stringify(postObject))
          .then((res) => {
            $("#proIndexF-" + IndexFlg).removeClass("active");
            hideLoader("proIndexF-" + IndexFlg, "Idtext");
            $("#proIndexF-" + IndexFlg)
              .find(".addcart_done_maindiv")
              .hide();
            $("#proIndexF-" + IndexFlg)
              .find(".smiple_product_lk")
              .show();
            if (res.data.status === "ok") {
              this.sateValChange("cartflg", "yes");
              removePromoCkValue();
              this.handleShowAlertFun(
                "success",
                "Great choice! Item added to your cart."
              );
              return false;
            } else if (res.data.status === "error") {
              var errMsgtxt =
                res.data.message !== ""
                  ? res.data.message
                  : "Sorry! Products can`t add your cart.";
              showCustomAlert("error", errMsgtxt);
            }
          });
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
  }
  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  closepromoPopup() {
    if ($("#dont_show:checked").length > 0) {
      cookie.save("subscribepopup", "Yes", { path: "/" });
    }
    $.magnificPopup.close();
  }

  serviceMaintanance() {
    if (this.state.client_password_maintain !== "") {
      this.setState({ client_password_maintain_error: "" });

      var postObject = {
        app_id: appId,
        client_password: this.state.client_password_maintain,
      };

      var qs = require("qs");

      axios
        .post(
          apiUrl + "settings/checking_maintenance",
          qs.stringify(postObject)
        )
        .then((response) => {
          if (response.data.status === "ok") {
            // $(".maintance-popup").modal("hide");
            //$(".maintance-popup").addClass("mfp-hide");

            cookie.save("site_maintenance_popup_open", "0", { path: "/" });
            cookie.save("site_maintenance_verify", "1", { path: "/" });
            this.setState({ maintanace_popup_hide: true }, function () {
              $.magnificPopup.open({
                items: {
                  src: "#promo-check-popup",
                },
                type: "inline",
                showCloseBtn: false,
                midClick: true,
                closeOnBgClick: false,
              });
            });
          } else {
            //  $(".maintance-popup").modal("show");
            this.setState({ client_password_maintain_error: "2" });
            cookie.save("site_maintenance_popup_open", "1", { path: "/" });
            cookie.save("site_maintenance_verify", "0", { path: "/" });
          }
        });
    } else {
      cookie.save("site_maintenance_popup_open", "1", { path: "/" });
      cookie.save("site_maintenance_verify", "0", { path: "/" });
      this.setState({ client_password_maintain_error: "1" });
    }
  }

  handleChangePassword(event) {
    if (event.target.name === "client_password_maintain") {
      this.setState({ client_password_maintain: event.target.value });
    }
  }

  render() {
    let settingsArr = this.props.globalsettings;
    var showPromoPopup = "",
      showNormalPopup = "";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        if (settingsArr[0].result_set.client_promocode_enable === "1") {
          showNormalPopup =
            settingsArr[0].result_set.client_promo_code_normal_popup_enable ===
            "1"
              ? "yes"
              : "";
          showPromoPopup =
            settingsArr[0].result_set.client_promocode_options === "1"
              ? "yes"
              : "";
        }
      }
    }

    var site_maintenance_verify =
      cookie.load("site_maintenance_verify") !== "" &&
      cookie.load("site_maintenance_verify") !== "undefined" &&
      cookie.load("site_maintenance_verify") !== undefined
        ? cookie.load("site_maintenance_verify")
        : "";

    return (
      <div className="home-main-div">
        {/* Header section */}
        <Header
          homePageState={this.state}
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />

        {/* Home banner section */}
        <section className="main-banner">
          <HomeBanner />
        </section>

        <div className="gallery">
          <div className="container">
            {this.state.samedaydelivery}
            <SameDayDeliveryProducts
              {...this.props}
              sateValChange={this.sateValChange}
            />
          </div>
        </div>

        <div className="content-main">
          <div className="home-product-list">
            {this.state.bastseller}
            <FeaturedProducts
              {...this.props}
              sateValChange={this.sateValChange}
            />
          </div>
          {this.state.ourstory !== "" && (
            <div className="home-about">
              <div className="container">
                {this.state.ourstory}
                <div className="home-about-rhs">
                  <img
                    loading="lazy"
                    src={mediaUrl + "staticimages/" + this.state.ourstoryimage}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="story-middle">
            <div className="container">
              {/*this.state.homepromotion*/}
              {/* this.state.displayReviewProduct */}
              {this.state.displayWhatsHappening !== "" && (
                <div className="story-middle-slider">
                  <OwlCarousel options={promoGallery}>
                    {this.state.displayWhatsHappening}
                  </OwlCarousel>
                </div>
              )}
              {this.state.homemember}
            </div>
          </div>
        </div>
        {/* Footer section */}
        <Footer />

        {/*Normal popup Start*/}
        <div
          id="normal-popup"
          className="white-popup mfp-hide popup_sec normal-popup"
        >
          <div className="normal_popup_alert">
            {this.state.normalpopup_status === "yes" &&
              Object.keys(this.state.normalpopupdata).length > 0 && (
                <div className="normal_popup_cont">
                  {Parser(this.state.normalpopupdata.normalpopup_description)}
                </div>
              )}
          </div>
        </div>
        {/*Normal popup - End*/}

        {/*  Serive Maintanance Popup - start */}
        {this.state.maintanace_mode === "1" &&
          cookie.load("site_maintenance_popup_open") === "1" &&
          (site_maintenance_verify === "" ||
            site_maintenance_verify === "0") && (
            <>
              {this.state.maintanace_popup_hide === false && (
                <div class="mfp-bg mfp-ready"></div>
              )}
              <div className="maintance-popup-main">
                <div
                  className={
                    this.state.maintanace_mode === "1"
                      ? "maintance-popup"
                      : "maintance-popup mfp-hide"
                  }
                  data-target="#myModal"
                  data-toggle="modal"
                  data-backdrop="static"
                  data-keyboard="false"
                >
                  <div className="maintance-popup-container">
                    <div className="mtnce-pup-imager">
                      <img className="mtnce-pup-img" src={maintanceImg} loading="lazy" />
                    </div>
                    <div className="maintance-popup-contant">
                      <h3>site is under maintainance</h3>
                      <p>
                        {" "}
                        Please enter admin password to browse through website
                      </p>

                      <div className="maintance-popup-input">
                        <input
                          type="password"
                          placeholder="Enter Admin Password"
                          name="client_password_maintain"
                          value={this.state.client_password_maintain}
                          onChange={this.handleChangePassword.bind(this)}
                        />
                        <a
                          onClick={this.serviceMaintanance.bind(this)}
                          className="button disbl_href_action"
                          title="Continue"
                        >
                          Continue
                        </a>
                      </div>
                      {this.state.client_password_maintain_error === "1" && (
                        <span className="error">This field is required. </span>
                      )}
                      {this.state.client_password_maintain_error === "2" && (
                        <span className="error">Password is incorrect. </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        {/* Serive Maintanance - end */}

        {/*Promo Check Email popup Start*/}
        <div
          id="promo-check-popup"
          className="white-popup mfp-hide popup_sec promo-check-popup"
        >
          <a
            href={void 0}
            className="image-close"
            onClick={this.closepromoPopup.bind(this)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </a>
          <div className="promopopup-maindiv">
            <div className="promopopup-lhs">
              <div className="frm-action-div">
                <div className="frm-top-title">
                  <h3>Subscribe to our Mailing List!</h3>
                </div>

                <div className="frm-inputbtn-div">
                  <div className="form-group">
                    <div className="focus-out">
                      <input
                        type="email"
                        placeholder="Email Address"
                        className="form-control input-focus"
                        value={this.state.promo_mail_id}
                        onChange={this.handleEmailChange.bind(this)}
                      />
                      {this.state.promomail_error !== "" && (
                        <span className="error">
                          {this.state.promomail_error}
                        </span>
                      )}
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={this.checkEmail.bind(this)}
                    className="button promomailpopup-cls"
                  >
                    Subscribe
                  </button>
                </div>

                <div className="frm-bottom-text">
                  <p>
                    Join our Mailing List for the latest deals and exclusive
                    promotions !
                  </p>
                  <div className="subscripe-dont-show">
                    <input
                      type="checkbox"
                      name="dont_show"
                      id="dont_show"
                      autoComplete="off"
                    />
                    <label htmlFor="dont_show">Do not show again</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="promopopup-rhs">
              <img src={newletterImg} loading="lazy" />
            </div>
          </div>
        </div>
        {/*Promo Check Email popup - End*/}

        {this.state.normalpopupstatus !== "initial" &&
          (showNormalPopup === "yes" || showPromoPopup === "yes") &&
          this.triggerNormalPopup("loading")}

        <div
          id="vouchergift-popup"
          className="mfp-hide popup_sec vouchergift-popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3>IM PURCHASING THIS</h3>
            </div>
            <div className="full-login-new-body">
              <div className="popup-footer voucher-popup-body">
                <Tabs>
                  <TabList>
                    <Tab>For Gift</Tab>
                    <Tab>For Me</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="voucher-popup-inner">
                      <h2>GIFT RECEIVER'S INFORMATION</h2>
                      <div className="form-group">
                        <div className="focus-out">
                          <label>Name</label>
                          <input
                            type="text"
                            className="form-control input-focus"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="focus-out">
                          <label>Enter Mobile Number</label>
                          <input
                            type="tel"
                            className="form-control input-focus"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="focus-out">
                          <label>Email Address</label>
                          <input
                            type="text"
                            className="form-control input-focus"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="focus-out">
                          <label>Message</label>
                          <textarea
                            type="text"
                            className="form-control input-focus"
                          />
                        </div>
                      </div>

                      <div className="howtouse-gift-vouchar">
                        <img src={smartPhone} loading="lazy" />
                        <h3>How to use</h3>
                        <p>
                          After purchase your recipient can get email
                          notificationand they can use above email address to
                          sign up. Vouchers will be available within their my
                          account{" "}
                        </p>
                      </div>

                      <div className="form-group gift-group">
                        <button type="button" className="button">
                          Add To Cart
                        </button>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="voucher-popup-inner">
                      <button type="button" className="button">
                        Add To Cart
                      </button>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var normalPopupStatus = "initial";
  var normalPopupArr = Array();
  if (Object.keys(state.normalpopuplist).length > 0) {
    if (state.normalpopuplist[0].status === "ok") {
      normalPopupArr = state.normalpopuplist[0].result_set;
      normalPopupStatus = "ok";
    } else {
      normalPopupStatus = "error";
    }
  }

  var tempArr = Array();
  var navigateRst = Array();
  var navigateCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
      }
    }
  }

  return {
    globalsettings: state.settings,
    categorylist: state.categorylist,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    staticblack: blacksArr,
    normalpopuplist: normalPopupArr,
    normalpopupstatus: normalPopupStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNormalPopup: () => {
      dispatch({ type: GET_NORMAL_POPUP });
    },
    getMenuNavigation: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
