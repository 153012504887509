/* eslint-disable */
/* if (location.protocol !== "https:") {
  var locationULR = location.href.replace("www.", "");
  location.href = locationULR.replace("http://", "https://");
} */ /*  else if (location.protocol === "https:") {
//   var locationULR = location.href;
//   if (locationULR.indexOf("www.") < 0) {
//     location.href = "https://www." + locationULR.replace("https://", "");
//   }
// } */
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";
import loadable from "@loadable/component";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";
import "./common/css/owl.carousel.css";

import Home from "./components/Home/Home";
const Products = loadable(() => import("./components/Products/Products"));
const ProductDetail = loadable(() =>
  import("./components/Products/ProductDetail")
);
const FavouriteProducts = loadable(() =>
  import("./components/Products/FavouriteProducts")
);
const Deliverydetails = loadable(() =>
  import("./components/Checkout/Deliverydetails")
);
const Checkout = loadable(() => import("./components/Checkout/Checkout"));
const Thankyou = loadable(() => import("./components/Checkout/Thankyou"));
const Pages = loadable(() => import("./components/Pages/Pages"));
const ContactUs = loadable(() => import("./components/Pages/ContactUs"));
const Faq = loadable(() => import("./components/Pages/Faq"));
const Myaccount = loadable(() => import("./components/Myaccount/Myaccount"));
const Orders = loadable(() => import("./components/Myaccount/Orders"));
const Mypromotions = loadable(() =>
  import("./components/Myaccount/Mypromotions")
);
const Myvoucher = loadable(() => import("./components/Myaccount/Myvoucher"));
const Rewards = loadable(() => import("./components/Myaccount/Rewards"));
const Notes = loadable(() => import("./components/Myaccount/Notes"));
const Account = loadable(() => import("./components/Account/Account"));
const Resetpassword = loadable(() =>
  import("./components/Account/Resetpassword")
);
const Logout = loadable(() => import("./components/Myaccount/Logout"));
const Refpage = loadable(() => import("./components/Layout/Refpage"));
const Reviews = loadable(() => import("./components/Pages/Reviews"));
const Outlets = loadable(() => import("./components/Pages/Outlets"));
const Completedelivery = loadable(() =>
  import("./components/Pages/Completedelivery")
);
const Page404 = loadable(() => import("./Page404"));
// import Careers from "./components/Pages/Careers";
// import WorkShop from "./components/Pages/WorkShop";
const Corporate = loadable(() => import("./components/Pages/Corporate"));
const BrandStory = loadable(() => import("./components/Pages/BrandStory"));
const BrandValue = loadable(() => import("./components/Pages/BrandValue"));
const BrandConcept = loadable(() => import("./components/Pages/BrandConcept"));
const Membership = loadable(() => import("./components/Pages/Membership"));
const Reviewproducts = loadable(() =>
  import("./components/Products/Reviewproducts")
);
const ReferFriends = loadable(() =>
  import("./components/Myaccount/ReferFriends")
);
const Referral = loadable(() => import("./components/Myaccount/Referral"));
const Rfcode = loadable(() => import("./components/Layout/Rfcode"));
const Location = loadable(() => import("./components/Pages/Location"));
// import { load } from "react-cookies"

const store = getStore();

render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/sign-in" component={Home} />
        <Route
          path={"/menu/category/:slugValue/:subcatSlug"}
          component={Products}
        />
        <Route
          path={"/menu/:slugType/:slugValue/:proValue"}
          component={ProductDetail}
        />
        <Route path={"/menu/:slugType/:slugValue?"} component={Products} />
        <Route path="/menu" component={Products} />
        <Route path="/favourite" component={FavouriteProducts} />
        <Route path="/delivery-locations" component={Deliverydetails} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/thankyou/:orderId" component={Thankyou} />
        <Route path="/about-us" component={Pages} />
        <Route path="/gallery" component={Pages} />
        <Route path="/our-team" component={Pages} />
        <Route path="/terms-conditions" component={Pages} />
        <Route path="/privacy-policy" component={Pages} />
        <Route path="/page/:page_slug" component={Pages} />
        <Route path="/contact-us/headquarter" component={Pages} />
        <Route path="/contact-us/outlets-location" component={Pages} />
        <Route path="/location/singapore" component={Outlets} />
        <Route path="/location/locate-us-overseas" component={Pages} />
        <Route path="/location/:slug" component={Location} />
        <Route path="/location" component={Pages} />
        <Route path="/careers" component={Pages} />
        <Route path="/workshop" component={Pages} />

        <Route path="/contact-us/feedback" component={ContactUs} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/faq" component={Faq} />

        <Route path="/corporate" component={Corporate} />

        <Route path="/brand-story" component={BrandStory} />
        <Route path="/brand-value" component={BrandValue} />
        <Route path="/brand-concept" component={BrandConcept} />
        <Route path="/membership" component={Membership} />
        <Route path="/reviews/:orderID" component={Reviews} />
        <Route path="/reviews" component={Reviewproducts} />

        <Route path="/rewards" component={Rewards} />
        <Route path="/mypromotions" component={Mypromotions} />
        <Route path="/voucher" component={Myvoucher} />
        <Route path="/myorders" component={Orders} />
        <Route path="/referfriends" component={ReferFriends} />
        <Route path="/myaccount" component={Myaccount} />
        <Route path="/referral" component={Referral} />
        <Route path={"/rfcode/:slugtext"} component={Rfcode} />
        <Route path="/notes" component={Notes} />
        <Route path="/account/activation/:activationKey" component={Account} />
        <Route
          path="/account/resetpassword/:resetKey"
          component={Resetpassword}
        />

        <Route
          path="/complete-delivery/:orderID/:addressID"
          component={Completedelivery}
        />

        <Route path="/logout" component={Logout} />
        <Route path={"/refpage/:slugtext"} component={Refpage} />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
