/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_MULTI_DELIVERY_LOCATION,
  SET_MULTI_DELIVERY_LOCATION,
} from "../actions";
import { appId, apiUrl } from "../components/Helpers/Config";
import { getReferenceID } from "../components/Helpers/SettingHelper";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetMultiDeliveryLocation = function* () {
  yield takeEvery(GET_MULTI_DELIVERY_LOCATION, workerGetMultiDeliveryLocation);
};

function* workerGetMultiDeliveryLocation() {
  try {
    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + getReferenceID();
    } else {
      var customerParam = "&customer_id=" + cookie.load("UserId");
    }
    customerParam += "&availability_id=" + cookie.load("defaultAvilablityId");
    const uri =
      apiUrl +
      "cart/getmultideliverylocations?status=A&app_id=" +
      appId +
      customerParam;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_MULTI_DELIVERY_LOCATION, value: resultArr });
  } catch {
    console.log("Get Cart Detail Failed");
  }
}
