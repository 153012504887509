/* Live */
export const appId = "AAAFBB9D-4514-45BD-884C-A118108F7F4A";
export const projectTitle = "Awfully Chocolate";
export const apiUrl = "https://ccpl.ninjaos.dev/api/";
export const apiUrlV2 = "https://ccpl.ninjaos.dev/apiv2/";
export const apiUrlPro = "https://ccpl.ninjaos.dev/ninjapro/";
export const apiUrlNotify = "https://ccpl.ninjaos.dev/Pushorder/";
export const baseUrl = "https://awfullychocolate.promobuddy.asia/";
//export const baseUrl = "https://shop.awfullychocolate.com/";
//export const baseUrl = "http://localhost:3000/";

export const timThumpUrl = "https://ccpl.ninjaos.dev/timthumb.php?src=";
export const mediaUrl = "https://ccpl.ninjaos.dev/media/dev_team/";
export const blogImageUrl = "https://ccpl.ninjaos.dev/media/dev_team/blog/";
export const tagImageUrl = "https://ccpl.ninjaos.dev/media/dev_team/tag/";
export const proFolderUrl = "https://ccpl.ninjaos.dev/media/dev_team/products/";
export const outletUrl = "https://ccpl.ninjaos.dev/media/dev_team/outlet/";
export const mainurl = "https://ccpl.ninjaos.dev/";

export const stripeImage = "/img/stripe_logo.png";

export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";

export const CountryTxt = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany = "Awfully Chocolate";

export const fbAppId = "2041480622716346";
export const googleAppId =
  "761389391992-qs8usqqaijrjvv2qgmii95bdt9ocv3sb.apps.googleusercontent.com";

export const stripeKey = "pk_test_mSKQhHZYbRvm1YvuX9mKN4zx";
export const stripeEmail = "dev@jankosoft.com";
export const stripeReference = "awfullychocolate";
export const stripeDesc = "My Checkout";
export const stripeCurrency = "SGD";
export const currencySymbol = "$";
export const defaultoutlet = "462";
export const vouchertoutlet = "462";
