import { takeEvery, call, put } from "redux-saga/effects";
import { GET_TESTIMONIAL, SET_TESTIMONIAL } from "../actions";
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetTestimonial = function* () {
  yield takeEvery(GET_TESTIMONIAL, workerGetTestimonial);
};

function* workerGetTestimonial() {
  try {
    const uri = apiUrl + "cms/testimonial?app_id=" + appId;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_TESTIMONIAL, value: resultArr });
  } catch (e) {
    console.log(e, "Get Testimonial Failed");
  }
}
