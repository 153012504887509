/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overall_orders: 0,
      referral_enable: '1',
      globalSettings: [],

    };
  }
  componentWillReceiveProps(nextProps) {

    /*activity count -start */
    if (Object.keys(nextProps.activitycountNew).length > 0) {
      if (nextProps.activitycountNew !== this.props.activitycountNew) {
        if (
          nextProps.activitycountNew[0].status &&
          nextProps.activitycountNew[0].result_set
        ) {
          this.setState({
            overall_orders:
              nextProps.activitycountNew[0].result_set.overall_orders,
          });
        }
      }
    } else {
      this.setState({
        overall_orders: 0,
      });
    }
  }

  render() {
    var initialSlide = 0;
    if (this.props.currentpage === "myorders") {
      initialSlide = 1;
    } else if (this.props.currentpage === "rewards") {
      initialSlide = 2;
    } else if (this.props.currentpage === "mypromotions") {
      initialSlide = 3;
    } else if (this.props.currentpage === "voucher") {
      initialSlide = 4;
    } else if (this.props.currentpage === "referral") {
      initialSlide = 5;
    }
    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 4,
      initialSlide: initialSlide,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };

    return (
      <div className="mainacc_menuout">
        <ul className="mainacc_menulist">
          <li
            className={this.props.currentpage === "myaccount" ? "active" : ""}
          >
            <Link to="/myaccount" title="My Account">
              <span>Account Details</span>
            </Link>
          </li>
          <li className={this.props.currentpage === "myorders" ? "active" : ""}>
            <Link to="/myorders" title="My Orders">
              <span>Orders</span>
              {parseFloat(this.state.overall_orders) > 0 && (
                <span id="masterCount">{this.state.overall_orders}</span>
              )}
            </Link>
          </li>
          <li className={this.props.currentpage === "rewards" ? "active" : ""}>
            <Link to="/rewards" title="My Rewards">
              <span>Rewards</span>
            </Link>
          </li>

          <li
            className={
              this.props.currentpage === "mypromotions" ? "active" : ""
            }
          >
            <Link to="/mypromotions" title="My Promotions">
              <span>Promotions</span>
            </Link>
          </li>

          <li className={this.props.currentpage === "voucher" ? "active" : ""}>
            <Link to="/voucher" title="Voucher">
              <span>Vouchers</span>
            </Link>
          </li>

          {this.state.referral_enable === '1' && (
          <li>
            <Link to="/referral" title="Referral">
              <span>Refer Friends</span>
            </Link>
          </li>
          )}

          <li className={this.props.currentpage === "notes" ? "active" : ""}>
            <Link to="/notes" title="Notes">
              <span>Notes</span>
            </Link>
          </li>
          <li>
            <a href="#change-password-popup" className="open-popup-link">
              <span>Change Password</span>
            </a>
          </li>
          <li>
            <a href="/logout">
              <span>Logout</span>
            </a>
          </li>
        </ul>
        <div className="mbacc_mslidersec mbacc_mslider">
          <Slider {...settingsMyAcc}>
            <div
              className={
                this.props.currentpage === "myaccount"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/myaccount" title="My Account">
                <span>Account Details</span>
              </Link>
            </div>
            <div
              className={
                this.props.currentpage === "myorders"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/myorders" title="My Orders">
                <span>Orders</span>
                {parseFloat(this.state.overall_orders) > 0 && (
                  <span id="masterCount">{this.state.overall_orders}</span>
                )}
              </Link>
            </div>
            <div
              className={
                this.props.currentpage === "rewards"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/rewards" title="My Rewards">
                <span>Rewards</span>
              </Link>
            </div>
            <div
              className={
                this.props.currentpage === "mypromotions"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/mypromotions" title="My Promotions">
                <span>Promotions</span>
              </Link>
            </div>
            <div
              className={
                this.props.currentpage === "voucher"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/voucher" title="Voucher">
                <span>Vouchers</span>
              </Link>
            </div>
            <div
              className={
                this.props.currentpage === "referral"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/referral" title="Refer Friends">
                <span>Refer Friends</span>
              </Link>
            </div>
            <div className="mbacc_mslide">
              <a href="#change-password-popup" className="open-popup-link">
                <span>Change Password</span>
              </a>
            </div>
            <div className="mbacc_mslide">
              <a href="/logout">
                <span>Logout</span>
              </a>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  
  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }
  
  return {
    settingsArr: globalSettings,
    activitycountNew: state.activitycount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

Sidebar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
