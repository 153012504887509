/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import OwlCarousel from "react-owl-carousel2";
import "../../../common/css/owl.carousel.css";
import { appId, apiUrlV2, deliveryId } from "../../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showPriceValueSub,
  showLoader,
  hideLoader,
  showCustomAlert,
  showAlert,
  removePromoCkValue,
} from "../../Helpers/SettingHelper";
import noimage from "../../../common/images/no-img-product.png";

import { GET_HIGHLIGHTPROLIST } from "../../../actions";
var qs = require("qs");
var Parser = require("html-react-parser");
var settingsGallery = {
  items: 5,
  autoplay: false,
  loop: false,
  nav: true,
  margin: 20,
  dots: false,
  responsive: {
    0: {
      items: 2,
      margin: 10,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
    1320: {
      items: 5,
    },
  },
};
class SameDayDeliveryProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { 
    this.props.getHighlightproductslist();
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndexSDD-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndexSDD-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      avilablityId !== "" &&
      orderOutletId != undefined &&
      orderOutletId !== "" &&
      orderOutletId != undefined
    ) {
      var productSlug = productDetail.product_slug;
      if (productSlug !== "") {
        this.props.history.push(
          "/menu/" +
            productDetail.pro_cate_slug +
            "/" +
            productDetail.pro_subcate_slug +
            "/" +
            productSlug
        );
      }
    } else {
      cookie.save("popuptriggerFrom", "HlightPro", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
    return false;
  }

  render() {
    let hlightproArr = this.props.hlightproduct;
    let hlightprolist = [];
    let hlightproimagesource = "";
    let tagimagesource = "";

    if (Object.keys(hlightproArr).length > 0) {
      if (hlightproArr[0].status === "ok") {
        hlightprolist = hlightproArr[0].result_set;
        hlightproimagesource = hlightproArr[0].common.image_source;
        tagimagesource = hlightproArr[0].common.tag_image_source;
      }
    }
    var settingsProduct = {
      items: 4,
      autoplay: false,
      margin: 18,
      loop: true,
      nav: true,
      dots: false,
      responsive: {
        0: {
          items: 1,
        },
        520: {
          items: 2,
          margin: 10,
        },
        780: {
          items: 3,
          margin: 12,
        },
        1280: {
          items: 4,
        },
      },
    };

    return (
      <section className="featuredpro-section">
        {Object.keys(hlightprolist).length > 0 ? (
          <div className="featured-products-section">
            <div className="container">
              <div className="featured-products-main">
                <div className="products-list-ulmain">
                  <OwlCarousel options={settingsGallery}>
                    {hlightprolist.map((hlightpro, index) => {
                      var prodivId =
                        "proIndexSDD-" + hlightpro.product_primary_id;
                      var comboProId = "comboPro-" + hlightpro.product_slug;
                      return (
                        <div
                          className="products-single-li"
                          id={prodivId}
                          key={index}
                        >
                          <Link
                            to={
                              "/menu/" +
                              hlightpro.pro_cate_slug +
                              "/" +
                              hlightpro.pro_subcate_slug +
                              "/" +
                              hlightpro.product_slug
                            }
                          >
                            <div className="products-image-div">
                              {hlightpro.product_thumbnail !== "" ? (
                                <img
                                  loading="lazy"
                                  src={
                                    hlightproimagesource +
                                    "/" +
                                    hlightpro.product_thumbnail
                                  }
                                />
                              ) : (
                                <img src={noimage} />
                              )}
                            </div>
                          </Link>

                          <div className="product-info-div">
                            <div className="product-title-maindiv">
                              <div className="product-title">
                                <Link
                                  to={
                                    "/menu/" +
                                    hlightpro.pro_cate_slug +
                                    "/" +
                                    hlightpro.pro_subcate_slug +
                                    "/" +
                                    hlightpro.product_slug
                                  }
                                >
                                  <h3>
                                    {hlightpro.product_alias !== ""
                                      ? stripslashes(hlightpro.product_alias)
                                      : stripslashes(hlightpro.product_name)}
                                  </h3>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    hlightproduct: state.highlightproductlist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHighlightproductslist: () => {
      dispatch({ type: GET_HIGHLIGHTPROLIST });
    },
  };
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(SameDayDeliveryProducts);
