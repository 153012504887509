/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import mainLogo from "../../common/images/logo.png";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import { projectTitle, appId, apiUrl } from "../Helpers/Config";
import { hideLoader, showLoader, showAlert } from "../Helpers/SettingHelper";
var Parser = require("html-react-parser");
var qs = require("qs");
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerBlocks: "",
      footerlink: "",
      newsLetterEmail: "",
      email_error: "",
    };
  }

  componentDidMount() {
    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
      var footerPas = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data) => {
          if (data.staticblocks_slug === "footer-link") {
            footerPas = data.staticblocks_description;
            return "";
          }
        });
      }
      footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      this.setState({
        footerBlocks: PropsData.staticblack,
        footerlink: footerPas,
      });
    }
  }
  checkEmail() {
    if (this.state.newsLetterEmail !== "") {
      var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
      if (!pattern.test(this.state.newsLetterEmail)) {
        this.setState({ email_error: "Please enter valid email" });
      } else {
        this.setState({ email_error: "" });
        showLoader("f-newletter-form", "class");
        var postObject = {
          app_id: appId,
          email: this.state.newsLetterEmail,
        };
        axios
          .post(
            apiUrl + "newsletter/subscription_new",
            qs.stringify(postObject)
          )
          .then((res) => {
            hideLoader("f-newletter-form", "class");
            if (res.data.status === "ok") {
              this.setState({ newsLetterEmail: "" });
              var magnfPopup = $.magnificPopup.instance;
              showAlert("Success", "Newsletter Subscribed", magnfPopup);
            } else {
              showAlert("Error", res.data.message, magnfPopup);
            }
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          });
      }
    } else {
      this.setState({ email_error: "This field is required" });
    }
  }

  handleChangeemail(event) {
    var value = event.target.value;
    var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    var error = "";
    if (value !== "" && !pattern.test(value)) {
      error = "Please enter valid email";
    }
    this.setState({ newsLetterEmail: event.target.value, email_error: error });
  }

  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

    return (
      <footer className="footer-main">
        <div className="footer-top">
          <div className="container">
            <div className="footer-newletter">
              <div className="footer-logo">
                <a href="#" title="Logo">
                  <img src={mainLogo} alt="Logo" />
                </a>
              </div>

              <span>Subscribe to our Mailing List today! </span>
              <h4>
                Input your email below to receive the latest news and best
                promotions from us!
              </h4>
              <div className="f-newletter-form">
                <input
                  type="text"
                  placeholder="Email Address"
                  onChange={this.handleChangeemail.bind(this)}
                  value={this.state.newsLetterEmail}
                />
                {this.state.email_error !== "" && (
                  <span className="error">{this.state.email_error}</span>
                )}
                <button
                  type="submit"
                  className="button"
                  onClick={this.checkEmail.bind(this)}
                >
                  Subscribe
                </button>
              </div>
              <div className="social-media">
                <p>Connect With Us</p>
                <a
                  href="https://www.facebook.com/awfullychocolate.sg/"
                  target="_blank"
                >
                  <img alt="Facebook" src="/img/facebook.svg" />
                </a>
                <a
                  href="https://www.instagram.com/awfullychocolatesg/"
                  target="_blank"
                >
                  <img alt="Instagram" src="/img/instagram.svg" />
                </a>
              </div>
            </div>
            {this.state.footerlink}
            <div className="mobile-footer-nav">
              <Accordion>
                <AccordionItem title="QUICK LINKS">
                  <ul>
                    <li>
                      <Link to="/menu/category/chocolate-cakes">
                        Chocolate Cakes
                      </Link>
                    </li>
                    <li>
                      <Link to="/menu/category/the-perfect-gift">
                        The Perfect Gifts
                      </Link>
                    </li>
                    <li>
                      <Link to="/page/franchise">Franchising</Link>
                    </li>
                    <li>
                      <Link to="/page/the-awfully-chocolate-bakery-cafe">
                        Our Flagship Store
                      </Link>
                    </li>
                    <li>
                      <a href="https://www.sinpopo.com/" target="_blank">
                        Sinpopo Brand
                      </a>
                    </li>
                    <li>
                      <Link to="/corporate">For Corporate</Link>
                    </li>
                    <li>
                      <Link to="/workshop">Workshop & Events</Link>
                    </li>
                  </ul>
                </AccordionItem>
                <AccordionItem title="MY ACCOUNT">
                  <ul>
                    <li>
                      <Link to={"/myaccount"}>Account Details</Link>
                    </li>
                    <li>
                      <Link to={"/myorders"}>Orders</Link>
                    </li>
                    <li>
                      <Link to={"/rewards"}>Rewards</Link>
                    </li>
                    <li>
                      <Link to={"/mypromotions"}>Promotions</Link>
                    </li>
                    <li>
                      <Link to={"/voucher"}>E Vouchers</Link>
                    </li>
                  </ul>
                </AccordionItem>
                <AccordionItem title="CUSTOMER SERVICE">
                  <ul>
                    <li>
                      <Link to={"/faq"}>FAQ</Link>
                    </li>
                    <li>
                      <Link to={"/terms-conditions"}>Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to={"/careers"}>Careers</Link>
                    </li>
                    <li>
                      <Link to={"/contact-us"}>Contact Us</Link>
                    </li>
                    <li>
                      <Link to={"/location"}>Stores & Opening Hours</Link>
                    </li>
                  </ul>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>

        <div className="copyright-section">
          <p>
            Copyright {yearSp} Awfully Chocolate Pte Ltd. All rights reserved.
            Design By{" "}
            <a href="https://jankosoft.com/" target="_blank">
              Jankosoft
            </a>
          </p>
        </div>

        <div className="scrolltop" id="scrollbutton">
          <a href="/" className="disbl_href_action">
            <span>
              <i className="fa fa-angle-double-up ars"></i>
              <i className="fa fa-angle-up ars1"></i>
              <i className="fa fa-angle-up ars2"></i>
            </span>
          </a>
        </div>
      </footer>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
